<div class="query__container">
  <span
    [ngClass]="{ label: true, 'font-semibold': true, 'mb-1': true, required }"
  >
    Please choose an {{ type === TagCategory.Actor ? 'actor' : 'channel' }}:
  </span>
  <itq-list-search
    class="relative"
    [initialState]="initialState"
    [query]="query"
    [showSelectionsLabel]="false"
    [dataSource]="dataSource | filterSelections: query : timeline.tagId"
    (changeValue)="onRemoveTag($event)"
    (search)="onGetTags($event)"
  >
    @for (
      item of dataSource | filterSelections: query : timeline.tagId;
      track item
    ) {
      <itq-tags-include-selection
        [item]="item"
        (changeValue)="onAddTag($event)"
      ></itq-tags-include-selection>
    }
  </itq-list-search>
</div>
<div class="selections__container" *ngIf="query?.selections?.length > 0">
  <itq-mat-button
    [materialStyle]="'basic'"
    [type]="'button'"
    (clickEvent)="onClearFilters()"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Clear query
  </itq-mat-button>
</div>
<itq-loader *ngIf="showLoader"></itq-loader>
