<itq-sidebar
  class="widget_settings__left p-4"
  [isExpanded]="expandedFilters"
  [width]="550"
>
  <itq-headline-simple
    class="mb-7"
    [iconName]="widget.type"
    [iconType]="IconType.SPRITE"
    [iconSize]="'40'"
    [title]="widget?.name + (segment ? ': ' + segment.data.name : '')"
  ></itq-headline-simple>
  @if (ChartTypes.ECHARTS.includes(this.widget?.chartType)) {
    <itq-chart
      #chart
      [widget]="widget"
      [data]="data"
      [action]="WidgetActions.EXPLORE"
      [segment]="segment"
      (drilldown)="onDrilldownChart($event)"
      (updateRef)="onUpdateRef($event)"
    ></itq-chart>
  } @else {
    <div class="chart__wrapper">
      @if (data?.totalHits > 0) {
        <itq-table
          [tableData]="data?.series[0]?.data"
          [tableColumns]="tableColumns"
          [trackBy]="'tagId'"
        >
        </itq-table>
      }
    </div>
  }
</itq-sidebar>
<div class="widget_settings__right">
  <div class="right__toolbar">
    @if (documentsData?.totalHits > 0) {
      <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-md'">{{
        documentsData?.totalHits | number
      }}</itq-pill>
    }
    <div class="toolbar__container">
      <itq-toolbar
        [actions]="toolbarActions"
        [filtersApplied]="hasFiltersApplied()"
        [form]="form"
        [initialState]="initialState"
        (search)="onSearch($event)"
        (export)="onExport()"
        (resetFilters)="onResetFilters()"
      ></itq-toolbar>
    </div>
  </div>
  <div class="right__body">
    <itq-virtual-table
      *ngIf="documentsData; else noSegmentSelected"
      [initialState]="initialState"
      [tableColumns]="docsTableColumns"
      [dataSource]="documentsData?.items"
      [active]="segment?.value?.id"
      [totalCount]="documentsData?.totalHits"
      [theme]="'tile'"
      [headerVisible]="false"
      [trackBy]="'id'"
      [emptyMessage]="'Please update your filters and try again'"
      [emptyMessageDescription]="'Please update your filters and try again'"
      (dataBound)="onDataBound($event)"
    ></itq-virtual-table>

    <itq-document-item-detail
      *ngIf="articleDetail"
      [viewParent]="true"
      [segment]="articleDetail"
      [action]="navigationItem?.action"
      [form]="form"
      [navigationItem]="navigationItem"
      (drilldown)="onDrilldown($event)"
    ></itq-document-item-detail>
  </div>
</div>
<ng-template #noSegmentSelected>
  <itq-panel-info
    *ngIf="!segment"
    [label]="'No data point selected!'"
    [message]="'Please select datapoint in order to get results!'"
    [icon]="'exclamation-circle'"
  ></itq-panel-info>
</ng-template>

<ng-template #countTemplate let-row>
  <button
    type="button"
    class="btn btn-link-secondary"
    (click)="onLoadCount(row)"
  >
    {{ row.count }}
  </button>
</ng-template>

<ng-template #template let-data>
  <itq-document-item-tile
    [item]="data"
    [segment]="segment"
    [form]="form"
    [navigationItem]="navigationItem"
    [query]="form?.controls?.searchTerm?.value"
    (drilldown)="onDrilldown($event)"
    (createProfile)="onCreateProfile($event)"
    (addConnection)="onAddConnection($event)"
    (addNote)="onAddNote($event)"
  ></itq-document-item-tile>
</ng-template>
