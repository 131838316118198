import { iif, fromEvent, merge, Observable, Subject, BehaviorSubject, NEVER, interval, animationFrameScheduler, combineLatest, of } from 'rxjs';
import { filter, switchMap, startWith, exhaustMap, takeUntil, take, map, tap, distinctUntilChanged } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { InjectionToken, Directive, Input, Injectable, ElementRef, Component, ChangeDetectionStrategy, Inject, ContentChildren, ViewChild, ContentChild, EventEmitter, ViewEncapsulation, Output, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * IMPORTANT:
 * This utils are taken from the project: https://github.com/STRML/react-grid-layout.
 * The code should be as less modified as possible for easy maintenance.
 */
const _c0 = ["resizeElem"];
const _c1 = ["*"];
const DEBUG = false;
/**
 * Return the bottom coordinate of the layout.
 *
 * @param  {Array} layout Layout array.
 * @return {Number}       Bottom coordinate.
 */
function bottom(layout) {
  let max = 0,
    bottomY;
  for (let i = 0, len = layout.length; i < len; i++) {
    bottomY = layout[i].y + layout[i].h;
    if (bottomY > max) {
      max = bottomY;
    }
  }
  return max;
}
function cloneLayout(layout) {
  const newLayout = Array(layout.length);
  for (let i = 0, len = layout.length; i < len; i++) {
    newLayout[i] = cloneLayoutItem(layout[i]);
  }
  return newLayout;
}
// Fast path to cloning, since this is monomorphic
/** NOTE: This code has been modified from the original source */
function cloneLayoutItem(layoutItem) {
  const clonedLayoutItem = {
    w: layoutItem.w,
    h: layoutItem.h,
    x: layoutItem.x,
    y: layoutItem.y,
    id: layoutItem.id,
    moved: !!layoutItem.moved,
    static: !!layoutItem.static
  };
  if (layoutItem.minW !== undefined) {
    clonedLayoutItem.minW = layoutItem.minW;
  }
  if (layoutItem.maxW !== undefined) {
    clonedLayoutItem.maxW = layoutItem.maxW;
  }
  if (layoutItem.minH !== undefined) {
    clonedLayoutItem.minH = layoutItem.minH;
  }
  if (layoutItem.maxH !== undefined) {
    clonedLayoutItem.maxH = layoutItem.maxH;
  }
  // These can be null
  if (layoutItem.isDraggable !== undefined) {
    clonedLayoutItem.isDraggable = layoutItem.isDraggable;
  }
  if (layoutItem.isResizable !== undefined) {
    clonedLayoutItem.isResizable = layoutItem.isResizable;
  }
  return clonedLayoutItem;
}
/**
 * Given two layoutitems, check if they collide.
 */
function collides(l1, l2) {
  if (l1.id === l2.id) {
    return false;
  } // same element
  if (l1.x + l1.w <= l2.x) {
    return false;
  } // l1 is left of l2
  if (l1.x >= l2.x + l2.w) {
    return false;
  } // l1 is right of l2
  if (l1.y + l1.h <= l2.y) {
    return false;
  } // l1 is above l2
  if (l1.y >= l2.y + l2.h) {
    return false;
  } // l1 is below l2
  return true; // boxes overlap
}
/**
 * Given a layout, compact it. This involves going down each y coordinate and removing gaps
 * between items.
 *
 * @param  {Array} layout Layout.
 * @param  {Boolean} verticalCompact Whether or not to compact the layout
 *   vertically.
 * @return {Array}       Compacted Layout.
 */
function compact(layout, compactType, cols) {
  // Statics go in the compareWith array right away so items flow around them.
  const compareWith = getStatics(layout);
  // We go through the items by row and column.
  const sorted = sortLayoutItems(layout, compactType);
  // Holding for new items.
  const out = Array(layout.length);
  for (let i = 0, len = sorted.length; i < len; i++) {
    let l = cloneLayoutItem(sorted[i]);
    // Don't move static elements
    if (!l.static) {
      l = compactItem(compareWith, l, compactType, cols, sorted);
      // Add to comparison array. We only collide with items before this one.
      // Statics are already in this array.
      compareWith.push(l);
    }
    // Add to output array to make sure they still come out in the right order.
    out[layout.indexOf(sorted[i])] = l;
    // Clear moved flag, if it exists.
    l.moved = false;
  }
  return out;
}
const heightWidth = {
  x: 'w',
  y: 'h'
};
/**
 * Before moving item down, it will check if the movement will cause collisions and move those items down before.
 */
function resolveCompactionCollision(layout, item, moveToCoord, axis) {
  const sizeProp = heightWidth[axis];
  item[axis] += 1;
  const itemIndex = layout.map(layoutItem => {
    return layoutItem.id;
  }).indexOf(item.id);
  // Go through each item we collide with.
  for (let i = itemIndex + 1; i < layout.length; i++) {
    const otherItem = layout[i];
    // Ignore static items
    if (otherItem.static) {
      continue;
    }
    // Optimization: we can break early if we know we're past this el
    // We can do this b/c it's a sorted layout
    if (otherItem.y > item.y + item.h) {
      break;
    }
    if (collides(item, otherItem)) {
      resolveCompactionCollision(layout, otherItem, moveToCoord + item[sizeProp], axis);
    }
  }
  item[axis] = moveToCoord;
}
/**
 * Compact an item in the layout.
 */
function compactItem(compareWith, l, compactType, cols, fullLayout) {
  const compactV = compactType === 'vertical';
  const compactH = compactType === 'horizontal';
  if (compactV) {
    // Bottom 'y' possible is the bottom of the layout.
    // This allows you to do nice stuff like specify {y: Infinity}
    // This is here because the layout must be sorted in order to get the correct bottom `y`.
    l.y = Math.min(bottom(compareWith), l.y);
    // Move the element up as far as it can go without colliding.
    while (l.y > 0 && !getFirstCollision(compareWith, l)) {
      l.y--;
    }
  } else if (compactH) {
    // Move the element left as far as it can go without colliding.
    while (l.x > 0 && !getFirstCollision(compareWith, l)) {
      l.x--;
    }
  }
  // Move it down, and keep moving it down if it's colliding.
  let collides;
  while (collides = getFirstCollision(compareWith, l)) {
    if (compactH) {
      resolveCompactionCollision(fullLayout, l, collides.x + collides.w, 'x');
    } else {
      resolveCompactionCollision(fullLayout, l, collides.y + collides.h, 'y');
    }
    // Since we can't grow without bounds horizontally, if we've overflown, let's move it down and try again.
    if (compactH && l.x + l.w > cols) {
      l.x = cols - l.w;
      l.y++;
      // ALso move element as left as much as we can (ktd-custom-change)
      while (l.x > 0 && !getFirstCollision(compareWith, l)) {
        l.x--;
      }
    }
  }
  // Ensure that there are no negative positions
  l.y = Math.max(l.y, 0);
  l.x = Math.max(l.x, 0);
  return l;
}
/**
 * Given a layout, make sure all elements fit within its bounds.
 *
 * @param  {Array} layout Layout array.
 * @param  {Number} bounds Number of columns.
 */
function correctBounds(layout, bounds) {
  const collidesWith = getStatics(layout);
  for (let i = 0, len = layout.length; i < len; i++) {
    const l = layout[i];
    // Overflows right
    if (l.x + l.w > bounds.cols) {
      l.x = bounds.cols - l.w;
    }
    // Overflows left
    if (l.x < 0) {
      l.x = 0;
      l.w = bounds.cols;
    }
    if (!l.static) {
      collidesWith.push(l);
    } else {
      // If this is static and collides with other statics, we must move it down.
      // We have to do something nicer than just letting them overlap.
      while (getFirstCollision(collidesWith, l)) {
        l.y++;
      }
    }
  }
  return layout;
}
/**
 * Get a layout item by ID. Used so we can override later on if necessary.
 *
 * @param  {Array}  layout Layout array.
 * @param  {String} id     ID
 * @return {LayoutItem}    Item at ID.
 */
function getLayoutItem(layout, id) {
  for (let i = 0, len = layout.length; i < len; i++) {
    if (layout[i].id === id) {
      return layout[i];
    }
  }
  return null;
}
/**
 * Returns the first item this layout collides with.
 * It doesn't appear to matter which order we approach this from, although
 * perhaps that is the wrong thing to do.
 *
 * @param  {Object} layoutItem Layout item.
 * @return {Object|undefined}  A colliding layout item, or undefined.
 */
function getFirstCollision(layout, layoutItem) {
  for (let i = 0, len = layout.length; i < len; i++) {
    if (collides(layout[i], layoutItem)) {
      return layout[i];
    }
  }
  return null;
}
function getAllCollisions(layout, layoutItem) {
  return layout.filter(l => collides(l, layoutItem));
}
/**
 * Get all static elements.
 * @param  {Array} layout Array of layout objects.
 * @return {Array}        Array of static layout items..
 */
function getStatics(layout) {
  return layout.filter(l => l.static);
}
/**
 * Move an element. Responsible for doing cascading movements of other elements.
 *
 * @param  {Array}      layout            Full layout to modify.
 * @param  {LayoutItem} l                 element to move.
 * @param  {Number}     [x]               X position in grid units.
 * @param  {Number}     [y]               Y position in grid units.
 */
function moveElement(layout, l, x, y, isUserAction, preventCollision, compactType, cols) {
  // If this is static and not explicitly enabled as draggable,
  // no move is possible, so we can short-circuit this immediately.
  if (l.static && l.isDraggable !== true) {
    return layout;
  }
  // Short-circuit if nothing to do.
  if (l.y === y && l.x === x) {
    return layout;
  }
  log(`Moving element ${l.id} to [${String(x)},${String(y)}] from [${l.x},${l.y}]`);
  const oldX = l.x;
  const oldY = l.y;
  // This is quite a bit faster than extending the object
  if (typeof x === 'number') {
    l.x = x;
  }
  if (typeof y === 'number') {
    l.y = y;
  }
  l.moved = true;
  // If this collides with anything, move it.
  // When doing this comparison, we have to sort the items we compare with
  // to ensure, in the case of multiple collisions, that we're getting the
  // nearest collision.
  let sorted = sortLayoutItems(layout, compactType);
  const movingUp = compactType === 'vertical' && typeof y === 'number' ? oldY >= y : compactType === 'horizontal' && typeof x === 'number' ? oldX >= x : false;
  if (movingUp) {
    sorted = sorted.reverse();
  }
  const collisions = getAllCollisions(sorted, l);
  // There was a collision; abort
  if (preventCollision && collisions.length) {
    log(`Collision prevented on ${l.id}, reverting.`);
    l.x = oldX;
    l.y = oldY;
    l.moved = false;
    return layout;
  }
  // Move each item that collides away from this element.
  for (let i = 0, len = collisions.length; i < len; i++) {
    const collision = collisions[i];
    log(`Resolving collision between ${l.id} at [${l.x},${l.y}] and ${collision.id} at [${collision.x},${collision.y}]`);
    // Short circuit so we can't infinite loop
    if (collision.moved) {
      continue;
    }
    // Don't move static items - we have to move *this* element away
    if (collision.static) {
      layout = moveElementAwayFromCollision(layout, collision, l, isUserAction, compactType, cols);
    } else {
      layout = moveElementAwayFromCollision(layout, l, collision, isUserAction, compactType, cols);
    }
  }
  return layout;
}
/**
 * This is where the magic needs to happen - given a collision, move an element away from the collision.
 * We attempt to move it up if there's room, otherwise it goes below.
 *
 * @param  {Array} layout            Full layout to modify.
 * @param  {LayoutItem} collidesWith Layout item we're colliding with.
 * @param  {LayoutItem} itemToMove   Layout item we're moving.
 */
function moveElementAwayFromCollision(layout, collidesWith, itemToMove, isUserAction, compactType, cols) {
  const compactH = compactType === 'horizontal';
  // Compact vertically if not set to horizontal
  const compactV = compactType !== 'horizontal';
  const preventCollision = collidesWith.static; // we're already colliding (not for static items)
  // If there is enough space above the collision to put this element, move it there.
  // We only do this on the main collision as this can get funky in cascades and cause
  // unwanted swapping behavior.
  if (isUserAction) {
    // Reset isUserAction flag because we're not in the main collision anymore.
    isUserAction = false;
    // Make a mock item so we don't modify the item here, only modify in moveElement.
    const fakeItem = {
      x: compactH ? Math.max(collidesWith.x - itemToMove.w, 0) : itemToMove.x,
      y: compactV ? Math.max(collidesWith.y - itemToMove.h, 0) : itemToMove.y,
      w: itemToMove.w,
      h: itemToMove.h,
      id: '-1'
    };
    // No collision? If so, we can go up there; otherwise, we'll end up moving down as normal
    if (!getFirstCollision(layout, fakeItem)) {
      log(`Doing reverse collision on ${itemToMove.id} up to [${fakeItem.x},${fakeItem.y}].`);
      return moveElement(layout, itemToMove, compactH ? fakeItem.x : undefined, compactV ? fakeItem.y : undefined, isUserAction, preventCollision, compactType, cols);
    }
  }
  return moveElement(layout, itemToMove, compactH ? itemToMove.x + 1 : undefined, compactV ? itemToMove.y + 1 : undefined, isUserAction, preventCollision, compactType, cols);
}
/**
 * Helper to convert a number to a percentage string.
 *
 * @param  {Number} num Any number
 * @return {String}     That number as a percentage.
 */
function perc(num) {
  return num * 100 + '%';
}
function setTransform({
  top,
  left,
  width,
  height
}) {
  // Replace unitless items with px
  const translate = `translate(${left}px,${top}px)`;
  return {
    transform: translate,
    WebkitTransform: translate,
    MozTransform: translate,
    msTransform: translate,
    OTransform: translate,
    width: `${width}px`,
    height: `${height}px`,
    position: 'absolute'
  };
}
function setTopLeft({
  top,
  left,
  width,
  height
}) {
  return {
    top: `${top}px`,
    left: `${left}px`,
    width: `${width}px`,
    height: `${height}px`,
    position: 'absolute'
  };
}
/**
 * Get layout items sorted from top left to right and down.
 *
 * @return {Array} Array of layout objects.
 * @return {Array}        Layout, sorted static items first.
 */
function sortLayoutItems(layout, compactType) {
  if (compactType === 'horizontal') {
    return sortLayoutItemsByColRow(layout);
  } else {
    return sortLayoutItemsByRowCol(layout);
  }
}
function sortLayoutItemsByRowCol(layout) {
  return [].concat(layout).sort(function (a, b) {
    if (a.y > b.y || a.y === b.y && a.x > b.x) {
      return 1;
    } else if (a.y === b.y && a.x === b.x) {
      // Without this, we can get different sort results in IE vs. Chrome/FF
      return 0;
    }
    return -1;
  });
}
function sortLayoutItemsByColRow(layout) {
  return [].concat(layout).sort(function (a, b) {
    if (a.x > b.x || a.x === b.x && a.y > b.y) {
      return 1;
    }
    return -1;
  });
}
/**
 * Validate a layout. Throws errors.
 *
 * @param  {Array}  layout        Array of layout items.
 * @param  {String} [contextName] Context name for errors.
 * @throw  {Error}                Validation error.
 */
function validateLayout(layout, contextName = 'Layout') {
  const subProps = ['x', 'y', 'w', 'h'];
  if (!Array.isArray(layout)) {
    throw new Error(contextName + ' must be an array!');
  }
  for (let i = 0, len = layout.length; i < len; i++) {
    const item = layout[i];
    for (let j = 0; j < subProps.length; j++) {
      if (typeof item[subProps[j]] !== 'number') {
        throw new Error('ReactGridLayout: ' + contextName + '[' + i + '].' + subProps[j] + ' must be a number!');
      }
    }
    if (item.id && typeof item.id !== 'string') {
      throw new Error('ReactGridLayout: ' + contextName + '[' + i + '].i must be a string!');
    }
    if (item.static !== undefined && typeof item.static !== 'boolean') {
      throw new Error('ReactGridLayout: ' + contextName + '[' + i + '].static must be a boolean!');
    }
  }
}
// Flow can't really figure this out, so we just use Object
function autoBindHandlers(el, fns) {
  fns.forEach(key => el[key] = el[key].bind(el));
}
function log(...args) {
  if (!DEBUG) {
    return;
  }
  // eslint-disable-next-line no-console
  console.log(...args);
}
const noop = () => {};

/** Cached result of whether the user's browser supports passive event listeners. */
let supportsPassiveEvents;
/**
 * Checks whether the user's browser supports passive event listeners.
 * See: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
 */
function ktdSupportsPassiveEventListeners() {
  if (supportsPassiveEvents == null && typeof window !== 'undefined') {
    try {
      window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        get: () => supportsPassiveEvents = true
      }));
    } finally {
      supportsPassiveEvents = supportsPassiveEvents || false;
    }
  }
  return supportsPassiveEvents;
}
/**
 * Normalizes an `AddEventListener` object to something that can be passed
 * to `addEventListener` on any browser, no matter whether it supports the
 * `options` parameter.
 * @param options Object to be normalized.
 */
function ktdNormalizePassiveListenerOptions(options) {
  return ktdSupportsPassiveEventListeners() ? options : !!options.capture;
}

/** Options that can be used to bind a passive event listener. */
const passiveEventListenerOptions = ktdNormalizePassiveListenerOptions({
  passive: true
});
/** Options that can be used to bind an active event listener. */
const activeEventListenerOptions = ktdNormalizePassiveListenerOptions({
  passive: false
});
let isMobile = null;
function ktdIsMobileOrTablet() {
  if (isMobile != null) {
    return isMobile;
  }
  // Generic match pattern to identify mobile or tablet devices
  const isMobileDevice = /Android|webOS|BlackBerry|Windows Phone|iPad|iPhone|iPod/i.test(navigator.userAgent);
  // Since IOS 13 is not safe to just check for the generic solution. See: https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
  const isIOSMobileDevice = /iPad|iPhone|iPod/.test(navigator.platform) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  isMobile = isMobileDevice || isIOSMobileDevice;
  return isMobile;
}
function ktdIsMouseEvent(event) {
  return event.clientX != null;
}
function ktdIsTouchEvent(event) {
  return event.touches != null && event.touches.length != null;
}
function ktdPointerClientX(event) {
  return ktdIsMouseEvent(event) ? event.clientX : event.touches[0].clientX;
}
function ktdPointerClientY(event) {
  return ktdIsMouseEvent(event) ? event.clientY : event.touches[0].clientY;
}
function ktdPointerClient(event) {
  return {
    clientX: ktdIsMouseEvent(event) ? event.clientX : event.touches[0].clientX,
    clientY: ktdIsMouseEvent(event) ? event.clientY : event.touches[0].clientY
  };
}
/**
 * Emits when a mousedown or touchstart emits. Avoids conflicts between both events.
 * @param element, html element where to  listen the events.
 * @param touchNumber number of the touch to track the event, default to the first one.
 */
function ktdMouseOrTouchDown(element, touchNumber = 1) {
  return iif(() => ktdIsMobileOrTablet(), fromEvent(element, 'touchstart', passiveEventListenerOptions).pipe(filter(touchEvent => touchEvent.touches.length === touchNumber)), fromEvent(element, 'mousedown', activeEventListenerOptions).pipe(filter(mouseEvent => {
    /**
     * 0 : Left mouse button
     * 1 : Wheel button or middle button (if present)
     * 2 : Right mouse button
     */
    return mouseEvent.button === 0; // Mouse down to be only fired if is left click
  })));
}
/**
 * Emits when a 'mousemove' or a 'touchmove' event gets fired.
 * @param element, html element where to  listen the events.
 * @param touchNumber number of the touch to track the event, default to the first one.
 */
function ktdMouseOrTouchMove(element, touchNumber = 1) {
  return iif(() => ktdIsMobileOrTablet(), fromEvent(element, 'touchmove', activeEventListenerOptions).pipe(filter(touchEvent => touchEvent.touches.length === touchNumber)), fromEvent(element, 'mousemove', activeEventListenerOptions));
}
function ktdTouchEnd(element, touchNumber = 1) {
  return merge(fromEvent(element, 'touchend').pipe(filter(touchEvent => touchEvent.touches.length === touchNumber - 1)), fromEvent(element, 'touchcancel').pipe(filter(touchEvent => touchEvent.touches.length === touchNumber - 1)));
}
/**
 * Emits when a there is a 'mouseup' or the touch ends.
 * @param element, html element where to  listen the events.
 * @param touchNumber number of the touch to track the event, default to the first one.
 */
function ktdMouseOrTouchEnd(element, touchNumber = 1) {
  return iif(() => ktdIsMobileOrTablet(), ktdTouchEnd(element, touchNumber), fromEvent(element, 'mouseup'));
}

/** Tracks items by id. This function is mean to be used in conjunction with the ngFor that renders the 'ktd-grid-items' */
function ktdTrackById(index, item) {
  return item.id;
}
/** Given a layout, the gridHeight and the gap return the resulting rowHeight */
function ktdGetGridItemRowHeight(layout, gridHeight, gap) {
  const numberOfRows = layout.reduce((acc, cur) => Math.max(acc, Math.max(cur.y + cur.h, 0)), 0);
  const gapTotalHeight = (numberOfRows - 1) * gap;
  const gridHeightMinusGap = gridHeight - gapTotalHeight;
  return gridHeightMinusGap / numberOfRows;
}
/**
 * Call react-grid-layout utils 'compact()' function and return the compacted layout.
 * @param layout to be compacted.
 * @param compactType, type of compaction.
 * @param cols, number of columns of the grid.
 */
function ktdGridCompact(layout, compactType, cols) {
  return compact(layout, compactType, cols)
  // Prune react-grid-layout compact extra properties.
  .map(item => ({
    id: item.id,
    x: item.x,
    y: item.y,
    w: item.w,
    h: item.h,
    minW: item.minW,
    minH: item.minH,
    maxW: item.maxW,
    maxH: item.maxH
  }));
}
function screenXToGridX(screenXPos, cols, width, gap) {
  const widthMinusGaps = width - gap * (cols - 1);
  const itemWidth = widthMinusGaps / cols;
  const widthMinusOneItem = width - itemWidth;
  const colWidthWithGap = widthMinusOneItem / (cols - 1);
  return Math.round(screenXPos / colWidthWithGap);
}
function screenYToGridY(screenYPos, rowHeight, height, gap) {
  return Math.round(screenYPos / (rowHeight + gap));
}
function screenWidthToGridWidth(gridScreenWidth, cols, width, gap) {
  const widthMinusGaps = width - gap * (cols - 1);
  const itemWidth = widthMinusGaps / cols;
  const gridScreenWidthMinusFirst = gridScreenWidth - itemWidth;
  return Math.round(gridScreenWidthMinusFirst / (itemWidth + gap)) + 1;
}
function screenHeightToGridHeight(gridScreenHeight, rowHeight, height, gap) {
  const gridScreenHeightMinusFirst = gridScreenHeight - rowHeight;
  return Math.round(gridScreenHeightMinusFirst / (rowHeight + gap)) + 1;
}
/** Returns a Dictionary where the key is the id and the value is the change applied to that item. If no changes Dictionary is empty. */
function ktdGetGridLayoutDiff(gridLayoutA, gridLayoutB) {
  const diff = {};
  gridLayoutA.forEach(itemA => {
    const itemB = gridLayoutB.find(_itemB => _itemB.id === itemA.id);
    if (itemB != null) {
      const posChanged = itemA.x !== itemB.x || itemA.y !== itemB.y;
      const sizeChanged = itemA.w !== itemB.w || itemA.h !== itemB.h;
      const change = posChanged && sizeChanged ? 'moveresize' : posChanged ? 'move' : sizeChanged ? 'resize' : null;
      if (change) {
        diff[itemB.id] = {
          change
        };
      }
    }
  });
  return diff;
}
/**
 * Given the grid config & layout data and the current drag position & information, returns the corresponding layout and drag item position
 * @param gridItem grid item that is been dragged
 * @param config current grid configuration
 * @param compactionType type of compaction that will be performed
 * @param draggingData contains all the information about the drag
 */
function ktdGridItemDragging(gridItem, config, compactionType, draggingData) {
  const {
    pointerDownEvent,
    pointerDragEvent,
    gridElemClientRect,
    dragElemClientRect,
    scrollDifference
  } = draggingData;
  const gridItemId = gridItem.id;
  const draggingElemPrevItem = config.layout.find(item => item.id === gridItemId);
  const clientStartX = ktdPointerClientX(pointerDownEvent);
  const clientStartY = ktdPointerClientY(pointerDownEvent);
  const clientX = ktdPointerClientX(pointerDragEvent);
  const clientY = ktdPointerClientY(pointerDragEvent);
  const offsetX = clientStartX - dragElemClientRect.left;
  const offsetY = clientStartY - dragElemClientRect.top;
  // Grid element positions taking into account the possible scroll total difference from the beginning.
  const gridElementLeftPosition = gridElemClientRect.left + scrollDifference.left;
  const gridElementTopPosition = gridElemClientRect.top + scrollDifference.top;
  // Calculate position relative to the grid element.
  const gridRelXPos = clientX - gridElementLeftPosition - offsetX;
  const gridRelYPos = clientY - gridElementTopPosition - offsetY;
  const rowHeightInPixels = config.rowHeight === 'fit' ? ktdGetGridItemRowHeight(config.layout, config.height ?? gridElemClientRect.height, config.gap) : config.rowHeight;
  // Get layout item position
  const layoutItem = {
    ...draggingElemPrevItem,
    x: screenXToGridX(gridRelXPos, config.cols, gridElemClientRect.width, config.gap),
    y: screenYToGridY(gridRelYPos, rowHeightInPixels, gridElemClientRect.height, config.gap)
  };
  // Correct the values if they overflow, since 'moveElement' function doesn't do it
  layoutItem.x = Math.max(0, layoutItem.x);
  layoutItem.y = Math.max(0, layoutItem.y);
  if (layoutItem.x + layoutItem.w > config.cols) {
    layoutItem.x = Math.max(0, config.cols - layoutItem.w);
  }
  // Parse to LayoutItem array data in order to use 'react.grid-layout' utils
  const layoutItems = config.layout;
  const draggedLayoutItem = layoutItems.find(item => item.id === gridItemId);
  let newLayoutItems = moveElement(layoutItems, draggedLayoutItem, layoutItem.x, layoutItem.y, true, config.preventCollision, compactionType, config.cols);
  newLayoutItems = compact(newLayoutItems, compactionType, config.cols);
  return {
    layout: newLayoutItems,
    draggedItemPos: {
      top: gridRelYPos,
      left: gridRelXPos,
      width: dragElemClientRect.width,
      height: dragElemClientRect.height
    }
  };
}
/**
 * Given the grid config & layout data and the current drag position & information, returns the corresponding layout and drag item position
 * @param gridItem grid item that is been dragged
 * @param config current grid configuration
 * @param compactionType type of compaction that will be performed
 * @param draggingData contains all the information about the drag
 */
function ktdGridItemResizing(gridItem, config, compactionType, draggingData) {
  const {
    pointerDownEvent,
    pointerDragEvent,
    gridElemClientRect,
    dragElemClientRect,
    scrollDifference
  } = draggingData;
  const gridItemId = gridItem.id;
  const clientStartX = ktdPointerClientX(pointerDownEvent);
  const clientStartY = ktdPointerClientY(pointerDownEvent);
  const clientX = ktdPointerClientX(pointerDragEvent);
  const clientY = ktdPointerClientY(pointerDragEvent);
  // Get the difference between the mouseDown and the position 'right' of the resize element.
  const resizeElemOffsetX = dragElemClientRect.width - (clientStartX - dragElemClientRect.left);
  const resizeElemOffsetY = dragElemClientRect.height - (clientStartY - dragElemClientRect.top);
  const draggingElemPrevItem = config.layout.find(item => item.id === gridItemId);
  const width = clientX + resizeElemOffsetX - (dragElemClientRect.left + scrollDifference.left);
  const height = clientY + resizeElemOffsetY - (dragElemClientRect.top + scrollDifference.top);
  const rowHeightInPixels = config.rowHeight === 'fit' ? ktdGetGridItemRowHeight(config.layout, config.height ?? gridElemClientRect.height, config.gap) : config.rowHeight;
  // Get layout item grid position
  const layoutItem = {
    ...draggingElemPrevItem,
    w: screenWidthToGridWidth(width, config.cols, gridElemClientRect.width, config.gap),
    h: screenHeightToGridHeight(height, rowHeightInPixels, gridElemClientRect.height, config.gap)
  };
  layoutItem.w = limitNumberWithinRange(layoutItem.w, gridItem.minW ?? layoutItem.minW, gridItem.maxW ?? layoutItem.maxW);
  layoutItem.h = limitNumberWithinRange(layoutItem.h, gridItem.minH ?? layoutItem.minH, gridItem.maxH ?? layoutItem.maxH);
  if (layoutItem.x + layoutItem.w > config.cols) {
    layoutItem.w = Math.max(1, config.cols - layoutItem.x);
  }
  if (config.preventCollision) {
    const maxW = layoutItem.w;
    const maxH = layoutItem.h;
    let colliding = hasCollision(config.layout, layoutItem);
    let shrunkDimension;
    while (colliding) {
      shrunkDimension = getDimensionToShrink(layoutItem, shrunkDimension);
      layoutItem[shrunkDimension]--;
      colliding = hasCollision(config.layout, layoutItem);
    }
    if (shrunkDimension === 'w') {
      layoutItem.h = maxH;
      colliding = hasCollision(config.layout, layoutItem);
      while (colliding) {
        layoutItem.h--;
        colliding = hasCollision(config.layout, layoutItem);
      }
    }
    if (shrunkDimension === 'h') {
      layoutItem.w = maxW;
      colliding = hasCollision(config.layout, layoutItem);
      while (colliding) {
        layoutItem.w--;
        colliding = hasCollision(config.layout, layoutItem);
      }
    }
  }
  const newLayoutItems = config.layout.map(item => {
    return item.id === gridItemId ? layoutItem : item;
  });
  return {
    layout: compact(newLayoutItems, compactionType, config.cols),
    draggedItemPos: {
      top: dragElemClientRect.top - gridElemClientRect.top,
      left: dragElemClientRect.left - gridElemClientRect.left,
      width,
      height
    }
  };
}
function hasCollision(layout, layoutItem) {
  return !!getFirstCollision(layout, layoutItem);
}
function getDimensionToShrink(layoutItem, lastShrunk) {
  if (layoutItem.h <= 1) {
    return 'w';
  }
  if (layoutItem.w <= 1) {
    return 'h';
  }
  return lastShrunk === 'w' ? 'h' : 'w';
}
/**
 * Given the current number and min/max values, returns the number within the range
 * @param number can be any numeric value
 * @param min minimum value of range
 * @param max maximum value of range
 */
function limitNumberWithinRange(num, min = 1, max = Infinity) {
  return Math.min(Math.max(num, min < 1 ? 1 : min), max);
}
/** Returns true if both item1 and item2 KtdGridLayoutItems are equivalent. */
function ktdGridItemLayoutItemAreEqual(item1, item2) {
  return item1.id === item2.id && item1.x === item2.x && item1.y === item2.y && item1.w === item2.w && item1.h === item2.h;
}

/**
 * Injection token that can be used to reference instances of `KtdGridDragHandle`. It serves as
 * alternative token to the actual `KtdGridDragHandle` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
const KTD_GRID_DRAG_HANDLE = new InjectionToken('KtdGridDragHandle');
/** Handle that can be used to drag a KtdGridItem instance. */
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class KtdGridDragHandle {
  constructor(element) {
    this.element = element;
  }
}
KtdGridDragHandle.ɵfac = function KtdGridDragHandle_Factory(t) {
  return new (t || KtdGridDragHandle)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
KtdGridDragHandle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: KtdGridDragHandle,
  selectors: [["", "ktdGridDragHandle", ""]],
  hostAttrs: [1, "ktd-grid-drag-handle"],
  features: [i0.ɵɵProvidersFeature([{
    provide: KTD_GRID_DRAG_HANDLE,
    useExisting: KtdGridDragHandle
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KtdGridDragHandle, [{
    type: Directive,
    args: [{
      selector: '[ktdGridDragHandle]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'ktd-grid-drag-handle'
      },
      providers: [{
        provide: KTD_GRID_DRAG_HANDLE,
        useExisting: KtdGridDragHandle
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();

/**
 * Injection token that can be used to reference instances of `KtdGridResizeHandle`. It serves as
 * alternative token to the actual `KtdGridResizeHandle` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
const KTD_GRID_RESIZE_HANDLE = new InjectionToken('KtdGridResizeHandle');
/** Handle that can be used to drag a KtdGridItem instance. */
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class KtdGridResizeHandle {
  constructor(element) {
    this.element = element;
  }
}
KtdGridResizeHandle.ɵfac = function KtdGridResizeHandle_Factory(t) {
  return new (t || KtdGridResizeHandle)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
KtdGridResizeHandle.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: KtdGridResizeHandle,
  selectors: [["", "ktdGridResizeHandle", ""]],
  hostAttrs: [1, "ktd-grid-resize-handle"],
  features: [i0.ɵɵProvidersFeature([{
    provide: KTD_GRID_RESIZE_HANDLE,
    useExisting: KtdGridResizeHandle
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KtdGridResizeHandle, [{
    type: Directive,
    args: [{
      selector: '[ktdGridResizeHandle]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'ktd-grid-resize-handle'
      },
      providers: [{
        provide: KTD_GRID_RESIZE_HANDLE,
        useExisting: KtdGridResizeHandle
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, null);
})();

/**
 * Injection token that can be used to reference instances of `KtdGridItemPlaceholder`. It serves as
 * alternative token to the actual `KtdGridItemPlaceholder` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
const KTD_GRID_ITEM_PLACEHOLDER = new InjectionToken('KtdGridItemPlaceholder');
/** Directive that can be used to create a custom placeholder for a KtdGridItem instance. */
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class KtdGridItemPlaceholder {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
KtdGridItemPlaceholder.ɵfac = function KtdGridItemPlaceholder_Factory(t) {
  return new (t || KtdGridItemPlaceholder)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
KtdGridItemPlaceholder.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: KtdGridItemPlaceholder,
  selectors: [["ng-template", "ktdGridItemPlaceholder", ""]],
  hostAttrs: [1, "ktd-grid-item-placeholder-content"],
  inputs: {
    data: "data"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: KTD_GRID_ITEM_PLACEHOLDER,
    useExisting: KtdGridItemPlaceholder
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KtdGridItemPlaceholder, [{
    type: Directive,
    args: [{
      selector: 'ng-template[ktdGridItemPlaceholder]',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'ktd-grid-item-placeholder-content'
      },
      providers: [{
        provide: KTD_GRID_ITEM_PLACEHOLDER,
        useExisting: KtdGridItemPlaceholder
      }]
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, {
    data: [{
      type: Input
    }]
  });
})();
const GRID_ITEM_GET_RENDER_DATA_TOKEN = new InjectionToken('GRID_ITEM_GET_RENDER_DATA_TOKEN');

/** Runs source observable outside the zone */
function ktdOutsideZone(zone) {
  return source => {
    return new Observable(observer => {
      return zone.runOutsideAngular(() => source.subscribe(observer));
    });
  };
}
/** Rxjs operator that makes source observable to no emit any data */
function ktdNoEmit() {
  return source$ => {
    return source$.pipe(filter(() => false));
  };
}

/** Coerces a data-bound value (typically a string) to a boolean. */
function coerceBooleanProperty(value) {
  return value != null && `${value}` !== 'false';
}
function coerceNumberProperty(value, fallbackValue = 0) {
  return _isNumberValue(value) ? Number(value) : fallbackValue;
}
/**
 * Whether the provided value is considered a number.
 * @docs-private
 */
function _isNumberValue(value) {
  // parseFloat(value) handles most of the cases we're interested in (it treats null, empty string,
  // and other non-number values as NaN, where Number just uses 0) but it considers the string
  // '123hello' to be a valid number. Therefore we also check if Number(value) is NaN.
  return !isNaN(parseFloat(value)) && !isNaN(Number(value));
}

/** Event options that can be used to bind an active, capturing event. */
const activeCapturingEventOptions = ktdNormalizePassiveListenerOptions({
  passive: false,
  capture: true
});
class KtdGridService {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this.touchMoveSubject = new Subject();
    this.touchMove$ = this.touchMoveSubject.asObservable();
    this.registerTouchMoveSubscription();
  }
  ngOnDestroy() {
    this.touchMoveSubscription.unsubscribe();
  }
  mouseOrTouchMove$(element) {
    return iif(() => ktdIsMobileOrTablet(), this.touchMove$, fromEvent(element, 'mousemove', activeCapturingEventOptions) // TODO: Fix rxjs typings, boolean should be a good param too.
    );
  }
  registerTouchMoveSubscription() {
    // The `touchmove` event gets bound once, ahead of time, because WebKit
    // won't preventDefault on a dynamically-added `touchmove` listener.
    // See https://bugs.webkit.org/show_bug.cgi?id=184250.
    this.touchMoveSubscription = this.ngZone.runOutsideAngular(() =>
    // The event handler has to be explicitly active,
    // because newer browsers make it passive by default.
    fromEvent(document, 'touchmove', activeCapturingEventOptions) // TODO: Fix rxjs typings, boolean should be a good param too.
    .pipe(filter(touchEvent => touchEvent.touches.length === 1)).subscribe(touchEvent => this.touchMoveSubject.next(touchEvent)));
  }
}
KtdGridService.ɵfac = function KtdGridService_Factory(t) {
  return new (t || KtdGridService)(i0.ɵɵinject(i0.NgZone));
};
KtdGridService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: KtdGridService,
  factory: KtdGridService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KtdGridService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
class KtdGridItemComponent {
  constructor(elementRef, gridService, renderer, ngZone, getItemRenderData) {
    this.elementRef = elementRef;
    this.gridService = gridService;
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.getItemRenderData = getItemRenderData;
    /** CSS transition style. Note that for more performance is preferable only make transition on transform property. */
    this.transition = 'transform 500ms ease, width 500ms ease, height 500ms ease';
    this._dragStartThreshold = 0;
    this._draggable = true;
    this._draggable$ = new BehaviorSubject(this._draggable);
    this._manualDragEvents$ = new Subject();
    this._resizable = true;
    this._resizable$ = new BehaviorSubject(this._resizable);
    this.dragStartSubject = new Subject();
    this.resizeStartSubject = new Subject();
    this.subscriptions = [];
    this.dragStart$ = this.dragStartSubject.asObservable();
    this.resizeStart$ = this.resizeStartSubject.asObservable();
  }
  /** Id of the grid item. This property is strictly compulsory. */
  get id() {
    return this._id;
  }
  set id(val) {
    this._id = val;
  }
  /** Minimum amount of pixels that the user should move before it starts the drag sequence. */
  get dragStartThreshold() {
    return this._dragStartThreshold;
  }
  set dragStartThreshold(val) {
    this._dragStartThreshold = coerceNumberProperty(val);
  }
  /** Whether the item is draggable or not. Defaults to true. Does not affect manual dragging using the startDragManually method. */
  get draggable() {
    return this._draggable;
  }
  set draggable(val) {
    this._draggable = coerceBooleanProperty(val);
    this._draggable$.next(this._draggable);
  }
  /** Whether the item is resizable or not. Defaults to true. */
  get resizable() {
    return this._resizable;
  }
  set resizable(val) {
    this._resizable = coerceBooleanProperty(val);
    this._resizable$.next(this._resizable);
  }
  ngOnInit() {
    const gridItemRenderData = this.getItemRenderData(this.id);
    this.setStyles(gridItemRenderData);
  }
  ngAfterContentInit() {
    this.subscriptions.push(this._dragStart$().subscribe(this.dragStartSubject), this._resizeStart$().subscribe(this.resizeStartSubject));
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  /**
   * To manually start dragging, route the desired pointer events to this method.
   * Dragging initiated by this method will work regardless of the value of the draggable Input.
   * It is the caller's responsibility to call this method with only the events that are desired to cause a drag.
   * For example, if you only want left clicks to cause a drag, it is your responsibility to filter out other mouse button events.
   * @param startEvent The pointer event that should initiate the drag.
   */
  startDragManually(startEvent) {
    this._manualDragEvents$.next(startEvent);
  }
  setStyles({
    top,
    left,
    width,
    height
  }) {
    // transform is 6x times faster than top/left
    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', `translateX(${left}) translateY(${top})`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', `block`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'transition', this.transition);
    if (width != null) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', width);
    }
    if (height != null) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'height', height);
    }
  }
  _dragStart$() {
    return merge(this._manualDragEvents$, this._draggable$.pipe(switchMap(draggable => {
      if (!draggable) {
        return NEVER;
      }
      return this._dragHandles.changes.pipe(startWith(this._dragHandles), switchMap(dragHandles => {
        return iif(() => dragHandles.length > 0, merge(...dragHandles.toArray().map(dragHandle => ktdMouseOrTouchDown(dragHandle.element.nativeElement, 1))), ktdMouseOrTouchDown(this.elementRef.nativeElement, 1));
      }));
    }))).pipe(exhaustMap(startEvent => {
      // If the event started from an element with the native HTML drag&drop, it'll interfere
      // with our own dragging (e.g. `img` tags do it by default). Prevent the default action
      // to stop it from happening. Note that preventing on `dragstart` also seems to work, but
      // it's flaky and it fails if the user drags it away quickly. Also note that we only want
      // to do this for `mousedown` since doing the same for `touchstart` will stop any `click`
      // events from firing on touch devices.
      if (startEvent.target && startEvent.target.draggable && startEvent.type === 'mousedown') {
        startEvent.preventDefault();
      }
      const startPointer = ktdPointerClient(startEvent);
      return this.gridService.mouseOrTouchMove$(document).pipe(takeUntil(ktdMouseOrTouchEnd(document, 1)), ktdOutsideZone(this.ngZone), filter(moveEvent => {
        moveEvent.preventDefault();
        const movePointer = ktdPointerClient(moveEvent);
        const distanceX = Math.abs(startPointer.clientX - movePointer.clientX);
        const distanceY = Math.abs(startPointer.clientY - movePointer.clientY);
        // When this conditions returns true mean that we are over threshold.
        return distanceX + distanceY >= this.dragStartThreshold;
      }), take(1),
      // Return the original start event
      map(() => startEvent));
    }));
  }
  _resizeStart$() {
    return this._resizable$.pipe(switchMap(resizable => {
      if (!resizable) {
        // Side effect to hide the resizeElem if resize is disabled.
        this.renderer.setStyle(this.resizeElem.nativeElement, 'display', 'none');
        return NEVER;
      } else {
        return this._resizeHandles.changes.pipe(startWith(this._resizeHandles), switchMap(resizeHandles => {
          if (resizeHandles.length > 0) {
            // Side effect to hide the resizeElem if there are resize handles.
            this.renderer.setStyle(this.resizeElem.nativeElement, 'display', 'none');
            return merge(...resizeHandles.toArray().map(resizeHandle => ktdMouseOrTouchDown(resizeHandle.element.nativeElement, 1)));
          } else {
            this.renderer.setStyle(this.resizeElem.nativeElement, 'display', 'block');
            return ktdMouseOrTouchDown(this.resizeElem.nativeElement, 1);
          }
        }));
      }
    }));
  }
}
KtdGridItemComponent.ɵfac = function KtdGridItemComponent_Factory(t) {
  return new (t || KtdGridItemComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(KtdGridService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(GRID_ITEM_GET_RENDER_DATA_TOKEN));
};
KtdGridItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: KtdGridItemComponent,
  selectors: [["ktd-grid-item"]],
  contentQueries: function KtdGridItemComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, KTD_GRID_ITEM_PLACEHOLDER, 5);
      i0.ɵɵcontentQuery(dirIndex, KTD_GRID_DRAG_HANDLE, 5);
      i0.ɵɵcontentQuery(dirIndex, KTD_GRID_RESIZE_HANDLE, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.placeholder = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._dragHandles = _t);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._resizeHandles = _t);
    }
  },
  viewQuery: function KtdGridItemComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.resizeElem = _t.first);
    }
  },
  inputs: {
    minW: "minW",
    minH: "minH",
    maxW: "maxW",
    maxH: "maxH",
    transition: "transition",
    id: "id",
    dragStartThreshold: "dragStartThreshold",
    draggable: "draggable",
    resizable: "resizable"
  },
  ngContentSelectors: _c1,
  decls: 3,
  vars: 0,
  consts: [[1, "grid-item-resize-icon"], ["resizeElem", ""]],
  template: function KtdGridItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
      i0.ɵɵelement(1, "div", 0, 1);
    }
  },
  styles: ["[_nghost-%COMP%]{display:none;position:absolute;z-index:1;overflow:hidden}[_nghost-%COMP%]   div[_ngcontent-%COMP%]{position:absolute;-webkit-user-select:none;user-select:none;z-index:10}[_nghost-%COMP%]   div.grid-item-resize-icon[_ngcontent-%COMP%]{cursor:se-resize;width:20px;height:20px;bottom:0;right:0;color:inherit}[_nghost-%COMP%]   div.grid-item-resize-icon[_ngcontent-%COMP%]:after{content:\"\";position:absolute;right:3px;bottom:3px;width:5px;height:5px;border-right:2px solid;border-bottom:2px solid}.display-none[_ngcontent-%COMP%]{display:none!important}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KtdGridItemComponent, [{
    type: Component,
    args: [{
      selector: 'ktd-grid-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content></ng-content>\r\n<div #resizeElem class=\"grid-item-resize-icon\"></div>",
      styles: [":host{display:none;position:absolute;z-index:1;overflow:hidden}:host div{position:absolute;-webkit-user-select:none;user-select:none;z-index:10}:host div.grid-item-resize-icon{cursor:se-resize;width:20px;height:20px;bottom:0;right:0;color:inherit}:host div.grid-item-resize-icon:after{content:\"\";position:absolute;right:3px;bottom:3px;width:5px;height:5px;border-right:2px solid;border-bottom:2px solid}.display-none{display:none!important}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: KtdGridService
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [GRID_ITEM_GET_RENDER_DATA_TOKEN]
      }]
    }];
  }, {
    _dragHandles: [{
      type: ContentChildren,
      args: [KTD_GRID_DRAG_HANDLE, {
        descendants: true
      }]
    }],
    _resizeHandles: [{
      type: ContentChildren,
      args: [KTD_GRID_RESIZE_HANDLE, {
        descendants: true
      }]
    }],
    resizeElem: [{
      type: ViewChild,
      args: ['resizeElem', {
        static: true,
        read: ElementRef
      }]
    }],
    placeholder: [{
      type: ContentChild,
      args: [KTD_GRID_ITEM_PLACEHOLDER]
    }],
    minW: [{
      type: Input
    }],
    minH: [{
      type: Input
    }],
    maxW: [{
      type: Input
    }],
    maxH: [{
      type: Input
    }],
    transition: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    dragStartThreshold: [{
      type: Input
    }],
    draggable: [{
      type: Input
    }],
    resizable: [{
      type: Input
    }]
  });
})();

/**
 * Client rect utilities.
 * This file is taken from Angular Material repository.
 */
/** Gets a mutable version of an element's bounding `ClientRect`. */
function getMutableClientRect(element) {
  const clientRect = element.getBoundingClientRect();
  // We need to clone the `clientRect` here, because all the values on it are readonly
  // and we need to be able to update them. Also we can't use a spread here, because
  // the values on a `ClientRect` aren't own properties. See:
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect#Notes
  return {
    top: clientRect.top,
    right: clientRect.right,
    bottom: clientRect.bottom,
    left: clientRect.left,
    width: clientRect.width,
    height: clientRect.height
  };
}
/**
 * Checks whether some coordinates are within a `ClientRect`.
 * @param clientRect ClientRect that is being checked.
 * @param x Coordinates along the X axis.
 * @param y Coordinates along the Y axis.
 */
function isInsideClientRect(clientRect, x, y) {
  const {
    top,
    bottom,
    left,
    right
  } = clientRect;
  return y >= top && y <= bottom && x >= left && x <= right;
}
/**
 * Updates the top/left positions of a `ClientRect`, as well as their bottom/right counterparts.
 * @param clientRect `ClientRect` that should be updated.
 * @param top Amount to add to the `top` position.
 * @param left Amount to add to the `left` position.
 */
function adjustClientRect(clientRect, top, left) {
  clientRect.top += top;
  clientRect.bottom = clientRect.top + clientRect.height;
  clientRect.left += left;
  clientRect.right = clientRect.left + clientRect.width;
}
/**
 * Checks whether the pointer coordinates are close to a ClientRect.
 * @param rect ClientRect to check against.
 * @param threshold Threshold around the ClientRect.
 * @param pointerX Coordinates along the X axis.
 * @param pointerY Coordinates along the Y axis.
 */
function isPointerNearClientRect(rect, threshold, pointerX, pointerY) {
  const {
    top,
    right,
    bottom,
    left,
    width,
    height
  } = rect;
  const xThreshold = width * threshold;
  const yThreshold = height * threshold;
  return pointerY > top - yThreshold && pointerY < bottom + yThreshold && pointerX > left - xThreshold && pointerX < right + xThreshold;
}

/**
 * Proximity, as a ratio to width/height at which to start auto-scrolling.
 * The value comes from trying it out manually until it feels right.
 */
const SCROLL_PROXIMITY_THRESHOLD = 0.05;
/**
 * Increments the vertical scroll position of a node.
 * @param node Node whose scroll position should change.
 * @param amount Amount of pixels that the `node` should be scrolled.
 */
function incrementVerticalScroll(node, amount) {
  if (node === window) {
    node.scrollBy(0, amount);
  } else {
    // Ideally we could use `Element.scrollBy` here as well, but IE and Edge don't support it.
    node.scrollTop += amount;
  }
}
/**
 * Increments the horizontal scroll position of a node.
 * @param node Node whose scroll position should change.
 * @param amount Amount of pixels that the `node` should be scrolled.
 */
function incrementHorizontalScroll(node, amount) {
  if (node === window) {
    node.scrollBy(amount, 0);
  } else {
    // Ideally we could use `Element.scrollBy` here as well, but IE and Edge don't support it.
    node.scrollLeft += amount;
  }
}
/**
 * Gets whether the vertical auto-scroll direction of a node.
 * @param clientRect Dimensions of the node.
 * @param pointerY Position of the user's pointer along the y axis.
 */
function getVerticalScrollDirection(clientRect, pointerY) {
  const {
    top,
    bottom,
    height
  } = clientRect;
  const yThreshold = height * SCROLL_PROXIMITY_THRESHOLD;
  if (pointerY >= top - yThreshold && pointerY <= top + yThreshold) {
    return 1 /* AutoScrollVerticalDirection.UP */;
  } else if (pointerY >= bottom - yThreshold && pointerY <= bottom + yThreshold) {
    return 2 /* AutoScrollVerticalDirection.DOWN */;
  }
  return 0 /* AutoScrollVerticalDirection.NONE */;
}
/**
 * Gets whether the horizontal auto-scroll direction of a node.
 * @param clientRect Dimensions of the node.
 * @param pointerX Position of the user's pointer along the x axis.
 */
function getHorizontalScrollDirection(clientRect, pointerX) {
  const {
    left,
    right,
    width
  } = clientRect;
  const xThreshold = width * SCROLL_PROXIMITY_THRESHOLD;
  if (pointerX >= left - xThreshold && pointerX <= left + xThreshold) {
    return 1 /* AutoScrollHorizontalDirection.LEFT */;
  } else if (pointerX >= right - xThreshold && pointerX <= right + xThreshold) {
    return 2 /* AutoScrollHorizontalDirection.RIGHT */;
  }
  return 0 /* AutoScrollHorizontalDirection.NONE */;
}
/**
 * Returns an observable that schedules a loop and apply scroll on the scrollNode into the specified direction/s.
 * This observable doesn't emit, it just performs the 'scroll' side effect.
 * @param scrollNode, node where the scroll would be applied.
 * @param verticalScrollDirection, vertical direction of the scroll.
 * @param horizontalScrollDirection, horizontal direction of the scroll.
 * @param scrollStep, scroll step in CSS pixels that would be applied in every loop.
 */
function scrollToDirectionInterval$(scrollNode, verticalScrollDirection, horizontalScrollDirection, scrollStep = 2) {
  return interval(0, animationFrameScheduler).pipe(tap(() => {
    if (verticalScrollDirection === 1 /* AutoScrollVerticalDirection.UP */) {
      incrementVerticalScroll(scrollNode, -scrollStep);
    } else if (verticalScrollDirection === 2 /* AutoScrollVerticalDirection.DOWN */) {
      incrementVerticalScroll(scrollNode, scrollStep);
    }
    if (horizontalScrollDirection === 1 /* AutoScrollHorizontalDirection.LEFT */) {
      incrementHorizontalScroll(scrollNode, -scrollStep);
    } else if (horizontalScrollDirection === 2 /* AutoScrollHorizontalDirection.RIGHT */) {
      incrementHorizontalScroll(scrollNode, scrollStep);
    }
  }), ktdNoEmit());
}
/**
 * Given a source$ observable with pointer location, scroll the scrollNode if the pointer is near to it.
 * This observable doesn't emit, it just performs a 'scroll' side effect.
 * @param scrollableParent, parent node in which the scroll would be performed.
 * @param options, configuration options.
 */
function ktdScrollIfNearElementClientRect$(scrollableParent, options) {
  let scrollNode;
  let scrollableParentClientRect;
  let scrollableParentScrollWidth;
  if (scrollableParent === document) {
    scrollNode = document.defaultView;
    const {
      width,
      height
    } = getViewportSize();
    scrollableParentClientRect = {
      width,
      height,
      top: 0,
      right: width,
      bottom: height,
      left: 0
    };
    scrollableParentScrollWidth = getDocumentScrollWidth();
  } else {
    scrollNode = scrollableParent;
    scrollableParentClientRect = getMutableClientRect(scrollableParent);
    scrollableParentScrollWidth = scrollableParent.scrollWidth;
  }
  /**
   * IMPORTANT: By design, only let scroll horizontal if the scrollable parent has explicitly an scroll horizontal.
   * This layout solution is not designed in mind to have any scroll horizontal, but exceptionally we allow it in this
   * specific use case.
   */
  options = options || {};
  if (options.disableHorizontal == null && scrollableParentScrollWidth <= scrollableParentClientRect.width) {
    options.disableHorizontal = true;
  }
  return source$ => source$.pipe(map(({
    pointerX,
    pointerY
  }) => {
    let verticalScrollDirection = getVerticalScrollDirection(scrollableParentClientRect, pointerY);
    let horizontalScrollDirection = getHorizontalScrollDirection(scrollableParentClientRect, pointerX);
    // Check if scroll directions are disabled.
    if (options?.disableVertical) {
      verticalScrollDirection = 0 /* AutoScrollVerticalDirection.NONE */;
    }
    if (options?.disableHorizontal) {
      horizontalScrollDirection = 0 /* AutoScrollHorizontalDirection.NONE */;
    }
    return {
      verticalScrollDirection,
      horizontalScrollDirection
    };
  }), distinctUntilChanged((prev, actual) => {
    return prev.verticalScrollDirection === actual.verticalScrollDirection && prev.horizontalScrollDirection === actual.horizontalScrollDirection;
  }), switchMap(({
    verticalScrollDirection,
    horizontalScrollDirection
  }) => {
    if (verticalScrollDirection || horizontalScrollDirection) {
      return scrollToDirectionInterval$(scrollNode, verticalScrollDirection, horizontalScrollDirection, options?.scrollStep);
    } else {
      return NEVER;
    }
  }));
}
/**
 * Emits on EVERY scroll event and returns the accumulated scroll offset relative to the initial scroll position.
 * @param scrollableParent, node in which scroll events would be listened.
 */
function ktdGetScrollTotalRelativeDifference$(scrollableParent) {
  let scrollInitialPosition;
  // Calculate initial scroll position
  if (scrollableParent === document) {
    scrollInitialPosition = getViewportScrollPosition();
  } else {
    scrollInitialPosition = {
      top: scrollableParent.scrollTop,
      left: scrollableParent.scrollLeft
    };
  }
  return fromEvent(scrollableParent, 'scroll', ktdNormalizePassiveListenerOptions({
    capture: true
  })).pipe(map(() => {
    let newTop;
    let newLeft;
    if (scrollableParent === document) {
      const viewportScrollPosition = getViewportScrollPosition();
      newTop = viewportScrollPosition.top;
      newLeft = viewportScrollPosition.left;
    } else {
      newTop = scrollableParent.scrollTop;
      newLeft = scrollableParent.scrollLeft;
    }
    const topDifference = scrollInitialPosition.top - newTop;
    const leftDifference = scrollInitialPosition.left - newLeft;
    return {
      top: topDifference,
      left: leftDifference
    };
  }));
}
/** Returns the viewport's width and height. */
function getViewportSize() {
  const _window = document.defaultView || window;
  return {
    width: _window.innerWidth,
    height: _window.innerHeight
  };
}
/** Gets a ClientRect for the viewport's bounds. */
function getViewportRect() {
  // Use the document element's bounding rect rather than the window scroll properties
  // (e.g. pageYOffset, scrollY) due to in issue in Chrome and IE where window scroll
  // properties and client coordinates (boundingClientRect, clientX/Y, etc.) are in different
  // conceptual viewports. Under most circumstances these viewports are equivalent, but they
  // can disagree when the page is pinch-zoomed (on devices that support touch).
  // See https://bugs.chromium.org/p/chromium/issues/detail?id=489206#c4
  // We use the documentElement instead of the body because, by default (without a css reset)
  // browsers typically give the document body an 8px margin, which is not included in
  // getBoundingClientRect().
  const scrollPosition = getViewportScrollPosition();
  const {
    width,
    height
  } = getViewportSize();
  return {
    top: scrollPosition.top,
    left: scrollPosition.left,
    bottom: scrollPosition.top + height,
    right: scrollPosition.left + width,
    height,
    width
  };
}
/** Gets the (top, left) scroll position of the viewport. */
function getViewportScrollPosition() {
  // The top-left-corner of the viewport is determined by the scroll position of the document
  // body, normally just (scrollLeft, scrollTop). However, Chrome and Firefox disagree about
  // whether `document.body` or `document.documentElement` is the scrolled element, so reading
  // `scrollTop` and `scrollLeft` is inconsistent. However, using the bounding rect of
  // `document.documentElement` works consistently, where the `top` and `left` values will
  // equal negative the scroll position.
  const windowRef = document.defaultView || window;
  const documentElement = document.documentElement;
  const documentRect = documentElement.getBoundingClientRect();
  const top = -documentRect.top || document.body.scrollTop || windowRef.scrollY || documentElement.scrollTop || 0;
  const left = -documentRect.left || document.body.scrollLeft || windowRef.scrollX || documentElement.scrollLeft || 0;
  return {
    top,
    left
  };
}
/** Returns the document scroll width */
function getDocumentScrollWidth() {
  return Math.max(document.body.scrollWidth, document.documentElement.scrollWidth);
}
function getDragResizeEventData(gridItem, layout) {
  return {
    layout,
    layoutItem: layout.find(item => item.id === gridItem.id),
    gridItemRef: gridItem
  };
}
function getColumnWidth(config, width) {
  const {
    cols,
    gap
  } = config;
  const widthExcludingGap = width - Math.max(gap * (cols - 1), 0);
  return widthExcludingGap / cols;
}
function getRowHeightInPixels(config, height) {
  const {
    rowHeight,
    layout,
    gap
  } = config;
  return rowHeight === 'fit' ? ktdGetGridItemRowHeight(layout, height, gap) : rowHeight;
}
function layoutToRenderItems(config, width, height) {
  const {
    layout,
    gap
  } = config;
  const rowHeightInPixels = getRowHeightInPixels(config, height);
  const itemWidthPerColumn = getColumnWidth(config, width);
  const renderItems = {};
  for (const item of layout) {
    renderItems[item.id] = {
      id: item.id,
      top: item.y * rowHeightInPixels + gap * item.y,
      left: item.x * itemWidthPerColumn + gap * item.x,
      width: item.w * itemWidthPerColumn + gap * Math.max(item.w - 1, 0),
      height: item.h * rowHeightInPixels + gap * Math.max(item.h - 1, 0)
    };
  }
  return renderItems;
}
function getGridHeight(layout, rowHeight, gap) {
  return layout.reduce((acc, cur) => Math.max(acc, (cur.y + cur.h) * rowHeight + Math.max(cur.y + cur.h - 1, 0) * gap), 0);
}
// eslint-disable-next-line @katoid/prefix-exported-code
function parseRenderItemToPixels(renderItem) {
  return {
    id: renderItem.id,
    top: `${renderItem.top}px`,
    left: `${renderItem.left}px`,
    width: `${renderItem.width}px`,
    height: `${renderItem.height}px`
  };
}
// eslint-disable-next-line @katoid/prefix-exported-code
function __gridItemGetRenderDataFactoryFunc(gridCmp) {
  return function (id) {
    return parseRenderItemToPixels(gridCmp.getItemRenderData(id));
  };
}
function ktdGridItemGetRenderDataFactoryFunc(gridCmp) {
  // Workaround explained: https://github.com/ng-packagr/ng-packagr/issues/696#issuecomment-387114613
  const resultFunc = __gridItemGetRenderDataFactoryFunc(gridCmp);
  return resultFunc;
}
const defaultBackgroundConfig = {
  borderColor: '#ffa72678',
  gapColor: 'transparent',
  rowColor: 'transparent',
  columnColor: 'transparent',
  borderWidth: 1
};
class KtdGridComponent {
  constructor(gridService, elementRef, viewContainerRef, renderer, ngZone) {
    this.gridService = gridService;
    this.elementRef = elementRef;
    this.viewContainerRef = viewContainerRef;
    this.renderer = renderer;
    this.ngZone = ngZone;
    /** Emits when layout change */
    this.layoutUpdated = new EventEmitter();
    /** Emits when drag starts */
    this.dragStarted = new EventEmitter();
    /** Emits when resize starts */
    this.resizeStarted = new EventEmitter();
    /** Emits when drag ends */
    this.dragEnded = new EventEmitter();
    /** Emits when resize ends */
    this.resizeEnded = new EventEmitter();
    /** Emits when a grid item is being resized and its bounds have changed */
    this.gridItemResize = new EventEmitter();
    /**
     * Parent element that contains the scroll. If an string is provided it would search that element by id on the dom.
     * If no data provided or null autoscroll is not performed.
     */
    this.scrollableParent = null;
    this._compactOnPropsChange = true;
    this._preventCollision = false;
    this._scrollSpeed = 2;
    this._compactType = 'vertical';
    this._rowHeight = 100;
    this._cols = 6;
    this._gap = 0;
    this._height = null;
    this._backgroundConfig = null;
  }
  /** Whether or not to update the internal layout when some dependent property change. */
  get compactOnPropsChange() {
    return this._compactOnPropsChange;
  }
  set compactOnPropsChange(value) {
    this._compactOnPropsChange = coerceBooleanProperty(value);
  }
  /** If true, grid items won't change position when being dragged over. Handy when using no compaction */
  get preventCollision() {
    return this._preventCollision;
  }
  set preventCollision(value) {
    this._preventCollision = coerceBooleanProperty(value);
  }
  /** Number of CSS pixels that would be scrolled on each 'tick' when auto scroll is performed. */
  get scrollSpeed() {
    return this._scrollSpeed;
  }
  set scrollSpeed(value) {
    this._scrollSpeed = coerceNumberProperty(value, 2);
  }
  /** Type of compaction that will be applied to the layout (vertical, horizontal or free). Defaults to 'vertical' */
  get compactType() {
    return this._compactType;
  }
  set compactType(val) {
    this._compactType = val;
  }
  /**
   * Row height as number or as 'fit'.
   * If rowHeight is a number value, it means that each row would have those css pixels in height.
   * if rowHeight is 'fit', it means that rows will fit in the height available. If 'fit' value is set, a 'height' should be also provided.
   */
  get rowHeight() {
    return this._rowHeight;
  }
  set rowHeight(val) {
    this._rowHeight = val === 'fit' ? val : Math.max(1, Math.round(coerceNumberProperty(val)));
  }
  /** Number of columns  */
  get cols() {
    return this._cols;
  }
  set cols(val) {
    this._cols = Math.max(1, Math.round(coerceNumberProperty(val)));
  }
  /** Layout of the grid. Array of all the grid items with its 'id' and position on the grid. */
  get layout() {
    return this._layout;
  }
  set layout(layout) {
    /**
     * Enhancement:
     * Only set layout if it's reference has changed and use a boolean to track whenever recalculate the layout on ngOnChanges.
     *
     * Why:
     * The normal use of this lib is having the variable layout in the outer component or in a store, assigning it whenever it changes and
     * binded in the component with it's input [layout]. In this scenario, we would always calculate one unnecessary change on the layout when
     * it is re-binded on the input.
     */
    this._layout = layout;
  }
  /** Grid gap in css pixels */
  get gap() {
    return this._gap;
  }
  set gap(val) {
    this._gap = Math.max(coerceNumberProperty(val), 0);
  }
  /**
   * If height is a number, fixes the height of the grid to it, recommended when rowHeight = 'fit' is used.
   * If height is null, height will be automatically set according to its inner grid items.
   * Defaults to null.
   * */
  get height() {
    return this._height;
  }
  set height(val) {
    this._height = typeof val === 'number' ? Math.max(val, 0) : null;
  }
  get backgroundConfig() {
    return this._backgroundConfig;
  }
  set backgroundConfig(val) {
    this._backgroundConfig = val;
    // If there is background configuration, add main grid background class. Grid background class comes with opacity 0.
    // It is done this way for adding opacity animation and to don't add any styles when grid background is null.
    const classList = this.elementRef.nativeElement.classList;
    this._backgroundConfig !== null ? classList.add('ktd-grid-background') : classList.remove('ktd-grid-background');
    // Set background visibility
    this.setGridBackgroundVisible(this._backgroundConfig?.show === 'always');
  }
  get config() {
    return {
      cols: this.cols,
      rowHeight: this.rowHeight,
      height: this.height,
      layout: this.layout,
      preventCollision: this.preventCollision,
      gap: this.gap
    };
  }
  ngOnChanges(changes) {
    if (this.rowHeight === 'fit' && this.height == null) {
      console.warn(`KtdGridComponent: The @Input() height should not be null when using rowHeight 'fit'`);
    }
    let needsCompactLayout = false;
    let needsRecalculateRenderData = false;
    // TODO: Does fist change need to be compacted by default?
    // Compact layout whenever some dependent prop changes.
    if (changes.compactType || changes.cols || changes.layout) {
      needsCompactLayout = true;
    }
    // Check if wee need to recalculate rendering data.
    if (needsCompactLayout || changes.rowHeight || changes.height || changes.gap || changes.backgroundConfig) {
      needsRecalculateRenderData = true;
    }
    // Only compact layout if lib user has provided it. Lib users that want to save/store always the same layout  as it is represented (compacted)
    // can use KtdCompactGrid utility and pre-compact the layout. This is the recommended behaviour for always having a the same layout on this component
    // and the ones that uses it.
    if (needsCompactLayout && this.compactOnPropsChange) {
      this.compactLayout();
    }
    if (needsRecalculateRenderData) {
      this.calculateRenderData();
    }
  }
  ngAfterContentInit() {
    this.initSubscriptions();
  }
  ngAfterContentChecked() {
    this.render();
  }
  resize() {
    this.calculateRenderData();
    this.render();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  compactLayout() {
    this.layout = compact(this.layout, this.compactType, this.cols);
  }
  getItemsRenderData() {
    return {
      ...this._gridItemsRenderData
    };
  }
  getItemRenderData(itemId) {
    return this._gridItemsRenderData[itemId];
  }
  calculateRenderData() {
    const clientRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.gridCurrentHeight = this.height ?? (this.rowHeight === 'fit' ? clientRect.height : getGridHeight(this.layout, this.rowHeight, this.gap));
    this._gridItemsRenderData = layoutToRenderItems(this.config, clientRect.width, this.gridCurrentHeight);
    // Set Background CSS variables
    this.setBackgroundCssVariables(getRowHeightInPixels(this.config, this.gridCurrentHeight));
  }
  render() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${this.gridCurrentHeight}px`);
    this.updateGridItemsStyles();
  }
  setBackgroundCssVariables(rowHeight) {
    const style = this.elementRef.nativeElement.style;
    if (this._backgroundConfig) {
      // structure
      style.setProperty('--gap', this.gap + 'px');
      style.setProperty('--row-height', rowHeight + 'px');
      style.setProperty('--columns', `${this.cols}`);
      style.setProperty('--border-width', (this._backgroundConfig.borderWidth ?? defaultBackgroundConfig.borderWidth) + 'px');
      // colors
      style.setProperty('--border-color', this._backgroundConfig.borderColor ?? defaultBackgroundConfig.borderColor);
      style.setProperty('--gap-color', this._backgroundConfig.gapColor ?? defaultBackgroundConfig.gapColor);
      style.setProperty('--row-color', this._backgroundConfig.rowColor ?? defaultBackgroundConfig.rowColor);
      style.setProperty('--column-color', this._backgroundConfig.columnColor ?? defaultBackgroundConfig.columnColor);
    } else {
      style.removeProperty('--gap');
      style.removeProperty('--row-height');
      style.removeProperty('--columns');
      style.removeProperty('--border-width');
      style.removeProperty('--border-color');
      style.removeProperty('--gap-color');
      style.removeProperty('--row-color');
      style.removeProperty('--column-color');
    }
  }
  updateGridItemsStyles() {
    this._gridItems.forEach(item => {
      const gridItemRenderData = this._gridItemsRenderData[item.id];
      if (gridItemRenderData == null) {
        console.error(`Couldn\'t find the specified grid item for the id: ${item.id}`);
      } else {
        item.setStyles(parseRenderItemToPixels(gridItemRenderData));
      }
    });
  }
  setGridBackgroundVisible(visible) {
    const classList = this.elementRef.nativeElement.classList;
    visible ? classList.add('ktd-grid-background-visible') : classList.remove('ktd-grid-background-visible');
  }
  initSubscriptions() {
    this.subscriptions = [this._gridItems.changes.pipe(startWith(this._gridItems), switchMap(gridItems => {
      return merge(...gridItems.map(gridItem => gridItem.dragStart$.pipe(map(event => ({
        event,
        gridItem,
        type: 'drag'
      })))), ...gridItems.map(gridItem => gridItem.resizeStart$.pipe(map(event => ({
        event,
        gridItem,
        type: 'resize'
      }))))).pipe(exhaustMap(({
        event,
        gridItem,
        type
      }) => {
        // Emit drag or resize start events. Ensure that is start event is inside the zone.
        this.ngZone.run(() => (type === 'drag' ? this.dragStarted : this.resizeStarted).emit(getDragResizeEventData(gridItem, this.layout)));
        this.setGridBackgroundVisible(this._backgroundConfig?.show === 'whenDragging' || this._backgroundConfig?.show === 'always');
        // Perform drag sequence
        return this.performDragSequence$(gridItem, event, type).pipe(map(layout => ({
          layout,
          gridItem,
          type
        })));
      }));
    })).subscribe(({
      layout,
      gridItem,
      type
    }) => {
      this.layout = layout;
      // Calculate new rendering data given the new layout.
      this.calculateRenderData();
      // Emit drag or resize end events.
      (type === 'drag' ? this.dragEnded : this.resizeEnded).emit(getDragResizeEventData(gridItem, layout));
      // Notify that the layout has been updated.
      this.layoutUpdated.emit(layout);
      this.setGridBackgroundVisible(this._backgroundConfig?.show === 'always');
    })];
  }
  /**
   * Perform a general grid drag action, from start to end. A general grid drag action basically includes creating the placeholder element and adding
   * some class animations. calcNewStateFunc needs to be provided in order to calculate the new state of the layout.
   * @param gridItem that is been dragged
   * @param pointerDownEvent event (mousedown or touchdown) where the user initiated the drag
   * @param calcNewStateFunc function that return the new layout state and the drag element position
   */
  performDragSequence$(gridItem, pointerDownEvent, type) {
    return new Observable(observer => {
      // Retrieve grid (parent) and gridItem (draggedElem) client rects.
      const gridElemClientRect = getMutableClientRect(this.elementRef.nativeElement);
      const dragElemClientRect = getMutableClientRect(gridItem.elementRef.nativeElement);
      const scrollableParent = typeof this.scrollableParent === 'string' ? document.getElementById(this.scrollableParent) : this.scrollableParent;
      this.renderer.addClass(gridItem.elementRef.nativeElement, 'no-transitions');
      this.renderer.addClass(gridItem.elementRef.nativeElement, 'ktd-grid-item-dragging');
      const placeholderClientRect = {
        ...dragElemClientRect,
        left: dragElemClientRect.left - gridElemClientRect.left,
        top: dragElemClientRect.top - gridElemClientRect.top
      };
      this.createPlaceholderElement(placeholderClientRect, gridItem.placeholder);
      let newLayout;
      // TODO (enhancement): consider move this 'side effect' observable inside the main drag loop.
      //  - Pros are that we would not repeat subscriptions and takeUntil would shut down observables at the same time.
      //  - Cons are that moving this functionality as a side effect inside the main drag loop would be confusing.
      const scrollSubscription = this.ngZone.runOutsideAngular(() => (!scrollableParent ? NEVER : this.gridService.mouseOrTouchMove$(document).pipe(map(event => ({
        pointerX: ktdPointerClientX(event),
        pointerY: ktdPointerClientY(event)
      })), ktdScrollIfNearElementClientRect$(scrollableParent, {
        scrollStep: this.scrollSpeed
      }))).pipe(takeUntil(ktdMouseOrTouchEnd(document))).subscribe());
      /**
       * Main subscription, it listens for 'pointer move' and 'scroll' events and recalculates the layout on each emission
       */
      const subscription = this.ngZone.runOutsideAngular(() => merge(combineLatest([this.gridService.mouseOrTouchMove$(document), ...(!scrollableParent ? [of({
        top: 0,
        left: 0
      })] : [ktdGetScrollTotalRelativeDifference$(scrollableParent).pipe(startWith({
        top: 0,
        left: 0
      }) // Force first emission to allow CombineLatest to emit even no scroll event has occurred
      )])])).pipe(takeUntil(ktdMouseOrTouchEnd(document))).subscribe(([pointerDragEvent, scrollDifference]) => {
        pointerDragEvent.preventDefault();
        /**
         * Set the new layout to be the layout in which the calcNewStateFunc would be executed.
         * NOTE: using the mutated layout is the way to go by 'react-grid-layout' utils. If we don't use the previous layout,
         * some utilities from 'react-grid-layout' would not work as expected.
         */
        const currentLayout = newLayout || this.layout;
        // Get the correct newStateFunc depending on if we are dragging or resizing
        const calcNewStateFunc = type === 'drag' ? ktdGridItemDragging : ktdGridItemResizing;
        const {
          layout,
          draggedItemPos
        } = calcNewStateFunc(gridItem, {
          layout: currentLayout,
          rowHeight: this.rowHeight,
          height: this.height,
          cols: this.cols,
          preventCollision: this.preventCollision,
          gap: this.gap
        }, this.compactType, {
          pointerDownEvent,
          pointerDragEvent,
          gridElemClientRect,
          dragElemClientRect,
          scrollDifference
        });
        newLayout = layout;
        this.gridCurrentHeight = this.height ?? (this.rowHeight === 'fit' ? gridElemClientRect.height : getGridHeight(newLayout, this.rowHeight, this.gap));
        this._gridItemsRenderData = layoutToRenderItems({
          cols: this.cols,
          rowHeight: this.rowHeight,
          height: this.height,
          layout: newLayout,
          preventCollision: this.preventCollision,
          gap: this.gap
        }, gridElemClientRect.width, gridElemClientRect.height);
        const newGridItemRenderData = {
          ...this._gridItemsRenderData[gridItem.id]
        };
        const placeholderStyles = parseRenderItemToPixels(newGridItemRenderData);
        // Put the real final position to the placeholder element
        this.placeholder.style.width = placeholderStyles.width;
        this.placeholder.style.height = placeholderStyles.height;
        this.placeholder.style.transform = `translateX(${placeholderStyles.left}) translateY(${placeholderStyles.top})`;
        // modify the position of the dragged item to be the once we want (for example the mouse position or whatever)
        this._gridItemsRenderData[gridItem.id] = {
          ...draggedItemPos,
          id: this._gridItemsRenderData[gridItem.id].id
        };
        this.setBackgroundCssVariables(this.rowHeight === 'fit' ? ktdGetGridItemRowHeight(newLayout, gridElemClientRect.height, this.gap) : this.rowHeight);
        this.render();
        // If we are performing a resize, and bounds have changed, emit event.
        // NOTE: Only emit on resize for now. Use case for normal drag is not justified for now. Emitting on resize is, since we may want to re-render the grid item or the placeholder in order to fit the new bounds.
        if (type === 'resize') {
          const prevGridItem = currentLayout.find(item => item.id === gridItem.id);
          const newGridItem = newLayout.find(item => item.id === gridItem.id);
          // Check if item resized has changed, if so, emit resize change event
          if (!ktdGridItemLayoutItemAreEqual(prevGridItem, newGridItem)) {
            this.gridItemResize.emit({
              width: newGridItemRenderData.width,
              height: newGridItemRenderData.height,
              gridItemRef: getDragResizeEventData(gridItem, newLayout).gridItemRef
            });
          }
        }
      }, error => observer.error(error), () => {
        this.ngZone.run(() => {
          // Remove drag classes
          this.renderer.removeClass(gridItem.elementRef.nativeElement, 'no-transitions');
          this.renderer.removeClass(gridItem.elementRef.nativeElement, 'ktd-grid-item-dragging');
          this.destroyPlaceholder();
          if (newLayout) {
            // TODO: newLayout should already be pruned. If not, it should have type Layout, not KtdGridLayout as it is now.
            // Prune react-grid-layout compact extra properties.
            observer.next(newLayout.map(item => ({
              id: item.id,
              x: item.x,
              y: item.y,
              w: item.w,
              h: item.h,
              minW: item.minW,
              minH: item.minH,
              maxW: item.maxW,
              maxH: item.maxH
            })));
          } else {
            // TODO: Need we really to emit if there is no layout change but drag started and ended?
            observer.next(this.layout);
          }
          observer.complete();
        });
      }));
      return () => {
        scrollSubscription.unsubscribe();
        subscription.unsubscribe();
      };
    });
  }
  /** Creates placeholder element */
  createPlaceholderElement(clientRect, gridItemPlaceholder) {
    this.placeholder = this.renderer.createElement('div');
    this.placeholder.style.width = `${clientRect.width}px`;
    this.placeholder.style.height = `${clientRect.height}px`;
    this.placeholder.style.transform = `translateX(${clientRect.left}px) translateY(${clientRect.top}px)`;
    this.placeholder.classList.add('ktd-grid-item-placeholder');
    this.renderer.appendChild(this.elementRef.nativeElement, this.placeholder);
    // Create and append custom placeholder if provided.
    // Important: Append it after creating & appending the container placeholder. This way we ensure parent bounds are set when creating the embeddedView.
    if (gridItemPlaceholder) {
      this.placeholderRef = this.viewContainerRef.createEmbeddedView(gridItemPlaceholder.templateRef, gridItemPlaceholder.data);
      this.placeholderRef.rootNodes.forEach(node => this.placeholder.appendChild(node));
      this.placeholderRef.detectChanges();
    } else {
      this.placeholder.classList.add('ktd-grid-item-placeholder-default');
    }
  }
  /** Destroys the placeholder element and its ViewRef. */
  destroyPlaceholder() {
    this.placeholder?.remove();
    this.placeholderRef?.destroy();
    this.placeholder = this.placeholderRef = null;
  }
}
KtdGridComponent.ɵfac = function KtdGridComponent_Factory(t) {
  return new (t || KtdGridComponent)(i0.ɵɵdirectiveInject(KtdGridService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
KtdGridComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: KtdGridComponent,
  selectors: [["ktd-grid"]],
  contentQueries: function KtdGridComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, KtdGridItemComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._gridItems = _t);
    }
  },
  inputs: {
    scrollableParent: "scrollableParent",
    compactOnPropsChange: "compactOnPropsChange",
    preventCollision: "preventCollision",
    scrollSpeed: "scrollSpeed",
    compactType: "compactType",
    rowHeight: "rowHeight",
    cols: "cols",
    layout: "layout",
    gap: "gap",
    height: "height",
    backgroundConfig: "backgroundConfig"
  },
  outputs: {
    layoutUpdated: "layoutUpdated",
    dragStarted: "dragStarted",
    resizeStarted: "resizeStarted",
    dragEnded: "dragEnded",
    resizeEnded: "resizeEnded",
    gridItemResize: "gridItemResize"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: GRID_ITEM_GET_RENDER_DATA_TOKEN,
    useFactory: ktdGridItemGetRenderDataFactoryFunc,
    deps: [KtdGridComponent]
  }]), i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function KtdGridComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["ktd-grid{display:block;position:relative;width:100%}ktd-grid.ktd-grid-background:before{content:\"\";border:none;position:absolute;inset:0;z-index:0;transition:opacity .2s;opacity:0;background-image:repeating-linear-gradient(var(--border-color) 0 var(--border-width),var(--row-color) var(--border-width) calc(var(--row-height) - var(--border-width)),var(--border-color) calc(var(--row-height) - var(--border-width)) calc(var(--row-height)),var(--gap-color) calc(var(--row-height)) calc(var(--row-height) + var(--gap))),repeating-linear-gradient(90deg,var(--border-color) 0 var(--border-width),var(--column-color) var(--border-width) calc(100% - (var(--border-width) + var(--gap))),var(--border-color) calc(100% - (var(--border-width) + var(--gap))) calc(100% - var(--gap)),var(--gap-color) calc(100% - var(--gap)) 100%);background-size:calc((100% + var(--gap)) / var(--columns)) calc(var(--row-height) + var(--gap));background-position:0 0}ktd-grid.ktd-grid-background.ktd-grid-background-visible:before{opacity:1}ktd-grid ktd-grid-item.ktd-grid-item-dragging{z-index:1000}ktd-grid ktd-grid-item.no-transitions{transition:none!important}ktd-grid .ktd-grid-item-placeholder{position:absolute;z-index:0;transition-property:transform;transition:all .15s ease}ktd-grid .ktd-grid-item-placeholder-default{background-color:#8b0000;opacity:.6}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KtdGridComponent, [{
    type: Component,
    args: [{
      selector: 'ktd-grid',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: GRID_ITEM_GET_RENDER_DATA_TOKEN,
        useFactory: ktdGridItemGetRenderDataFactoryFunc,
        deps: [KtdGridComponent]
      }],
      template: "<ng-content></ng-content>",
      styles: ["ktd-grid{display:block;position:relative;width:100%}ktd-grid.ktd-grid-background:before{content:\"\";border:none;position:absolute;inset:0;z-index:0;transition:opacity .2s;opacity:0;background-image:repeating-linear-gradient(var(--border-color) 0 var(--border-width),var(--row-color) var(--border-width) calc(var(--row-height) - var(--border-width)),var(--border-color) calc(var(--row-height) - var(--border-width)) calc(var(--row-height)),var(--gap-color) calc(var(--row-height)) calc(var(--row-height) + var(--gap))),repeating-linear-gradient(90deg,var(--border-color) 0 var(--border-width),var(--column-color) var(--border-width) calc(100% - (var(--border-width) + var(--gap))),var(--border-color) calc(100% - (var(--border-width) + var(--gap))) calc(100% - var(--gap)),var(--gap-color) calc(100% - var(--gap)) 100%);background-size:calc((100% + var(--gap)) / var(--columns)) calc(var(--row-height) + var(--gap));background-position:0 0}ktd-grid.ktd-grid-background.ktd-grid-background-visible:before{opacity:1}ktd-grid ktd-grid-item.ktd-grid-item-dragging{z-index:1000}ktd-grid ktd-grid-item.no-transitions{transition:none!important}ktd-grid .ktd-grid-item-placeholder{position:absolute;z-index:0;transition-property:transform;transition:all .15s ease}ktd-grid .ktd-grid-item-placeholder-default{background-color:#8b0000;opacity:.6}\n"]
    }]
  }], function () {
    return [{
      type: KtdGridService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    _gridItems: [{
      type: ContentChildren,
      args: [KtdGridItemComponent, {
        descendants: true
      }]
    }],
    layoutUpdated: [{
      type: Output
    }],
    dragStarted: [{
      type: Output
    }],
    resizeStarted: [{
      type: Output
    }],
    dragEnded: [{
      type: Output
    }],
    resizeEnded: [{
      type: Output
    }],
    gridItemResize: [{
      type: Output
    }],
    scrollableParent: [{
      type: Input
    }],
    compactOnPropsChange: [{
      type: Input
    }],
    preventCollision: [{
      type: Input
    }],
    scrollSpeed: [{
      type: Input
    }],
    compactType: [{
      type: Input
    }],
    rowHeight: [{
      type: Input
    }],
    cols: [{
      type: Input
    }],
    layout: [{
      type: Input
    }],
    gap: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    backgroundConfig: [{
      type: Input
    }]
  });
})();
class KtdGridModule {}
KtdGridModule.ɵfac = function KtdGridModule_Factory(t) {
  return new (t || KtdGridModule)();
};
KtdGridModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: KtdGridModule
});
KtdGridModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [KtdGridService],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KtdGridModule, [{
    type: NgModule,
    args: [{
      declarations: [KtdGridComponent, KtdGridItemComponent, KtdGridDragHandle, KtdGridResizeHandle, KtdGridItemPlaceholder],
      exports: [KtdGridComponent, KtdGridItemComponent, KtdGridDragHandle, KtdGridResizeHandle, KtdGridItemPlaceholder],
      providers: [KtdGridService],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of grid
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GRID_ITEM_GET_RENDER_DATA_TOKEN, KTD_GRID_DRAG_HANDLE, KTD_GRID_ITEM_PLACEHOLDER, KTD_GRID_RESIZE_HANDLE, KtdGridComponent, KtdGridDragHandle, KtdGridItemComponent, KtdGridItemPlaceholder, KtdGridModule, KtdGridResizeHandle, __gridItemGetRenderDataFactoryFunc, ktdGridCompact, ktdGridItemGetRenderDataFactoryFunc, ktdTrackById, parseRenderItemToPixels };
