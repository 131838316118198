import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import {
  CastFormGroupPipe,
  CoreModule,
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
  EventBusService,
  FAwesomeModule,
  Sizes,
} from '@intorqa-ui/core';
import { IBoard, ITree } from '@portal/boards/interfaces/board.interface';
import { Board } from '@portal/boards/models/board';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { TagService } from '@portal/shared/pipes/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-board-header',
  templateUrl: './board-header.component.html',
  styleUrls: ['./board-header.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    CommonModule,
    FAwesomeModule,
    MatTooltipModule,
    CastFormGroupPipe,
    FormsModule,
  ],
})
export class BoardHeaderComponent implements OnInit {
  @Input() board: Board;
  @Input() form: FormGroup;

  readonly Sizes = Sizes;

  public boardsDescription: string;
  private subscriptions = new Subscription();

  constructor(
    private boardService: BoardService,
    private customOverlayService: CustomOverlayService,
    private snackBar: MatSnackBar,
    private eventBusService: EventBusService,
    readonly widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.addControls();
    this.bindLoadBoardSubscription();
    this.registerEventBusUpdateBoard();
  }

  ngOnDestroy(): void {
    this.unRegisterEventBusUpdateBoard();
    this.subscriptions.unsubscribe();
  }

  private bindLoadBoardSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe((params) => {
        const board = this.boardService.findBoardById(params.id);
        if (board) {
          this.form.get('boardHeader.name').setValue(board.name);
          this.form.get('boardHeader.description').setValue(board.description);
        }
      }),
    );
  }

  private addControls(): void {
    this.form.addControl(
      'boardHeader',
      new FormGroup({
        name: new FormControl(this.board.name),
        description: new FormControl(this.board.description),
      }),
    );
  }

  public onDeleteBoard(): void {
    this.customOverlayService.openCustom(
      {
        title: 'Delete board?',
        message: `Are you sure you want to permanently delete the <strong>${this.board?.name}</strong> board?`,
        size: '4x',
        icon: ['far', 'question-circle'],
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          this.boardService.deleteBoard(this.board.id).subscribe(() => {
            this.snackBar.open('Your board has been deleted!', 'Close', {
              horizontalPosition: 'right',
              duration: 5000,
              verticalPosition: 'top',
            });
          });
        }
      },
    );
  }

  public onToggleDefault(): void {
    this.board.defaultBoard = true;
    this.boardService
      .updateBoard(this.board.id, {
        default: true,
      })
      .subscribe();
  }

  public onUpdateName(): void {
    this.board.name = this.form.get('boardHeader.name').value;
    this.boardService
      .updateBoard(this.board.id, {
        name: this.board.name,
      })
      .subscribe();
  }

  public onUpdateDescription(): void {
    this.board.description = this.form.get('boardHeader.description').value;
    this.boardService
      .updateBoard(this.board.id, {
        description: this.board.description,
      })
      .subscribe();
  }

  private registerEventBusUpdateBoard(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_BOARD}.${this.boardService.board.id}`,
      this.updateBoardCallback(),
    );
  }

  private unRegisterEventBusUpdateBoard(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_BOARD}.${this.boardService.board.id}`,
      this.updateBoardCallback(),
    );
  }

  private updateBoardCallback(): (
    err: Error,
    msg: {
      body: { board: IBoard; tree: ITree };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { board: IBoard; tree: ITree };
        address: string;
        type: string;
      },
    ) => {const board = new Board(
          msg.body.board.id,
          msg.body.board.name,
          msg.body.board.description,
          msg.body.board.defaultBoard,
          msg.body.board.filter,
          msg.body.board.widgetIds,
        );
      if (this.board.id === msg.body.board.id) {
        this.board = board;
        this.form.get('boardHeader.name').setValue(board.name);
        this.form.get('boardHeader.description').setValue(board.description);
      } else {
        if(msg.body.board.defaultBoard ) {
          this.board ={
            ...this.board,
            ...{
              defaultBoard: false
            }
          }
        }
      }


    };
  }
}
