<itq-form [formGroup]="form">
  <ng-container *ngIf="navigationItem?.action === WidgetActions.EXPLORE">
    <itq-chart-explore
      [navigationItem]="navigationItem"
      [segment]="segment"
      [articleDetail]="articleDetail"
      [form]="form"
    ></itq-chart-explore>
  </ng-container>
</itq-form>
