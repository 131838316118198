<ng-container [formGroup]="form">
  <itq-input
    [formControlName]="'query'"
    [icon]="'search'"
    [placeholder]="'Search notifications'"
    [type]="'text'"
    (search)="onSearch()"
    (clear)="onSearch()"
  ></itq-input>
  <itq-notifications-timeline
    [initialState]="initialState"
    [dataSource]="notificationsDataSource"
    (drilldown)="onDrilldown($event)"
    (dataBound)="onDataBound($event)"
  ></itq-notifications-timeline>
</ng-container>
