import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Category,
  CoreModule,
  DateQueryType,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { TopOptions } from '@portal/boards/const/widget.const';
import { TagAnalysis } from '@portal/boards/models/tag-analysis';
import { Widget } from '@portal/boards/models/widget';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ICustomTag } from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { WidgetSettingsService } from '@portal/boards/components/widget-settings/widget-settings.service';
import moment from 'moment';
import { Timeline } from '@portal/shared/models/timeline';

@Component({
  selector: 'itq-tag-analysis-metrics',
  templateUrl: './tag-analysis-metrics.component.html',
  styleUrls: ['./tag-analysis-metrics.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CoreModule],
})
export class TagAnalysisMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.tagAnalysisWidget = widget as TagAnalysis;
  }
  @Input() form: FormGroup;

  @Output() update = new EventEmitter<Widget>();

  public dataPointsDataSource: Array<Category> = [];
  public tagsDataSource: Array<ICustomTag>;
  public topDataSource: Array<{ name: string; value: number }> = [];
  public initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
  );
  public tagAnalysisWidget: TagAnalysis;

  constructor(
    private categoryService: CategoryService,
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly widgetSettingsService: WidgetSettingsService,
  ) {
    this.topDataSource = TopOptions;
  }

  ngOnInit(): void {
    this.addControls();
    this.onGetTags(this.initialState);
    if (this.tagAnalysisWidget.widgetId) {
      this.getCategories();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
    this.form.removeControl('dataPoints');
    this.form.removeControl('top');
  }

  public onGetTags(params: QueryFilters): void {
    this.initialState = params;
    this.categoryService
      .getTags(
        params.query,
        params,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        params.page > 1
          ? this.tagsDataSource[this.tagsDataSource.length - 1].name
          : undefined,
      )
      .then((response: Array<ICustomTag>) => {
        this.tagsDataSource =
          params.page > 1 ? [...this.tagsDataSource, ...response] : response;
        if (this.tagAnalysisWidget.dataSource?.length > 0) {
          this.tagService
            .getTagById(this.tagAnalysisWidget.dataSource[0])
            .then((tag: Timeline) => {
              this.form.patchValue({
                dataSource: {
                  name: tag.name,
                  id: tag.tagId,
                },
              });
            });
        }
      });
  }

  private getCategories(): void {
    this.categoryService
      .getCategories(this.userService.userPreferences.defaultEcosystemId)
      .then((response: Array<Category>) => {
        this.dataPointsDataSource = response;
        if (this.tagAnalysisWidget.dataPoints?.length > 0) {
          this.form.patchValue({
            dataPoints: this.tagAnalysisWidget.dataPoints[0],
          });
          this.form.get('dataPoints').enable();
        }
      });
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(undefined, [Validators.required]),
    );
    this.form.addControl(
      'dataPoints',
      new FormControl(
        {
          value: undefined,
          disabled: this.tagAnalysisWidget?.dataPoints?.length === 0,
        },
        [Validators.required],
      ),
    );
    this.form.addControl(
      'top',
      new FormControl(this.tagAnalysisWidget.top, [Validators.required]),
    );
  }

  public onChangeTop(): void {
    this.tagAnalysisWidget.top = this.form.controls.top.value;
    this.update.emit(this.tagAnalysisWidget);
  }

  public onChangeDataSources(): void {
    this.getCategories();
    this.form.get('dataPoints').enable();
    this.tagAnalysisWidget.dataSource = [
      this.form.controls.dataSource.value.id,
    ];
    this.update.emit(this.tagAnalysisWidget);
  }

  public onChangeDatePoints(): void {
    this.tagAnalysisWidget.dataPoints = [this.form.controls.dataPoints.value];
    this.update.emit(this.tagAnalysisWidget);
  }
}
