<div class="flex mb-2 flex-col" [formGroup]="form">
  <span class="mb-1 text-13 font-medium">TAG</span>

  <itq-multiple-dropdown
    [dataSource]="dataPointsDataSource"
    formControlName="dataPoints"
    [dataFields]="{ name: 'name', value: 'id' }"
    [initialState]="this.initialState"
    [emptyDataSourceMessage]="'No tags found'"
    [httpBinding]="true"
    (changeValue)="onDataBound()"
    (dataBound)="onGetTags($event)"
  >
  </itq-multiple-dropdown>
  <span class="text-13 text-metadata pt-1"> Select your tags to plot</span>
</div>
