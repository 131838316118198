<div class="dependencies__container" [formGroup]="form">
  <itq-headline-simple
    [iconName]="'list-tree'"
    [iconSize]="'2x'"
    [iconType]="IconType.FONT_AWESOME"
    [title]="'Tag dependencies'"
    [description]="'Visualization of tag dependencies'"
  ></itq-headline-simple>
  <div class="dependencies__body">
    <itq-form-row class="flex-col">
      <span class="mb-1 label">Direction:</span>
      <itq-button-thumbnail
        formControlName="direction"
        [dataSource]="dependencies"
        (change)="onDataBound()"
      ></itq-button-thumbnail>
    </itq-form-row>
    <itq-form-row class="tree__container flex-col mt-4">
      <span class="mb-1 label"
        >List of {{ form?.get('direction')?.value }}:</span
      >
      <mat-tree
        *ngIf="dataset?.size > 1; else noResultsTemplate"
        [dataSource]="dataSource"
        [treeControl]="treeControl"
      >
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-icon-button disabled></button>

          <ng-container
            [ngTemplateOutlet]="labelTemplate"
            [ngTemplateOutletContext]="{ $implicit: node }"
          ></ng-container>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
        >
          <button
            mat-icon-button
            [attr.aria-label]="'Toggle ' + node.name"
            matTreeNodeToggle
          >
            <mat-icon class="mat-icon-rtl-mirror">
              <span>
                {{
                  treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
                }}</span
              >
            </mat-icon>
          </button>

          <ng-container
            [ngTemplateOutlet]="nodeContainerTemplate"
            [ngTemplateOutletContext]="{ $implicit: node }"
          ></ng-container>
          <mat-progress-bar
            *ngIf="node.isLoading"
            mode="indeterminate"
            class="example-tree-progress-bar"
          ></mat-progress-bar>
        </mat-tree-node>
      </mat-tree>
    </itq-form-row>
  </div>
</div>

<ng-template #labelTemplate let-node>
  <ng-container
    [ngTemplateOutlet]="nodeContainerTemplate"
    [ngTemplateOutletContext]="{ $implicit: node }"
  ></ng-container>
</ng-template>

<ng-template #nodeContainerTemplate let-node>
  <div class="flex flex-col">
    <div class="flex items-center">
      <itq-mat-button
        *ngIf="node.level > 0; else labelChildTemplate"
        [type]="'button'"
        [materialStyle]="'basic'"
        [matTooltip]="node | tooltipNode"
        (mouseDownEvent)="onLoadNode(node)"
      >
        <div class="node__icon">
          <ng-container
            *ngIf="
              node.type === TagNodeType.TAG ||
                node.type === TagNodeType.SYSTEM_TAG;
              else svgIconTemplate
            "
          >
            <fa-icon [icon]="['far', 'tags']"></fa-icon>
          </ng-container>
          <ng-template #svgIconTemplate>
            <itq-svg
              class="w-6"
              [icon]="node.type === TagNodeType.BOARD ? 'board' : 'dashboard'"
            ></itq-svg>
          </ng-template>
        </div>
        <span
          [ngClass]="{
            'text-wrapper': true,
            'ml-x-sm': true
          }"
          >{{ node.name }}</span
        ></itq-mat-button
      >
      <ng-template #labelChildTemplate>
        <div class="flex items-center" [matTooltip]="node | tooltipNode">
          <div class="node__icon">
            <ng-container
              *ngIf="
                node.type === TagNodeType.TAG ||
                  node.type === TagNodeType.SYSTEM_TAG;
                else svgIconTemplate
              "
            >
              <fa-icon [icon]="['far', 'tags']"></fa-icon>
            </ng-container>
            <ng-template #svgIconTemplate>
              <itq-svg
                class="w-6"
                [icon]="node.type === TagNodeType.BOARD ? 'board' : 'dashboard'"
              ></itq-svg>
            </ng-template>
          </div>
          <span
            [ngClass]="{
              node__label: true,
              'text-wrapper': true,
              'ml-x-sm': true
            }"
            >{{ node.name }}</span
          >
        </div>
      </ng-template>
      <itq-mat-button
        *ngIf="
          node.level > 0 && this.form?.get('direction')?.value === 'dependants'
        "
        class="ml-x-sm label-secondary"
        [type]="'button'"
        [materialStyle]="'basic'"
        (mouseDownEvent)="onUnlink(node)"
      >
        {{ node.linked ? 'Unlink' : 'Link' }}
      </itq-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #noResultsTemplate>
  <itq-panel-action
    *ngIf="dataset"
    [label]="'No ' + this?.form.get('direction')?.value + ' found!'"
  ></itq-panel-action>
</ng-template>
