import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Category,
  IPresetQuery,
  QueryFilters,
  Sections,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';

@Component({
  selector: 'itq-timeline-type-list',
  templateUrl: './timeline-type-list.component.html',
  styleUrls: ['./timeline-type-list.component.scss'],
})
export class TimelineTypeListComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() type: TagCategory;
  @Input() query: Query;
  @Input() dates: IPresetQuery;
  @Input() timeline: Timeline;
  @Input() required = true;

  @Output() dataBound = new EventEmitter<Query>();
  @Output() clearFilters = new EventEmitter<Query>();

  public dataFields = { name: 'name', value: 'value' };
  public dataSource: Array<ICustomTag>;
  public showLoader = false;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
  );

  readonly Sections = Sections;
  readonly Sizes = Sizes;
  readonly TagCategory = TagCategory;

  constructor(
    private categoryService: CategoryService,
    private virtualScrollingService: VirtualScrollService,
    private tagService: TagService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    if (
      this.navigationItem.action === WidgetActions.SETTINGS ||
      this.navigationItem.action === WidgetActions.CLONE
    ) {
      this.loadSelections();
    }
  }

  private loadSelections(): void {
    let tagIds = this.query.getTagIds();
    let fieldFilterIds = this.query.getFieldFilterTagIds();
    let contentIds = this.query.getFieldFilterTagIds();
    this.tagService
      .getSelections(tagIds, fieldFilterIds, contentIds, [
        new Category(
          undefined,
          this.type,
          undefined,
          Sections['Communities and Sources'],
          undefined,
          this.userService.userPreferences.defaultEcosystemId,
        ),
      ])
      .then((response: Array<ITagMetadata>) => {
        this.query.selections = response;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dates?.previousValue !== changes?.dates?.currentValue) {
      this.initialState.where = this.dates;
    }
    if (
      changes?.type?.previousValue !== changes?.type?.currentValue ||
      changes?.dates?.previousValue !== changes?.dates?.currentValue ||
      changes?.query?.previousValue !== changes?.query?.currentValue
    ) {
      this.initialState.resetPagination().then(() => {
        this.virtualScrollingService.dataBoundObservable.next();
      });
      this.onGetTags(this.initialState);
    }
  }

  public onGetTags(params: QueryFilters): void {
    this.showLoader = true;
    const queryModel = this.query.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    this.categoryService
      .getTags(
        params.query,
        params,
        queryModel.modelToDTO(),
        this.type,
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState.page > 1
          ? this.dataSource[this.dataSource.length - 1].name
          : undefined,
      )
      .then((response: Array<ICustomTag>) => {
        this.dataSource =
          params.page > 1 ? [...this.dataSource, ...response] : response;
        this.showLoader = false;
      });
  }

  public onAddTag(item: ICustomTag): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollingService.dataBoundObservable.next();
    });
    const query = this.query.cloneDeep();
    query.addSelection({
      section: Sections['Communities and Sources'],
      tagId: item.id,
      tagName: item.name,
      included: true,
      excluded: false,
      categoryName: this.type,
    });
    this.dataBound.emit(query);
  }

  public onRemoveTag(item: ITagMetadata): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollingService.dataBoundObservable.next();
    });
    const query = this.query.cloneDeep();
    query.removeSelection(item);
    this.dataBound.emit(query);
  }

  public onClearFilters(): void {
    this.clearFilters.emit(new Query([], this.query.type));
  }
}
