<ng-container [formGroup]="form">
  <itq-sidebar class="widget_settings__left p-4" [isExpanded]="expandedFilters">
    <itq-headline-simple
      class="mb-7"
      [iconName]="navigationItem.icon"
      [iconType]="navigationItem.iconType"
      [iconSize]="'2x'"
      [title]="widget?.name"
      [description]="widget?.description"
    ></itq-headline-simple>
    <div class="filters__panel">
      <itq-form-row class="search__type_container">
        <itq-form-element>
          <span class="mb-1 label font-semibold">Search type:</span>
          <itq-button-thumbnail
            formControlName="searchType"
            [dataSource]="searchTypes"
            (change)="onChangeQueryType()"
          ></itq-button-thumbnail>
        </itq-form-element>
      </itq-form-row>
      <itq-timeline-query
        [formControlName]="'query'"
        [required]="false"
        [navigationItem]="navigationItem"
        [queryType]="queryModel?.type"
        [query]="queryModel"
        [dataSource]="categoriesDataSource"
        [widget]="widget"
        [initialState]="initialState"
        (dataBound)="onDataBound($event, initialState)"
        (clearFilters)="onDataBound($event, initialState)"
      ></itq-timeline-query>
    </div>
  </itq-sidebar>
  <div class="widget_settings__right">
    <div class="widget_settings__toolbar">
      <itq-pill
        *ngIf="
          (this.queryModel.hasRules() || navigationItem?.rules?.length > 0) &&
          (this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE
            ? timelineData?.count > 0
            : widgetData?.totalHits > 0)
        "
        [type]="PillType.CUSTOM"
        [class]="'secondary rounded-md'"
        >{{
          this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE
            ? (timelineData?.count | number)
            : (widgetData?.totalHits | number)
        }}</itq-pill
      >
      <div class="toolbar__container">
        <itq-toolbar
          [actions]="toolbarActions"
          [viewMode]="viewMode"
          [showTooltip]="true"
          [initialState]="initialState"
          [widget]="widget"
          [displayTypes]="displayTypes"
          [selectedDisplayType]="navigationItem.selectedDisplayType"
          [count]="timelineData?.count || widgetData?.totalHits"
          (search)="onDataBound(this.queryModel, $event)"
          (toggleView)="onToggleView($event)"
          (export)="onExportFoamtree()"
          (refresh)="onDataBound(this.queryModel, initialState)"
          (showApi)="onShowApi()"
          (changeDisplayType)="onChangeDisplayType($event)"
          (add)="onAdd()"
        ></itq-toolbar>
      </div>
    </div>
    <div
      [ngClass]="{
        widget__container: true,
        ripl__mode: viewMode === ViewMode.RIPL
      }"
    >
      <ng-container
        *ngIf="
          this.queryModel.hasRules() || navigationItem?.rules?.length > 0;
          else noFiltersTemplate
        "
      >
        <itq-search-results
          [query]="queryModel"
          [initialState]="initialState"
          [foamtreeData]="foamtreeData"
          [navigationItem]="navigationItem"
          [widgetData]="widgetData"
          [timelineData]="timelineData"
          [tagAnalysisWidget]="tagAnalysisWidget"
          [viewMode]="viewMode"
          [segment]="articleDetail"
          [form]="form"
          [widget]="widget"
          [allowScrollingUp]="this.navigationItem.activeDocument ? true : false"
          (drilldownFoamtree)="onDrilldownFoamtree($event)"
        ></itq-search-results>
      </ng-container>

      <ng-template #noFiltersTemplate>
        <itq-panel-action
          [label]="'No filters selected!'"
          [description]="
            'Type in the search box and add some filters for targeted results.'
          "
        ></itq-panel-action>
      </ng-template>
      <itq-document-item-detail
        *ngIf="articleDetail"
        [viewParent]="true"
        [segment]="articleDetail"
        [form]="form"
        [navigationItem]="navigationItem"
        [action]="navigationItem?.action"
        (drilldown)="onDrilldown($event)"
      ></itq-document-item-detail>
    </div>
  </div>
</ng-container>
<itq-error *ngIf="error?.stopPropagation" [error]="error"></itq-error>
