import { FormGroup } from '@angular/forms';
import { IconType, QueryFilters } from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widget';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { QueryRule } from '@portal/shared/models/query-rule';
import { INotificationNavigationItem } from '../interfaces/notification-navigation-item.interface';
import { ISegment } from '../interfaces/widget-settings.interface';
import { NavigationHistoryItem } from './navigation-history-item.model';

export class NotificationNavigationItem
  extends NavigationHistoryItem
  implements INotificationNavigationItem
{
  constructor(
    public id: string,
    public item: Widget,
    public action: WidgetActions,
    public rules: Array<QueryRule>,
    public initialState: QueryFilters,
    public form: FormGroup,
    public icon: string,
    public iconType: IconType,
    public selectedDisplayType: IDisplayType,
    public segment: ISegment,
    public boardId: string,
    public raisedAlertId: string,
    public researchAlertId: string,
  ) {
    super(
      id,
      item,
      action,
      rules,
      initialState,
      form,
      icon,
      iconType,
      selectedDisplayType,
      segment,
      boardId,
    );
  }
}
