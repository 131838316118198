@if (category !== TagCategory['My Tags']) {
  <ul class="list__container">
    <li
      *ngFor="let item of dataSource"
      [ngClass]="{ active: item.name === category }"
      (click)="onExpandCategory(item.name)"
    >
      <div class="flex items-center">
        {{ item.name }}
        <fa-icon
          class="ml-1"
          [icon]="['far', 'circle-info']"
          [matTooltip]="item.description"
        ></fa-icon>
      </div>
      <fa-icon
        [icon]="[
          'far',
          item.name === category ? 'chevron-right' : 'chevron-down'
        ]"
      ></fa-icon>
    </li>
  </ul>
}

<div
  class="tags__container"
  *ngIf="
    tagsDataSource
      | filterSelections: query : timeline?.tagId as dataSourceAlias
  "
>
  <itq-list-search
    [initialState]="initialState"
    [query]="query"
    [section]="section"
    [dataSource]="tagsDataSource"
    [category]="category"
    (changeValue)="onRemoveTag($event)"
    (search)="onGetTags($event)"
  >
    <itq-tags-boolean-selection
      [section]="section"
      [dataSource]="tagsDataSource"
      [category]="category"
      (changeValue)="onChangeValue($event)"
    ></itq-tags-boolean-selection
  ></itq-list-search>
  @if (showLoader) {
    <itq-loader></itq-loader>
  }
</div>
