import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconType, Sizes } from "@intorqa-ui/core";
import { WidgetActions, AnalysisTypes } from '@portal/shared/enums/widget.enum';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';

@Component({
  selector: "itq-navigation-history",
  templateUrl: "./navigation-history.component.html",
  styleUrls: ["./navigation-history.component.scss"],
})
export class NavigationHistoryComponent implements OnInit {
  @Input() selected: NavigationHistoryItem;
  @Input() dataSource: Array<NavigationHistoryItem> = [];
  @Input() dataFields: {
    name: string;
    value: string;
  };

  @Output() deleteItem = new EventEmitter<NavigationHistoryItem>();
  @Output() loadHistory = new EventEmitter<NavigationHistoryItem>();

  readonly AnalysisTypes = AnalysisTypes;
  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly WidgetActions = WidgetActions;

  constructor() {}

  ngOnInit(): void {}

  public onLoadHistory(link: NavigationHistoryItem): void {
    this.loadHistory.emit(link);
  }

  public onDelete(item: NavigationHistoryItem): void {
    this.deleteItem.emit(item);
  }
}
