<div class="flex" *ngIf="notification.tagEdited">
  <fa-icon
    class="absolute right-4 bottom-4 z-50"
    [size]="'lg'"
    [matTooltip]="'This tag has changed since the alert was flat'"
    [icon]="['far', 'bell-exclamation']"
    [ngStyle]="{ color: '#ffb74d' }"
  ></fa-icon>
</div>
<div class="flex flex-col w-full">
  <div class="w-full flex flex-col">
    <div class="header__wrapper">
      <div class="header__left">
        <fa-icon
          [matTooltip]="'Tag threshold alert'"
          [size]="'lg'"
          [icon]="[
            'far',
            AlertTypesIcons[notification?.alertTypeName] | castIconName
          ]"
        ></fa-icon>
        <div>
          {{ notification?.tagName }} has matched
          {{ Condition_Notification[notification?.condition] }}
          {{ notification?.count }} in
          {{ notification?.period | getPeriodLabel }}
        </div>
      </div>
      <div class="header__right">
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          [matTooltip]="'Explore tag'"
          (clickEvent)="onExploreTag()"
        >
          <fa-icon [icon]="['far', 'expand']"></fa-icon>
        </itq-mat-button>
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
          (clickEvent)="onToggleRead()"
        >
          <fa-icon
            [icon]="['far', notification.read ? 'eye-slash' : 'eye']"
          ></fa-icon>
        </itq-mat-button>
        <span
          class="priority"
          [ngStyle]="{ color: PriorityColor[notification?.priority] }"
          >{{ PriorityLabel[notification.priority] }}</span
        >
      </div>
    </div>
  </div>
  <div class="notification__body">
    <span class="body_message" *ngIf="notification.message">
      {{ notification.message }}</span
    >
  </div>
  <div
    class="notification__footer flex align-center pl-4 pr-4 w-full justify-start"
  >
    {{ notification.createdDate | millisToDate | timeAgo }}
  </div>
</div>
