import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { WidgetActions } from "@portal/shared/enums/widget.enum";
import { ISegment } from "@portal/widget-settings/interfaces/widget-settings.interface";
import { NavigationHistoryItem } from "@portal/widget-settings/models/navigation-history-item.model";
import { TimelineNavigationItem } from "@portal/widget-settings/models/timeline-navigation-item.model";

@Component({
  selector: "itq-widget-settings-timeline",
  templateUrl: "./widget-settings-timeline.component.html",
  styleUrls: ["./widget-settings-timeline.component.scss"],
})
export class WidgetSettingsTimelineComponent implements OnInit {
  @Input()
  set navigationItem(value: NavigationHistoryItem) {
    this._navigationItem = value as TimelineNavigationItem;
  }

  get navigationItem(): TimelineNavigationItem {
    return this._navigationItem;
  }
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  public WidgetActions = WidgetActions;
  public _navigationItem: TimelineNavigationItem;

  constructor() {}

  ngOnInit(): void {}
}
