<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addTags'">
    <itq-headline-simple
      *ngIf="showTitle"
      class="mb-7"
      [iconName]="'plus'"
      [iconSize]="'2x'"
      [iconType]="IconType.FONT_AWESOME"
      [title]="'Link tag'"
    ></itq-headline-simple>
    <div class="link_tag__body">
      <itq-form-row class="mb-4">
        <itq-form-element>
          <span class="mb-1 label required">Link type:</span>
          <itq-dropdown
            formControlName="linkType"
            [dataFields]="{ name: 'name', value: 'value' }"
            [emptyDataSourceMessage]="'No link type found!'"
            [dataSource]="linkTypeDataSource"
            (changeValue)="onChangeLinkType()"
          >
          </itq-dropdown>
        </itq-form-element>
      </itq-form-row>
      <itq-form-row class="mb-4">
        <itq-form-element>
          <span class="mb-1 label required">Tags:</span>
          <itq-dropdown
            formControlName="tag"
            [dataFields]="{ name: 'name', value: 'name' }"
            [dataSource]="
              tagsDataSource | filterTags: form?.get('addTags.linkType').value
            "
            [httpBinding]="true"
            [emptyDataSourceMessage]="'No tags found!'"
            (dataBound)="onGetTags($event)"
            (changeValue)="onChangeValue($event)"
          >
          </itq-dropdown>
        </itq-form-element>
      </itq-form-row>
      <itq-form-row class="mb-4">
        <itq-form-element>
          <span class="mb-1 label">Description:</span>
          <itq-input
            [formControlName]="'description'"
            [placeholder]="'Please add a description...'"
            (search)="onChangeDescription()"
          ></itq-input>
        </itq-form-element>
      </itq-form-row>
      <itq-form-row class="mb-4">
        <itq-form-element class="font-normal">
          <mat-slide-toggle
            [color]="'primary'"
            [formControlName]="'default'"
            (change)="onToggleChange()"
            >Default tag</mat-slide-toggle
          >
        </itq-form-element>
      </itq-form-row>
    </div>

    <div class="link_tag__footer">
      <div class="link_tag__footer_left">
        <itq-mat-button [materialStyle]="'basic'" (clickEvent)="onCancel()">
          Cancel
        </itq-mat-button>
      </div>
      <div class="link_tag__footer_right">
        <ng-container
          *ngIf="action === WidgetActions.CREATE; else updateTemplate"
        >
          <itq-nav-button
            [materialStyle]="'flat'"
            [openDirection]="{
              vertical: 'up',
              horizontal: 'left'
            }"
            [color]="'primary'"
            [padding]="Sizes.MD"
            [disabled]="!this.form?.get('addTags').valid"
            [openPosition]="'inside'"
            [template]="saveTemplate"
            >SAVE</itq-nav-button
          >
          <ng-template #saveTemplate>
            <itq-mat-button
              [materialStyle]="'basic'"
              [padding]="Sizes['X-SM']"
              [size]="Sizes.BLOCK"
              [align]="Align.START"
              (clickEvent)="onCreate()"
            >
              <fa-icon [icon]="['far', 'plus']"></fa-icon>
              Create new
            </itq-mat-button>
            <itq-mat-button
              [padding]="Sizes['X-SM']"
              [materialStyle]="'basic'"
              [size]="Sizes.BLOCK"
              [align]="Align.START"
              (clickEvent)="onAdd()"
            >
              <fa-icon [icon]="['far', 'plus']"></fa-icon>
              <span class="text-wrapper">Add to profile</span>
            </itq-mat-button>
          </ng-template>
        </ng-container>
        <ng-template #updateTemplate>
          <itq-mat-button
            [disabled]="!this.form?.get('addTags')?.valid"
            [materialStyle]="'flat'"
            [style]="'primary'"
            (clickEvent)="onUpdate()"
          >
            <span class="text-wrapper">Update</span>
          </itq-mat-button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
