<itq-default-navigation
  [showNotifications]="showNotifications"
  (loadNotifications)="onLoadNotifications($event)"
  (closeNotifications)="showNotifications = false"
></itq-default-navigation>
<main role="main">
  <router-outlet></router-outlet>

  @if (showLoader) {
    <itq-loader></itq-loader>
  }
</main>
<div
  [ngClass]="{ block: open, invisible: !open, portal__container: true }"
  [ngStyle]="{
    zIndex: 10000,
    position: 'absolute',
    top: dropdownPosition.top + 'px',
    left: dropdownPosition.left + 'px'
  }"
>
  <ng-template [cdkPortalOutlet]="portal | async"></ng-template>
</div>

@if (showTooltip) {
  <itq-tooltip
    [tooltipContent]="tooltipContent"
    [top]="tooltipPosition?.top"
    [left]="tooltipPosition?.left"
    [show]="showTooltip"
  ></itq-tooltip>
}

@if (showNotifications) {
  <itq-notifications
    (close)="onCloseNotifications()"
    [raisedAlertId]="raisedAlertId"
    [researchAlertId]="researchAlertId"
  ></itq-notifications>
  <div class="notifications__backdrop" #backdrop></div>
}
