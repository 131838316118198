<div class="notification__container" *ngFor="let item of dataSource?.items">
  <span class="notification__group">
    {{ item.date }}
  </span>
  <ng-container *ngFor="let notification of item.notifications">
    <div
      class="notification__wrapper"
      [ngClass]="{
        notification__wrapper: true,
        active: notification.raisedAlertId === raisedAlertId
      }"
    >
      <ng-container #dynamicComponentContainer></ng-container>
    </div>
  </ng-container>
</div>
<itq-panel-info
  *ngIf="dataSource?.totalCount === 0"
  class="m-4"
  [label]="'Your inbox is empty!'"
  [message]="'When new notifications are available there will be shown in here'"
  [icon]="'exclamation-circle'"
></itq-panel-info>
