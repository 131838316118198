<div class="notification__header">
  <div class="header__wrapper">
    <div class="flex items-center flex-1">
      <div class="flex m-3">
        <fa-icon
          [size]="'lg'"
          [matTooltip]="'System alert'"
          [icon]="['far', 'gear']"
        ></fa-icon>
      </div>
      <div>{{ notification?.headline }}</div>
    </div>
    <div class="flex justify-center items-center mr-4">
      <itq-mat-button
        *ngIf="
          notification?.triggerMetadata?.action !==
            TransactionNotificationType.TAG_DELETED &&
          notification?.triggerMetadata?.action !==
            TransactionNotificationType.PROFILE_DELETED
        "
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        [matTooltip]="
          TransactionNotificationTypeText[notification?.triggerMetadata?.action]
        "
        (clickEvent)="onLoadAction()"
      >
        <fa-icon
          [icon]="[
            'far',
            notification?.triggerMetadata?.action | getTransactionalAction
          ]"
        ></fa-icon>
      </itq-mat-button>
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
        (clickEvent)="onToggleRead()"
      >
        <fa-icon
          [icon]="['far', notification.read ? 'eye-slash' : 'eye']"
        ></fa-icon>
      </itq-mat-button>
    </div>
  </div>
</div>
<div class="notification__body">
  <div class="py-3 px-4 flex" *ngIf="notification.message">
    {{ notification.message }}
  </div>
  <div
    class="py-3 px-4 flex"
    [innerHTML]="notification?.triggerMetadata?.addition"
    *ngIf="notification?.triggerMetadata?.addition"
  ></div>
</div>

<div
  class="notification__footer flex items-center pl-4 pr-4 w-full justify-start"
>
  {{ notification.createdDate | millisToDate | timeAgo }}
</div>
