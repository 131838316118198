import { Type } from '@angular/core';
import { TagComparisonMetricsComponent } from './modules/widget-settings-chart/chart-wizard/components/tag-comparison-metrics/tag-comparison-metrics.component';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import { TagTrendsMetricsComponent } from './modules/widget-settings-chart/chart-wizard/components/tag-trends-metrics/tag-trends-metrics.component';
import { TagAnalysisMetricsComponent } from './modules/widget-settings-chart/chart-wizard/components/tag-analysis-metrics/tag-analysis-metrics.component';
import { TimelineMetricsComponent } from './modules/widget-settings-chart/chart-wizard/components/timeline-metrics/timeline-metrics.component';

export class WidgetSettingsFactory {
  static getComponent(
    type: AnalysisTypes,
  ): Type<
    | TagTrendsMetricsComponent
    | TagAnalysisMetricsComponent
    | TagComparisonMetricsComponent
    | TimelineMetricsComponent
  > {
    if (type === AnalysisTypes.TAG_ANALYSIS) {
      return TagAnalysisMetricsComponent;
    } else if (type === AnalysisTypes.TAG_COMPARISON) {
      return TagComparisonMetricsComponent;
    } else if (type === AnalysisTypes.TIME_SERIES) {
      return TagTrendsMetricsComponent;
    } else if (type === AnalysisTypes.TIMELINE) {
      return TimelineMetricsComponent;
    }
  }
}
