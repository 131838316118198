<itq-headline-simple
  [iconName]="'bell'"
  [iconSize]="'2x'"
  [iconType]="IconType.FONT_AWESOME"
  [title]="'Notifications'"
></itq-headline-simple>
<itq-form [formGroup]="form">
  <div class="flex justify-between">
    <itq-button-thumbnail
      [theme]="'tabs'"
      [formControlName]="'showAll'"
      [dataSource]="buttonDataSource"
      (change)="onDataBound()"
    ></itq-button-thumbnail>
    <itq-mat-button
      *ngIf="unreadCount() > 0"
      class="underline hover:no-underline mr-4 text-link"
      [type]="'button'"
      [materialStyle]="'basic'"
      [padding]="Sizes.SM"
      (clickEvent)="onMarkAllRead()"
    >
      <span class="text-wrapper">Mark all as read</span>
    </itq-mat-button>
  </div>
  <itq-notifications-hub
    [form]="form"
    [initialState]="initialState"
  ></itq-notifications-hub>
  <itq-mat-button
    class="btn__explore"
    [disabled]="!notificationsCount || notificationsCount === 0"
    [size]="Sizes.BLOCK"
    [type]="'button'"
    [style]="'primary'"
    [materialStyle]="'flat'"
    [padding]="Sizes.SM"
    (clickEvent)="onExplore()"
  >
    <span class="text-wrapper">Explore</span>
  </itq-mat-button>
</itq-form>
<ng-template #unreadTemplate>
  <div class="unread__container">
    <span class="unread__title">Unread</span>
    <span class="unread__count" *ngIf="unreadCount()">{{ unreadCount() }}</span>
  </div>
</ng-template>
<itq-loader *ngIf="showLoader"></itq-loader>
