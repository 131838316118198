<ng-container [formGroup]="form">
  <ng-container *ngIf="this.keycloakService.isUserInRole('saas-alerts')">
    <div class="flex items-center">
      <fa-icon [icon]="['far', 'tags']" [size]="'lg'"></fa-icon>
      <span class="subtitle pl-2">Tags</span>
    </div>
  </ng-container>
  <div class="stepper__container">
    <itq-form-row>
      <itq-form-element [inline]="true">
        <span class="mb-1 label"> Shared Tag:</span>
        <mat-slide-toggle
          [matTooltip]="
            this.widget.sharedTag && this.widget.alertTypeId
              ? 'You can\'t unshare this tag as it has an alert setup.'
              : ''
          "
          [color]="'primary'"
          [formControlName]="'sharedTag'"
          (change)="onChangeSharedTag($event)"
        ></mat-slide-toggle>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row>
      <itq-form-element>
        <span class="mb-1 label"> Add tag to boards:</span>
        <itq-multiple-dropdown
          [dataSource]="boardsDataSource"
          [text]="
            this.form.controls.boardIds?.value?.length + ' board selected'
          "
          [materialStyle]="'stroked'"
          [padding]="Sizes.SM"
          [formControlName]="'boardIds'"
          [emptyDataSourceMessage]="
            'No board found. Please refine your search!'
          "
          [dataFields]="{ name: 'name', value: 'id' }"
        >
        </itq-multiple-dropdown>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row *ngIf="this.keycloakService.isUserInRole('super-admin')">
      <itq-form-element>
        <span class="mb-1 label"> Add tag to a category:</span>
        <itq-dropdown
          [formControlName]="'categoryId'"
          [dataSource]="categoryService.categories | filterCategories"
          [dataFields]="{ name: 'name', value: 'id' }"
          (changeValue)="onChangeCategory($event)"
        ></itq-dropdown>
      </itq-form-element>
    </itq-form-row>
  </div>
  <ng-container *ngIf="this.keycloakService.isUserInRole('saas-alerts')">
    <div class="flex items-center">
      <fa-icon [icon]="['far', 'bell']" [size]="'lg'"></fa-icon>
      <span class="subtitle pl-2">Alerts</span>
    </div>
    <div class="stepper__container">
      <itq-form-row>
        <itq-form-element>
          <span class="mb-1 label">Select alert type:</span>
          <itq-dropdown
            [formControlName]="'alertTypeId'"
            [dataSource]="alertTypesDataSource"
            [dataFields]="{ name: 'label', value: 'id' }"
            (changeValue)="onChangeAlertType()"
          ></itq-dropdown>
        </itq-form-element>
      </itq-form-row>
    </div>
  </ng-container>
</ng-container>
<itq-loader *ngIf="!boardsDataSource"></itq-loader>
