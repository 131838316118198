import { Pipe, PipeTransform } from '@angular/core';
import { DateQueryType, DateRange, QueryFilters } from '@intorqa-ui/core';
import {
  AnalysisTypes,
  AnalysisTypesLabel,
} from '@portal/shared/enums/widget.enum';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import moment from 'moment';

@Pipe({
  name: 'filterTags',
  standalone: true,
})
export class FilterTagsPipe implements PipeTransform {
  constructor() {}

  transform(
    data: Array<ICustomTag>,
    selections: Array<ITagMetadata>,
  ): Array<ICustomTag> {
    if (!selections) return data;
    const ids = selections.map((item: ITagMetadata) => item.tagId);
    return data?.filter((item: ICustomTag) => !ids.includes(item.id));
  }
}

@Pipe({
  name: 'mapLabel',
  standalone: true,
})
export class MapLabelPipe implements PipeTransform {
  constructor() {}

  transform(type: AnalysisTypes): Array<ICustomTag> {
    return AnalysisTypesLabel[type];
  }
}

@Pipe({
  name: 'getDateRange',
  standalone: true,
})
export class GetDateRangePipe implements PipeTransform {
  constructor() {}

  transform(initialState: QueryFilters): string {
    if (initialState?.where.label === DateQueryType.Custom) {
      return `${moment(
        DateRange.convertFromEpochSeconds(initialState?.where?.start),
      ).format('DD-MM-YYYY')} - ${moment(
        DateRange.convertFromEpochSeconds(initialState?.where?.end),
      ).format('DD-MM-YYYY')}`;
    } else {
      return initialState?.where?.label;
    }
  }
}
