<form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col h-full">
  <div class="flex items-center p-9 border-b border-border justify-between">
  <div class="flex items-center flex-col">
    <div class="flex items-center justify-start w-full">
      <h1 class="text-28 font-bold">Create new board</h1>
    </div>
    <div class="text-20 justify-start w-full">
      What message would you like me to write here?
    </div>
  </div>
  <itq-mat-button
    [matTooltip]="'Close'"
    [materialStyle]="'basic'"
    [type]="'button'"
    (clickEvent)="onClose()"
  >
    <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
  ></itq-mat-button>
</div>
<div class="flex  flex-col p-7 bg-background justify-between flex-1">
  <div class="flex flex-col">
  <div
    class="rounded-md border border-border bg-white flex justify-center p-7 items-center"
  >
    <fa-icon
      class="pr-2"
      [icon]="['far', 'chart-column']"
      [size]="'3x'"
    ></fa-icon>
    <itq-input
      class="flex-1 board__name"
      [formControlName]="'name'"
      [autoFocus]="true"
      [placeholder]="'Please type to add board name'"
    ></itq-input>
    <itq-input
      class="flex-1"
      [formControlName]="'description'"
      [placeholder]="'Please type to add board description'"
    ></itq-input>
    <itq-mat-button
      class="ml-2"
      [padding]="Sizes.SM"
      [materialStyle]="'basic'"
      [style]="board.defaultBoard ? 'primary' : 'basic'"
      [type]="'button'"
      [matTooltip]="board.defaultBoard ? 'Unset as default' : 'Set as default'"
      (clickEvent)="onToggleDefault()"
    >
      <fa-icon [icon]="['far', 'star']" [size]="'lg'"></fa-icon>
    </itq-mat-button>

  </div>
  <span class="flex font-bold text-17 my-7">Create your first widget...</span>
  <div
    class="rounded-md border border-border bg-white flex justify-left p-7 w-full"
  >
    <itq-widget-type-thumbnail-list
      class="flex w-full"
      [disabled]="form?.get('name').invalid"
      (add)="onAdd($event)"
    ></itq-widget-type-thumbnail-list>
  </div>
  </div>
  <div class="flex justify-end">
    <itq-mat-button
      class="ml-2"
      [disabled]="!form.valid"
      [padding]="Sizes.SM"
      [style]="'primary'"
      [materialStyle]="'flat'"
      [type]="'submit'"
    >
      SAVE
    </itq-mat-button>
  </div>
</div>
</form>
