<ng-container
  *ngIf="
    navigationItem?.action === WidgetActions.SETTINGS ||
    navigationItem?.action === WidgetActions.EXPLORE
  "
>
  <itq-profiles-explore
    [navigationItem]="navigationItem"
    [form]="form"
    [articleDetail]="articleDetail"
  ></itq-profiles-explore>
</ng-container>
<ng-container
  *ngIf="
    navigationItem?.action === WidgetActions.CREATE ||
    navigationItem?.action === WidgetActions.CLONE
  "
>
  <itq-profiles-wizard [navigationItem]="navigationItem"></itq-profiles-wizard>
</ng-container>
