import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { CoreModule } from '@intorqa-ui/core';
import { SharedModule } from '../shared/shared.module';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { DocumentItem } from '@portal/shared/interfaces/document.interface';
import { DocumentItemService } from '@portal/shared/services/document-item.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';

@Component({
  selector: 'itq-page-document',
  templateUrl: './page-document.component.html',
  styleUrls: ['./page-document.component.scss'],
  standalone: true,
  imports: [CoreModule, SharedModule],
})
export class PageDocumentComponent implements OnInit, OnDestroy {
  public segment: ISegment;
  private queryParamsSubscription: Subscription;
  private docId: string;

  readonly WidgetActions = WidgetActions;

  constructor(
    private docService: DocumentItemService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.queryParamsSubscription = this.route.params.subscribe((params) => {
      this.docId = params.id;
    });
    this.docService.getDocument(this.docId).then((response: DocumentItem) => {
      this.segment = { scope: SegmentScope.ARTICLE_DETAIL, value: response };
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }
}
