import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Category,
  QueryFilters,
  QueryModel,
  Sections,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
  @Input() query: Query;
  @Input() form: FormGroup;
  @Input() dataSource: Array<Category> = [];
  @Input() section: Sections;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() timeline: Timeline;

  @Output() addTag = new EventEmitter<ITagMetadata>();
  @Output() removeTag = new EventEmitter<ITagMetadata>();

  readonly Sizes = Sizes;
  readonly TagCategory = TagCategory;

  public categoriesDatasource: Array<Category>;
  public tagsDataSource: Array<ICustomTag>;
  public showLoader = true;
  public category: TagCategory;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
  );
  private reloadFiltersSubscription: Subscription;

  constructor(
    private categoryService: CategoryService,
    private virtualScrollingService: VirtualScrollService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.reloadFilters();
    this.reloadFiltersSubscription = this.tagService.reloadFilters$.subscribe(
      (response: { queryFilters: QueryFilters; query: Query }) => {
        this.initialState = response.queryFilters;
        this.query = response.query;
        this.reloadFilters();
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.section?.previousValue !== changes?.section?.currentValue) {
      this.category = this.dataSource[0].name;
    }
  }

  ngOnDestroy(): void {
    this.reloadFiltersSubscription.unsubscribe();
  }

  private reloadFilters(): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollingService.dataBoundObservable.next();
    });
    this.onGetTags(this.initialState);
  }

  public onExpandCategory(category: TagCategory): void {
    if (this.category !== category) {
      this.initialState.resetPagination().then(() => {
        this.virtualScrollingService.dataBoundObservable.next();
      });
      this.initialState.query = undefined;
      this.category = category;
      this.tagsDataSource = undefined;
      this.onGetTags(this.initialState);
    }
  }

  public onGetTags(params: QueryFilters): void {
    this.showLoader = true;
    const queryModel = this.query.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    this.categoryService
      .getTags(
        params.query,
        params,
        queryModel.modelToDTO(),
        this.category,
        this.userService.userPreferences.defaultEcosystemId,
        this.tagsDataSource
          ? this.tagsDataSource[this.tagsDataSource?.length - 1]?.name
          : undefined,
        this.navigationItem instanceof DiscordNavigationItem
          ? this.navigationItem.context
          : undefined,
      )
      .then((response: Array<ICustomTag>) => {
        this.tagsDataSource =
          params.page > 1 ? [...this.tagsDataSource, ...response] : response;
        this.showLoader = false;
      });
  }

  public onChangeValue(item: ITagMetadata): void {
    this.addTag.emit(item);
  }

  public trackByMethod(index: number): number {
    return index;
  }

  public onRemoveTag(item: ITagMetadata): void {
    this.removeTag.emit(item);
  }
}
