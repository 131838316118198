<ng-container [formGroup]="form">
  <itq-sidebar class="widget_settings__left p-4">
    <itq-headline-simple
      [iconName]="navigationItem.icon"
      [iconType]="navigationItem.iconType"
      [iconSize]="'2x'"
      [title]="navigationItem?.item?.name"
      [description]="navigationItem?.item?.description"
    ></itq-headline-simple>
    <div class="filters__panel m-4">
      <itq-form-row class="mb-7">
        <itq-form-element>
          <itq-input
            [formControlName]="'query'"
            [type]="'text'"
            [placeholder]="'Search notifications'"
            (search)="onSearch()"
          ></itq-input>
        </itq-form-element>
      </itq-form-row>
      <itq-form-row class="mb-4">
        <itq-form-element>
          <span class="mb-1">Alert type:</span>
          <itq-dropdown
            [dataSource]="alertTypesDataSource"
            formControlName="alertType"
            [dataFields]="{ name: 'label', value: 'id' }"
            (changeValue)="onSearch()"
            (clear)="onSearch()"
          ></itq-dropdown>
        </itq-form-element>
      </itq-form-row>
      <itq-form-row>
        <itq-form-element>
          <span class="mb-1">Priority:</span>
          <itq-dropdown
            [dataSource]="priorityDataSource"
            formControlName="priority"
            [dataFields]="{ name: 'name', value: 'value' }"
            (changeValue)="onSearch()"
            (clear)="onSearch()"
          ></itq-dropdown>
        </itq-form-element>
      </itq-form-row>
    </div>
  </itq-sidebar>
  <div class="widget_settings__right">
    <div class="right__notifications_container">
      <div class="widget_settings__toolbar">
        <itq-button-thumbnail
          [theme]="'tabs'"
          [formControlName]="'showAll'"
          [dataSource]="buttonDataSource"
          (change)="onSearch()"
        ></itq-button-thumbnail>
        <div class="toolbar__container">
          <itq-toggle-date-range
            (search)="onDateRangeChange($event)"
          ></itq-toggle-date-range>
        </div>
      </div>
      <itq-notifications-timeline
        [raisedAlertId]="raisedAlertId"
        [initialState]="initialState"
        [dataSource]="notificationsDataSource"
        (drilldown)="onDrilldown($event.segment, $event.notification)"
        (dataBound)="onDataBound($event)"
      ></itq-notifications-timeline>
    </div>
    <div
      class="flex flex-grow p-4 border-l border-solid border-border flex-1"
      *ngIf="raisedAlertId"
    >
      <itq-virtual-table
        class="flex-1"
        [initialState]="initialState"
        [tableColumns]="tableColumns"
        [theme]="'tile'"
        [headerVisible]="false"
        [dataSource]="matchesDataSource?.items"
        [totalCount]="matchesDataSource?.totalHits"
      ></itq-virtual-table>
    </div>

    <itq-notification-research-detail
      class="flex flex-grow p-4 border-l border-solid border-border bg-background flex-1"
      *ngIf="researchNotification"
      [notification]="researchNotification"
    ></itq-notification-research-detail>

    <itq-document-item-detail
      class="flex-1"
      *ngIf="articleDetail"
      [viewParent]="true"
      [segment]="articleDetail"
      [form]="form"
      [navigationItem]="navigationItem"
      [action]="navigationItem?.action"
      (drilldown)="onDrilldownDocumentDetail($event)"
    ></itq-document-item-detail>
  </div>
</ng-container>
<ng-template #unreadTemplate> </ng-template>
<ng-template #template let-data>
  <itq-document-item-tile
    [item]="data"
    [form]="form"
    [navigationItem]="navigationItem"
    (drilldown)="onDrilldownMatches($event)"
    (createProfile)="onCreateProfile($event)"
  ></itq-document-item-tile>
</ng-template>
