<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'alerts'">
    <itq-chart
      *ngIf="trendDataSource?.totalHits > 0"
      class="mb-4"
      [widget]="trendWidget"
      [data]="trendDataSource"
    ></itq-chart>
    <itq-form-row
      class="mb-4"
      *ngIf="navigationItem?.action !== WidgetActions.CREATE"
    >
      <itq-form-element [inline]="true" [size]="'auto'">
        <span class="mr-2">Active:</span>
        <mat-slide-toggle
          [color]="'primary'"
          [formControlName]="'active'"
        ></mat-slide-toggle>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row class="mb-4">
      <itq-form-element
        class="mr-4 justify-center"
        [inline]="true"
        [size]="'auto'"
      >
        <span class="mr-2">Use daily average?</span>
        <mat-slide-toggle
          [color]="'primary'"
          [formControlName]="'dailyAverage'"
          (change)="onChangeDailyAverage($event)"
        ></mat-slide-toggle>
      </itq-form-element>
      <itq-form-element [inline]="true">
        <span class="mr-3 required">Priority:</span>
        <div class="flex items-center">
          <itq-dropdown
            [formControlName]="'priority'"
            [dataSource]="priorityDataSource"
            [dataFields]="{ name: 'name', value: 'value' }"
          ></itq-dropdown>
        </div>
        <mat-error
          *ngIf="
            form?.controls['priority']?.touched &&
            form?.controls['priority']?.hasError('required')
          "
        >
          Priority is <strong>required</strong>
        </mat-error>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row class="mb-4 frequency__container">
      <itq-form-element class="mr-3">
        <itq-dropdown
          [formControlName]="'condition'"
          [dataSource]="conditionDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
      </itq-form-element>
      <itq-form-element class="mr-3">
        <itq-input [type]="'number'" formControlName="count"></itq-input>
      </itq-form-element>
      times in the last
      <itq-form-element class="ml-4">
        <itq-dropdown
          [placeholder]="'Please select a time range'"
          [formControlName]="'period'"
          [dataSource]="periodDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
      </itq-form-element>
    </itq-form-row> </ng-container
></ng-container>
