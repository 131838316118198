<itq-form [formGroup]="form">
  <div class="container__left">
    <itq-headline-simple
      [iconName]="
        navigationItem.action === WidgetActions.CREATE
          ? 'plus'
          : navigationItem.action === WidgetActions.SETTINGS
            ? 'pencil'
            : 'clone'
      "
      [iconSize]="'2x'"
      [iconType]="IconType.FONT_AWESOME"
      [title]="form?.get('details.name')?.value || ' Create profile'"
      [description]="form?.get('details.description')?.value"
    ></itq-headline-simple>
    <mat-stepper
      orientation="vertical"
      class="left__body"
      (selectionChange)="action = undefined"
      #stepper
    >
      <mat-step
        state="info"
        [stepControl]="form?.controls?.details"
        [errorMessage]="
          form?.get('details.name')?.hasError('required')
            ? 'Name is a required field'
            : 'Vendor name already exists'
        "
      >
        <ng-template matStepLabel>Tell us about your profile</ng-template>
        <itq-profile-wizard-details
          class="left__stepper"
          [navigationItem]="navigationItem"
          [profile]="profile"
          [form]="form"
        ></itq-profile-wizard-details>
        <div class="stepper_footer justify-end">
          <button
            [disabled]="!form?.controls?.details?.valid"
            type="button"
            mat-button
            matStepperNext
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step
        state="metadata"
        [optional]="form.get('metadata') | isOptional"
        [stepControl]="form.get('metadata')"
        [errorMessage]="'Metadata is a required field'"
      >
        <ng-template matStepLabel>Add descriptive data</ng-template>
        <itq-profiles-wizard-metadata
          class="left__stepper"
          [profile]="profile"
          [form]="form"
        ></itq-profiles-wizard-metadata>
        <div class="stepper_footer">
          <button mat-button matStepperPrevious type="button">Previous</button>
          <button
            [disabled]="!form?.controls?.metadata?.valid"
            type="button"
            mat-button
            matStepperNext
          >
            Next
          </button>
        </div>
      </mat-step>
      <mat-step
        state="content"
        label="Link tags"
        [optional]="true"
        [stepControl]="
          form?.controls.links?.touched &&
          form?.controls.links?.errors?.invalidDefaultTab
            ? form?.controls?.links
            : null
        "
        [errorMessage]="'You need to set a default tab'"
      >
        <ng-template matStepLabel>Link this profile to tags</ng-template>
        <itq-profiles-links
          [formControlName]="'links'"
          [profile]="profile"
          [action]="action"
          [navigationItem]="navigationItem"
          [form]="form"
          [showTitle]="false"
        ></itq-profiles-links
        ><mat-error
          class="error_container"
          *ngIf="
            form?.controls.links?.touched &&
            form?.controls.links?.errors?.invalidDefaultTab
          "
        >
          You need to set a default tab
        </mat-error>

        <div class="stepper_footer" *ngIf="!showLinkTags">
          <button mat-button matStepperPrevious type="button">Previous</button>
          <button
            *ngIf="
              linkTagsService.links?.items?.length === 0;
              else nextTemplate
            "
            type="button"
            mat-button
            matStepperNext
          >
            Skip
          </button>
          <ng-template #nextTemplate>
            <button mat-button matStepperNext type="button">Next</button>
          </ng-template>
        </div>
      </mat-step>
      <mat-step state="links" [optional]="true">
        <ng-template matStepLabel>Connect to other vendors</ng-template>
        <ng-template matStepContent>
          <itq-profiles-connections
            [formControlName]="'connections'"
            [action]="action"
            [profile]="profile"
            [navigationItem]="navigationItem"
            [form]="form"
            [showTitle]="false"
          ></itq-profiles-connections>

          <div class="stepper_footer" *ngIf="!action">
            <button mat-button matStepperPrevious type="button">
              Previous
            </button>
            <button
              *ngIf="
                connectionsService.connections?.items?.length === 0;
                else nextTemplate
              "
              type="button"
              mat-button
              matStepperNext
            >
              Skip
            </button>
            <ng-template #nextTemplate>
              <button mat-button matStepperNext type="button">Next</button>
            </ng-template>
          </div>
        </ng-template>
      </mat-step>
      <mat-step state="notes" [optional]="true">
        <ng-template matStepLabel>Notes</ng-template>
        <ng-template matStepContent>
          <itq-profiles-notes
            [formControlName]="'notes'"
            [profile]="profile"
            [navigationItem]="navigationItem"
            [action]="action"
            [form]="form"
            [showTitle]="false"
          ></itq-profiles-notes>
          <div class="stepper_footer justify-start" *ngIf="!action">
            <button mat-button matStepperPrevious>Previous</button>
          </div>
        </ng-template>
      </mat-step>
      <ng-template matStepperIcon="info">
        <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
      </ng-template>
      <ng-template matStepperIcon="content">
        <fa-icon [icon]="['far', 'tags']"></fa-icon>
      </ng-template>
      <ng-template matStepperIcon="metadata">
        <fa-icon [icon]="['far', 'database']"></fa-icon>
      </ng-template>
      <ng-template matStepperIcon="links">
        <fa-icon [icon]="['far', 'link']"></fa-icon>
      </ng-template>
      <ng-template matStepperIcon="notes">
        <fa-icon [icon]="['far', 'note']"></fa-icon>
      </ng-template>
    </mat-stepper>
  </div>
  <footer class="right__footer justify-end">
    <itq-nav-button
      *ngIf="navigationItem.action === WidgetActions.CREATE"
      [disabled]="
        !this.form.controls.details?.valid ||
        !this.form.controls.metadata.valid ||
        !this.form.controls.connections.valid ||
        !this.form.controls.links.valid ||
        !this.form.controls.notes.valid
      "
      [openDirection]="{ vertical: 'up', horizontal: 'right' }"
      [openPosition]="'inside'"
      [template]="actionsTemplate"
      [materialStyle]="'flat'"
      [color]="'primary'"
      [padding]="Sizes.MD"
      [width]="180"
      >SAVE</itq-nav-button
    >
  </footer>
  <ng-template #actionsTemplate>
    <div class="actions_container">
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (mouseDownEvent)="onSubmit(CreateProfileActions.LOAD)"
      >
        <span class="text-wrapper">Save & Load</span>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (mouseDownEvent)="onSubmit(CreateProfileActions.CLOSE)"
      >
        <span class="text-wrapper">Save & Close</span>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [padding]="Sizes.SM"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (mouseDownEvent)="onSubmit(CreateProfileActions.CREATE_NEW)"
      >
        <span class="text-wrapper">Save & Create New</span>
      </itq-mat-button>
    </div>
  </ng-template>
</itq-form>
