import { NgModule } from '@angular/core';
import {
  AngularModule,
  CoreModule,
  FAwesomeModule,
  HeadLineSimpleComponent,
  MaterialModule,
} from '@intorqa-ui/core';
import { SharedModule } from '../../../shared/shared.module';
import { ChartExploreComponent } from './chart-explore/chart-explore.component';
import { ChartWizardComponent } from './chart-wizard/components/chart-wizard/chart-wizard.component';
import { WidgetSettingsChartComponent } from './widget-settings-chart.component';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';

@NgModule({
  imports: [
    FAwesomeModule,
    MaterialModule,
    HeadLineSimpleComponent,
    AngularModule,
    CoreModule,
    SharedModule,
    ChartWizardComponent,
    ChartComponent,
  ],
  declarations: [WidgetSettingsChartComponent, ChartExploreComponent],
  exports: [WidgetSettingsChartComponent],
})
export class WidgetSettingsChartModule {}
