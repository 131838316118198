import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CoreModule,
  DateQueryType,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { TagComparison } from '@portal/boards/models/tag-comparison';
import { TimeSeries } from '@portal/boards/models/time-series';
import { WidgetService } from '@portal/boards/services/widget.service';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import moment from 'moment';

@Component({
  selector: 'itq-all-tags-datasource',
  templateUrl: './all-tags-datasource.component.html',
  styleUrls: ['./all-tags-datasource.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CoreModule],
})
export class AllTagsDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() widget: TimeSeries | TagComparison;

  @Output() changeDataValue = new EventEmitter<Array<string>>();

  public dataPointsDataSource: Array<ICustomTag>;
  public dataValues: Array<any> = [];
  public initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
  );

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService,
    public widgetService: WidgetService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadMetadata();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataPoints');
  }

  private loadMetadata(): void {
    let tagIds = this.widget.getTagIds();
    let fieldFilterIds = this.widget.getFieldFilterTagIds();
    if (
      tagIds?.included?.length > 0 ||
      tagIds?.excluded?.length > 0 ||
      fieldFilterIds?.included?.length > 0 ||
      fieldFilterIds?.excluded?.length > 0
    ) {
      this.tagService
        .getSelections(
          tagIds,
          fieldFilterIds,
          undefined,
          this.categoryService.categories,
        )
        .then((response: Array<ITagMetadata>) => {
          this.dataValues = response.map((item: ITagMetadata) => ({
            name: item.tagName,
            id: item.tagId,
          }));
          this.form.controls.dataPoints.setValue(this.dataValues || []);

          this.onGetTags(this.initialState);
        });
    }
  }

  public createForm(): void {
    this.form.addControl(
      'dataPoints',
      new FormControl([], Validators.required),
    );
  }

  public onGetTags(params: QueryFilters): void {
    this.initialState = params;
    this.categoryService
      .getTags(
        params.query,
        params,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        params.page > 1
          ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1].name
          : undefined,
      )
      .then((response: Array<ICustomTag>) => {
        this.dataPointsDataSource =
          params.page > 1
            ? [...this.dataPointsDataSource, ...response]
            : response;
      });
  }

  public onDataBound(): void {
    this.changeDataValue.emit(
      this.form.controls.dataPoints.value.map((item: ICustomTag) => item.id),
    );
  }
}
