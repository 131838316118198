<div class="flex flex-col w-full">
  <span class="px-4 py-2 bg-border font-bold mb-4 rounded-md">WIDGET TYPE</span>
  <div class="flex">
    <itq-thumbnail
      class="mr-4 bg-white"
      [icon]="'comment'"
      [title]="'Posts timeline'"
      [description]="'A stream of posts from a tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TIMELINE)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="mr-4 bg-white"
      [icon]="'search'"
      [title]="'Tag analysis'"
      [description]="'Analyse within specific tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TAG_ANALYSIS)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="mr-4 bg-white"
      [icon]="'chart-pie'"
      [title]="'Tag comparison'"
      [description]="'Compare one or more tags'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TAG_COMPARISON)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="bg-white"
      [icon]="'arrow-trend-up'"
      [title]="'Tag trends'"
      [description]="'Analyse tag trends over time'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TIME_SERIES)"
    ></itq-thumbnail>
  </div>
</div>
