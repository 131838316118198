import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  EventBusService,
  FAwesomeModule,
  IQueryColumn,
  IconType,
  IconTypes,
  PillType,
  QueryFilters,
  SearchFieldType,
  SharedService,
  Sizes,
  SvgComponent,
  TableColumn,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { TagAnalysis } from '@portal/boards/models/tag-analysis';
import { Widget } from '@portal/boards/models/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { GetProfileTypeIconPipe } from '@portal/profiles/pipes/profiles.pipe';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/profiles.service';
import { ToolbarActions } from '@portal/shared/components/toolbar/toolbar.enum';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { TimelineStatus } from '@portal/shared/enums/timeline-feed.enum';
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import { IData } from '@portal/shared/interfaces/document.interface';
import {
  IDataPoint,
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { INavigationHistoryItem } from '@portal/widget-settings/interfaces/navigation-history-item.interface';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { TimelineNavigationItem } from '@portal/widget-settings/models/timeline-navigation-item.model';
import { KeycloakService } from 'keycloak-angular';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetSettingsComponent } from '../../../widget-settings/widget-settings.component';
import { TimelineFeedComponent } from '../timeline-feed/timeline-feed.component';
import { TIMELINE_CHART_TYPES } from '@portal/boards/const/timeline.const';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'itq-timeline-widget',
  templateUrl: './timeline-widget.component.html',
  styleUrls: ['./timeline-widget.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    MatTooltip,
    CommonModule,
    SvgComponent,
    TimelineFeedComponent,
    ClipboardModule,
    GetProfileTypeIconPipe,
  ],
  providers: [VirtualScrollService],
})
export class TimelineWidgetComponent implements OnInit {
  @Input() segment: any;
  @Input() widget: Timeline;
  @Input() action: WidgetActions;
  @Input() board: Board;
  @Input() selectedNavigationHistory: INavigationHistoryItem;
  @Input() query: string | Array<IQueryColumn>;

  @Output() delete = new EventEmitter<Timeline>();
  @Output() clone = new EventEmitter<Timeline>();
  @Output() drilldown = new EventEmitter<{ segment: any; widget: Widget }>();
  @Output() update = new EventEmitter<Timeline>();

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  public chartInstance: echarts.ECharts;
  public data: IData;
  public widgetData: IWidgetData;
  public showLoader = false;
  public tableColumns: Array<TableColumn> = [];
  public displayTypes = TIMELINE_CHART_TYPES;
  public selectedDisplayType: IDisplayType;
  public tagShareLink: string;
  public tagAnalysisWidget: TagAnalysis;
  public noContentTitle = 'No results found';
  public noContentMessage = 'Please update your filters and try again';
  public timelineStatus: TimelineStatus;
  public showListProfile = false;
  public showNestedNavbar = false;
  public profileTypesDataSource: Array<ProfileType> = [];
  public profilesDataSource: Array<Profile>;
  public profileInitialState: QueryFilters;
  public isLoadingProfiles = false;
  private subscriptions = new Subscription();
  public initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
  );

  readonly PillType = PillType;
  readonly AnalysisTypes = AnalysisTypes;
  readonly TimelineStatus = TimelineStatus;
  readonly WidgetActions = WidgetActions;
  readonly Sizes = Sizes;
  readonly IconTypes = IconTypes;
  readonly ChartType = ChartType;
  readonly Align = Align;
  readonly SegmentScope = SegmentScope;
  readonly ProfileDrildownScope = ProfileDrildownScope;

  constructor(
    private widgetService: WidgetService,
    private virtualScrollService: VirtualScrollService,
    private snackBar: MatSnackBar,
    private customOverlayService: CustomOverlayService,
    private tagService: TagService,
    private profileService: ProfileService,
    private linkTagsService: LinkTagsService,
    public keycloakService: KeycloakService,
    private eventBusService: EventBusService,
    readonly sharedService: SharedService,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute,
    readonly boardService: BoardService,
  ) {
    this.selectedDisplayType = TIMELINE_CHART_TYPES.find(
      (item: IDisplayType) => {
        return item.type === ChartType.TIMELINE;
      },
    );
    this.profileTypesDataSource = this.profileService.types;
  }

  ngOnInit(): void {
    this.tagShareLink = `${window.location.origin}/boards/${this.board.id}?widgetid=${this.widget.widgetId}`;
    this.bindQueryParamsSubscription();
    this.bindRemoveWidgetSubscription();
    this.bindReloadWidgetSubscription();
    this.registerEventBusEvents();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterTaggingEventBusEvent();
    this.unRegisterEventBusDeleteWidgetEvent();
    this.unRegisterEventBusUpdateWidgetEvent();
  }

  private registerEventBusEvents(): void {
    this.registerEventBusDeleteWidgetEvent();
    this.registerEventBusUpdateWidgetEvent();
  }

  private bindReloadWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.reload$.subscribe(
        (params: { widget: Widget; initialState: QueryFilters }) => {
          if (
            !params.widget ||
            params.widget.widgetId === this.widget.widgetId
          ) {
            this.initialState = params.initialState;
            if (this.selectedDisplayType.type === ChartType.TIMELINE) {
              this.onCheckTagging();
              this.onDataBound(this.initialState);
            } else {
              this.getWidgetData(this.initialState);
            }
          }
        },
      ),
    );
  }

  private bindQueryParamsSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params?.widgetid && this.widget.widgetId === params.widgetid) {
          this.onExplore();
        }
      }),
    );
  }

  private bindRemoveWidgetSubscription(): void {
    this.subscriptions.add(
      this.boardService.removeTimelines$.subscribe((widget: Timeline) => {
        if (widget.widgetId === this.widget.widgetId) {
          this.onDeleteWidget(this.widget);
        }
      }),
    );
  }

  public onUpdateRef(ref: echarts.ECharts): void {
    this.chartInstance = ref;
  }

  public onEdit(): void {
    const navigationItem = new NavigationHistoryItem(
      `${this.widget.widgetId}_${WidgetActions.SETTINGS}`,
      cloneDeep(this.widget),
      WidgetActions.SETTINGS,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'pencil',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      this.board.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private onEditCallback(widget: Timeline): void {
    this.widget = widget;
    if (JSON.stringify(this.widget._extras) !== widget?._extras) {
      this.onCheckTagging();
      this.onDataBound(this.initialState);
    }
  }

  public onClone(): void {
    this.clone.emit(this.widget);
  }

  public onDeleteWidget(widget: Timeline): void {
    this.unRegisterTaggingEventBusEvent();
    this.boardService
      .updateWidgets(this.board.id, {
        delete: [widget.widgetId],
      })
      .subscribe();
  }

  private loadExploreView(
    segment?: ISegment,
    initialState?: QueryFilters,
  ): void {
    const widget = cloneDeep(this.widget);
    const rules = [
      new QueryRule(DTOQueryFieldType.tag, DTOQueryConditionOperator.in, [
        widget.tagId,
      ]),
    ];
    if (this.initialState?.query) {
      rules.push(
        new QueryRule(DTOQueryFieldType.content, DTOQueryConditionOperator.in, [
          this.initialState?.query,
        ]),
      );
    }
    const navigationItem = new TimelineNavigationItem(
      `${this.widget.widgetId}_${WidgetActions.EXPLORE}`,
      widget,
      WidgetActions.EXPLORE,
      rules,
      initialState,
      new FormGroup({}),
      'magnifying-glass',
      IconType.FONT_AWESOME,
      this.selectedDisplayType,
      undefined,
      this.board.id,
      segment?.value?.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
        articleDetail: segment,
      },
      closeBtnStyle: 'basic',
      size: "w-[90%]",
      type: CustomOverlayType['almost-full'],
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onExplore(): void {
    const initialState = this.initialState.cloneDeep();
    initialState.resetPagination();
    this.loadExploreView(undefined, initialState);
  }

  public onChangeDisplay(params: IDisplayType): void {
    this.selectedDisplayType = params;
    if (this.selectedDisplayType.type === ChartType.TIMELINE) {
      this.initialState.page = 1;
      this.onDataBound(this.initialState);
    } else {
      this.tagAnalysisWidget = new TagAnalysis(
        this.widget.widgetId,
        this.widget.username,
        AnalysisTypes.TAG_ANALYSIS,
        this.widget.name,
        this.widget.description,
        undefined,
        undefined,
        undefined,
        undefined,
        params.options,
        [(this.widget as Timeline).tagId],
        [params.id as TagCategory],
        25,
        ChartType.TABLE,
        this.userService.userPreferences.defaultEcosystemId,
      );
      this.tableColumns = this.tagAnalysisWidget.getTableColumns(
        this.countTemplate,
      );
      this.widgetData = undefined;
      this.getWidgetData(this.initialState);
    }
  }

  public onLoadCount(row: IDataPoint): void {
    const timeline = new Timeline(
      this.widget.widgetId,
      this.widget.username,
      AnalysisTypes.TIMELINE,
      `${this.widget.name}: ${row.category}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      this.tagAnalysisWidget.dataSource[0],
      undefined,
      undefined,
      undefined,
      this.widget.categoryId,
      this.widget.lastTaggingTime,
      this.widget.updatedDate,
      this.widget.alertTypeId,
    );
    const navigationItem = new NavigationHistoryItem(
      `${timeline.widgetId}_${WidgetActions.DRILLDOWN}`,
      cloneDeep(timeline),
      WidgetActions.DRILLDOWN,
      [
        {
          field:
            row.tagId.search('Field Filter') > -1
              ? DTOQueryFieldType.filter
              : DTOQueryFieldType.tag,
          operator: DTOQueryConditionOperator.in,
          value: [row.tagId],
        },
        {
          field: DTOQueryFieldType.tag,
          operator: DTOQueryConditionOperator.in,
          value: [timeline.tagId],
        },
      ],
      this.initialState,
      new FormGroup({}),
      'search',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
        initialState: this.initialState,
        actions: [
          {
            action: ToolbarActions.TOGGLE_VIEWS,
          },
          {
            action: ToolbarActions.RESET_FILTERS,
          },
          {
            action: ToolbarActions.DATE,
            expanded: this.initialState?.where ? true : false,
            data: {
              date: this.initialState?.where,
            },
          },
          {
            action: ToolbarActions.REFRESH,
          },
          {
            action: ToolbarActions.API,
          },
        ],
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: "w-[90%]",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public scrollToTop(): void {
    this.virtualScrollService.dataBoundObservable.next();
  }

  public onDrilldown(segment?: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.loadExploreView(segment, this.initialState.cloneDeep());
        break;
      default:
        break;
    }
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      this.selectedDisplayType,
      undefined,
      this.board.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: "w-[90%]",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      this.selectedDisplayType,
      undefined,
      this.board.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: "w-[90%]",
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onShareLink(): void {
    this.snackBar.open('Tag shared successfully!', 'Close', {
      horizontalPosition: 'right',
      duration: 5000,
      verticalPosition: 'top',
    });
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    const queryModel = new Query();
    queryModel.addRule(
      new QueryRule(DTOQueryFieldType.tag, DTOQueryConditionOperator.in, [
        this.widget.tagId,
      ]),
    );
    const initialState = new QueryFilters(
      30,
      params?.page || this.initialState?.page,
      params?.where || this.initialState?.where,
      params.query || this.initialState.query,
      undefined,
    );
    if (params?.query) {
      queryModel.query.rules.push({
        field: DTOQueryFieldType.content,
        operator: DTOQueryConditionOperator.contains,
        value: params?.query,
      });
    }
    this.tagService
      .execute(
        initialState,
        queryModel,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .then((response: IData) => {
        if (initialState.page > 1) {
          this.data.result = [...this.data.result, ...response.result];
        } else {
          this.data = response;
        }
        if (
          this.data.count === 0 &&
          this.timelineStatus === TimelineStatus.COMPLETED
        ) {
          this.noContentTitle = 'No results found';
          this.noContentMessage = 'Please update your filters and try again';
        }
        this.showLoader = false;
      });
  }

  private onCheckTagging(): void {
    this.timelineStatus =
      this.widget.lastTaggingTime > this.widget.updatedDate
        ? TimelineStatus.COMPLETED
        : TimelineStatus.IN_PROGRESS;
    if (this.timelineStatus === TimelineStatus.IN_PROGRESS) {
      this.noContentTitle = 'No results yet';
      this.noContentMessage =
        'Your tag is being built, please wait to see content.';

      this.registerEventBusTaggingEvent();
    }
  }

  private getWidgetData(params: QueryFilters): void {
    this.showLoader = true;

    const queryModel = new Query();
    if (params?.query) {
      queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [params?.query],
        ),
      );
    }
    this.widgetService
      .getData(
        {
          widget: this.tagAnalysisWidget,
          filters: queryModel.modelToDTO(),
        },
        {
          where: params?.where,
        },
      )
      .then((response: IWidgetData) => {
        this.widgetData = response;
        this.showLoader = false;
      });
  }

  public onCreateProfileTag(
    scope: ProfileDrildownScope,
    profileType: ProfileType,
  ): void {
    const profileDrilldown = new ProfileDrilldown(
      scope,
      profileType,
      this.widget.tagId,
    );
    this.onCreateProfile(profileDrilldown);
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.scope === ProfileDrildownScope.TAG
        ? this.widget.name
        : profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'md',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onGetProfileTypes(): void {
    this.profileService
      .getProfileTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<ProfileType>) => {
        this.profileTypesDataSource = response;
      });
  }

  public onLinkProfile(profile: Profile): void {
    const link = new LinkTag(
      undefined,
      undefined,
      TagCategory['My Tags'],
      true,
      this.widget.name,
      undefined,
      undefined,
      this.widget.tagId,
    );
    this.linkTagsService.linkTags(profile.profileId, [link]).subscribe(() => {
      this.snackBar.open(
        `${this.widget.name} has been linked to profile ${profile.name}!`,
        'Close',
        {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        },
      );
      this.profilesDataSource = this.profilesDataSource.filter(
        (item: Profile) => {
          return item.profileId !== profile.profileId;
        },
      );
    });
  }

  public onProfileDataBound(params: QueryFilters): void {
    this.onGetProfiles(params).subscribe();
  }

  public onGetProfiles(params: QueryFilters): Observable<IProfileData> {
    if (typeof params.query === 'string') {
      params.query = [
        {
          searchValues: [params.query],
          searchField: 'name',
          searchFieldType: SearchFieldType.TEXT,
        },
      ];
    }
    return this.profileService
      .getProfiles(params, this.userService.userPreferences.defaultEcosystemId)
      .pipe(
        map((response: IProfileData) => {
          this.profilesDataSource = cloneDeep(response.items);
          return response;
        }),
      );
  }

  public onMouseOverLinkProfile(): void {
    if (!this.isLoadingProfiles) {
      this.isLoadingProfiles = true;
      this.profileInitialState = new QueryFilters(30, 1, undefined, undefined, {
        direction: 'desc',
        active: 'updatedDate',
      });
      forkJoin([
        this.getSelections(),
        this.onGetProfiles(this.profileInitialState),
      ]).subscribe((response: Array<any>) => {
        this.profilesDataSource = this.profilesDataSource.filter(
          (item: Profile) => !response[0].includes(item.profileId),
        );
        this.showListProfile = true;
        this.showNestedNavbar = false;
        this.isLoadingProfiles = false;
      });
    }
  }

  private getSelections(): Observable<Array<string>> {
    return this.linkTagsService.getTargetLinks(this.widget.tagId);
  }

  private registerEventBusDeleteWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private unRegisterEventBusDeleteWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private deleteWidgetCallback(): (
    err: Error,
    msg: {
      body: { result: boolean };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { result: boolean };
        address: string;
        type: string;
      },
    ) => {
      this.delete.emit(this.widget);
    };
  }

  private registerEventBusUpdateWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private unRegisterEventBusUpdateWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private updateWidgetCallback(): (
    err: Error,
    msg: {
      body: Timeline;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: Timeline;
        address: string;
        type: string;
      },
    ) => {
      this.onEditCallback(msg.body);
    };
  }

  private registerEventBusTaggingEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.CORE_REACTOR}.${EventBusScope.TAGGING}.${this.widget.tagId}.${this.widget.widgetId}`,
      this.handleTaggingMessages(),
    );
  }

  private unRegisterTaggingEventBusEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.CORE_REACTOR}.${EventBusScope.TAGGING}.${this.widget.tagId}.${this.widget.widgetId}`,
      this.handleTaggingMessages(),
    );
  }

  private handleTaggingMessages(): (
    err: Error,
    msg: {
      body: string;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: string;
        address: string;
        type: string;
      },
    ) => {
      if (msg.body === this.widget.tagId) {
        this.onDataBound(this.initialState);
        this.timelineStatus = TimelineStatus.COMPLETED;
        this.unRegisterTaggingEventBusEvent();
      }
    };
  }
}
