<div class="flex flex-col" [formGroup]="form">
  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">TAG</span>

    <itq-dropdown
      formControlName="dataSource"
      [httpBinding]="true"
      [clearSelection]="false"
      [dataSource]="tagsDataSource"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Please select an item...'"
      (dataBound)="onDataBoundDataSources($event)"
      (changeValue)="onChangeDataSources()"
    ></itq-dropdown>
    <span class="text-13 text-metadata pt-1">Select your tags to plot</span>
  </div>

  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">DATA SLICES</span>
    <itq-dropdown
      formControlName="category"
      [clearSelection]="false"
      [dataSource]="categoriesDataSource"
      [dataFields]="{ name: 'name', value: 'name' }"
      (changeValue)="onChangeDataType()"
    ></itq-dropdown>
    <span class="text-13 text-metadata pt-1"
      >Which category are the tags you want to plot in?</span
    >
  </div>
  <div class="flex mb-2 flex-col">
    <span class="mb-1 text-13 font-medium">DATA POINTS</span>
    <itq-multiple-dropdown
      formControlName="dataPoints"
      [dataFields]="{ name: 'name', value: 'id' }"
      [dataSource]="dataPointsDataSource"
      [httpBinding]="true"
      (dataBound)="onDataBoundDataValues($event)"
      (changeValue)="onChangeDataValues()"
    >
    </itq-multiple-dropdown>
    <span class="text-13 text-metadata pt-1">Select your tags to plot</span>
  </div>
</div>
