<div class="filters__container">
  <ng-container *ngIf="dataSource?.length > 0">
    <span
      [ngClass]="{ label: true, 'font-semibold': true, 'mb-1': true, required }"
    >
      Search for text:</span
    >
    <div class="search-term__container">
      <itq-input
        [icon]="'search'"
        [(ngModel)]="searchTerm"
        [placeholder]="'e.g aimbot or \'DLL Injector\''"
        [submitTooltip]="
          'Search for phrases using quotation marks e.g. “DLL Injector” or exclude them by prefixing with a minus (-) sign e.g. -wallhack.'
        "
        (search)="onSearch()"
        (clear)="onSearch()"
      ></itq-input>
    </div>

    <div class="query__container">
      <mat-tab-group animationDuration="0ms">
        <ng-container *ngIf="dataSource | getSections as sections">
          @for (section of sections; track section) {
            <mat-tab [label]="section.name">
              <ng-template mat-tab-label>
                <fa-icon
                  [icon]="['far', SectionIcons[section.name]]"
                  class="mr-1"
                ></fa-icon>
                <span class="font-semibold"> {{ section.name }}</span>
              </ng-template>
              <ng-template matTabContent>
                <itq-category-list
                class="h-full"
                  [dataSource]="dataSource | filterBySection: section.name"
                  [navigationItem]="navigationItem"
                  [query]="query"
                  [timeline]="timeline"
                  [section]="section.name"
                  [form]="form"
                  (addTag)="onAddTag($event)"
                  (removeTag)="onRemoveTag($event)"
                ></itq-category-list>
              </ng-template>
            </mat-tab>
          }
        </ng-container>
      </mat-tab-group>
    </div>
  </ng-container>
</div>

<div class="selections__container" *ngIf="query?.selections?.length > 0">
  <itq-nav-button
    [openDirection]="{ vertical: 'up', horizontal: 'right' }"
    [openPosition]="'outside'"
    [materialStyle]="'basic'"
    [width]="700"
    [template]="selectionsTemplate"
  >
    <fa-icon [icon]="['far', 'filter']"></fa-icon>
    View query ({{ query.selections.length }} )</itq-nav-button
  >
  <itq-mat-button
    [materialStyle]="'basic'"
    [type]="'button'"
    (clickEvent)="onClearFilters()"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Clear query
  </itq-mat-button>
</div>

<ng-template #selectionsTemplate>
  <itq-timeline-selections
    [query]="query"
    (removeTag)="onRemoveTag($event)"
  ></itq-timeline-selections>
</ng-template>
