<div class="flex mb-2 flex-col" [formGroup]="form">
  <span class="mb-1 text-13 font-medium">TAG</span>
  <itq-dropdown
    formControlName="dataSource"
    [clearSelection]="false"
    [httpBinding]="false"
    [dataSource]="tagsDataSource"
    [dataFields]="{ name: 'name', value: 'tagId' }"
    [placeholder]="'Please select an item...'"
    (changeValue)="onChangeDataSources($event)"
  ></itq-dropdown>

  <span class="text-13 text-metadata pt-1"> Select your tags to plot</span>
</div>
