import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { WidgetActions } from "@portal/shared/enums/widget.enum";
import { ISegment } from "@portal/widget-settings/interfaces/widget-settings.interface";
import { NavigationHistoryItem } from "@portal/widget-settings/models/navigation-history-item.model";

@Component({
  selector: "itq-widget-settings-chart",
  templateUrl: "./widget-settings-chart.component.html",
  styleUrls: ["./widget-settings-chart.component.scss"],
})
export class WidgetSettingsChartComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() articleDetail: ISegment;
  @Input() segment: any;
  @Input() form: FormGroup;

  readonly WidgetActions = WidgetActions;

  constructor() {}

  ngOnInit(): void {}
}
