import { CommonModule } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayService,
  FAwesomeModule,
  MillisToDate,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { AlertTypesIcons } from '@portal/notifications/enums/alerts.enum';
import {
  PriorityLabel,
  PriorityColor,
} from '@portal/notifications/enums/notification.enum';
import { ResearchNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { SharedModule } from '@portal/shared/shared.module';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-notifications-research',
  templateUrl: './notifications-research.component.html',
  styleUrls: ['./notifications-research.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    MillisToDate,
  ],
})
export class NotificationsResearchComponent {
  @Input() notification: ResearchNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;

  private subscription = new Subscription();

  @HostBinding('style.borderRight') borderRight: string;
  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.notificationService.markAsRead$.subscribe(() => {
        this.class = '';
      }),
    );
    this.class = !this.notification.read ? 'unread' : '';
    this.borderRight =
      '10px solid ' + PriorityColor[this.notification.priority];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onToggleRead(): void {
    this.subscription.add(
      this.notificationService
        .toggleReadState(
          this.notification.raisedAlertId,
          !this.notification.read,
        )
        .subscribe((response: ResearchNotification) => {
          this.notification = response;
          this.class = !this.notification.read ? 'unread' : '';
          this.notificationService
            .getUnreadNotificationsCount(
              this.initialState,
              this.form?.get('priority')?.value,
            )
            .subscribe();
        }),
    );
  }

  public onOpenNotification(): void {
    this.notificationService.loadNotificationDetail$.next(this.notification);
  }
}
