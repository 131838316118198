import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Category,
  IPresetQuery,
  QueryFilters,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import {
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DTOQueryOperator,
  Operator,
} from 'projects/core/src/lib/enums/query.enum';
import { QueryType } from '@portal/shared/enums/timeline-query.enum';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import {
  Field,
  FieldMap,
  QueryBuilderConfig,
  Rule,
  RuleSet,
} from 'ngx-angular-query-builder';
import { Subscription } from 'rxjs';
import { UserService } from '@portal/shared/services/user.service';

interface CustomField extends Field {
  _name?: string;
}

interface CustomFieldMap extends FieldMap {
  [key: string]: CustomField;
}

interface CustomQueryBuilderConfig extends QueryBuilderConfig {
  fields: CustomFieldMap;
}

interface IOperatorList {
  fieldName: string;
  operators: Array<IOperator>;
}

interface IOperator {
  name: string;
  value: string;
}

@Component({
  selector: 'itq-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
})
export class QueryBuilderComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() query: Query;
  @Input() dates: IPresetQuery;
  @Input() required = true;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() dataSource: Array<Category>;

  @Output() dataBound = new EventEmitter<Query>();
  @Output() clearFilters = new EventEmitter<Query>();

  public builderConfig: CustomQueryBuilderConfig = {
    fields: {
      content: {
        name: 'Content',
        type: 'textarea',
        operators: [
          DTOQueryConditionOperator.contains,
          DTOQueryConditionOperator['doesnotcontain'],
        ],
      },
    },
  };
  public operatorFields: Array<IOperatorList> = [];
  public searchTerm = '';
  public tagsDataSource: Array<ICustomTag>;
  public queryModel: Query;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
  );
  private getSearchResultsSubscription: Subscription;

  readonly Sizes = Sizes;
  readonly tagCategory = TagCategory;

  constructor(
    public tagService: TagService,
    private categoryService: CategoryService,
    private virtualScrollingService: VirtualScrollService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.getSearchResultsSubscription =
      this.tagService.getSearchResults$.subscribe(() => {
        this.initializeQueryBuilder();
      });
    if (this.navigationItem.action === WidgetActions.CREATE) {
      this.initializeQueryBuilder();
      this.operatorFields = this.getOperatorsList();
      setTimeout(() => {
        this.queryModel = this.query.cloneDeep();
      }, 10);
    } else {
      let tagIds = this.query.getTagIds();
      let fieldFilterIds = this.query.getFieldFilterTagIds();
      let contentIds = this.query.getContentTag();
      this.tagService
        .getSelections(tagIds, fieldFilterIds, contentIds, this.dataSource)
        .then((response: Array<ITagMetadata>) => {
          this.initializeQueryBuilder();
          this.operatorFields = this.getOperatorsList();
          setTimeout(() => {
            const query = this.query.cloneDeep();
            query.selections = response;
            query.reverseMapQueryBuilderMetadata();
            this.queryModel = query;
          }, 10);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dates?.previousValue !== changes?.dates?.currentValue) {
      this.initialState.where = this.dates;
      this.initialState.resetPagination().then(() => {
        this.virtualScrollingService.dataBoundObservable.next();
      });
    }
  }

  ngOnDestroy(): void {
    this.getSearchResultsSubscription.unsubscribe();
  }

  private getOperatorsList(): Array<IOperatorList> {
    const operatorFields: Array<IOperatorList> = [];
    Object.keys(this.builderConfig.fields).forEach((key: string) => {
      const field = this.builderConfig.fields[key];
      operatorFields.push({
        fieldName: key,
        operators: field.operators.map((operator: string) => {
          return {
            name: Operator[operator],
            value: operator,
          };
        }),
      });
    });
    return operatorFields;
  }

  initializeQueryBuilder(): void {
    this.builderConfig = undefined;
    this.queryModel = undefined;
    const builderConfig = {
      fields: {
        content: {
          name: 'Content',
          type: 'textarea',
          operators: [
            DTOQueryConditionOperator.contains,
            DTOQueryConditionOperator['doesnotcontain'],
          ],
        },
      },
    };
    this.dataSource.forEach((item: Category) => {
      builderConfig.fields[item.name] = {
        name: item.name,
        type: DTOQueryFieldType.tag,
        operators: [
          DTOQueryConditionOperator.in,
          DTOQueryConditionOperator['notin'],
        ],
        value: item.name,
      };
    });
    this.builderConfig = builderConfig;
    this.queryModel = new Query(
      [
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [],
        ),
      ],
      QueryType.QUERY_BUILDER,
    );
  }

  public onGetTags(rule: Rule, params: QueryFilters): void {
    const rules = this.getRules();
    const query = new Query(rules, QueryType.QUERY_BUILDER);
    query.query.condition = this.queryModel.query.condition;
    const queryModel = query.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    this.categoryService
      .getTags(
        params.query,
        params,
        queryModel.modelToDTO(),
        rule.field as TagCategory,
        this.userService.userPreferences.defaultEcosystemId,
        params.page > 1
          ? this.tagsDataSource[this.tagsDataSource.length - 1].name
          : undefined,
      )
      .then((response: Array<ICustomTag>) => {
        this.tagsDataSource =
          params.page > 1 ? [...this.tagsDataSource, ...response] : response;
      });
  }

  private getRules(): Array<any> {
    return this.queryModel.getRules().map((rule: any) => {
      return this.formatRules(rule);
    });
  }

  private formatRules(rule: any): any {
    if (rule.condition) {
      return {
        condition: rule.condition,
        rules: rule.rules.map((item: any) => {
          return this.formatRules(item);
        }),
      };
    } else {
      return new QueryRule(
        rule.field,
        rule.operator,
        Array.isArray(rule.value)
          ? rule.value?.map((item: ICustomTag) => item.id)
          : rule.value,
      );
    }
  }

  onChange(): void {
    const rules = this.getRules();
    const query = new Query(
      rules,
      QueryType.QUERY_BUILDER,
      this.queryModel.selections,
    );
    query.query.condition = this.queryModel.query.condition;
    this.dataBound.emit(query);
  }

  private changeRuleSetCondition(model: any, value: string): void {
    model.condition = value;
  }

  orClick(ruleset: RuleSet): void {
    this.changeRuleSetCondition(ruleset, DTOQueryOperator.or);
    if (this.queryModel.hasRules()) {
      this.onChange();
    }
  }

  andClick(ruleset: RuleSet): void {
    this.changeRuleSetCondition(ruleset, DTOQueryOperator.and);
    if (this.queryModel.hasRules()) {
      this.onChange();
    }
  }

  onChangeDataField(rule: Rule, avaialbleFields: any): void {
    rule.value = [];
    const field = avaialbleFields.find((item: any) => {
      return item.value === rule.field;
    });
    rule.value = [];
    const operatorIndex = field.operators.indexOf(rule.operator);
    rule.operator =
      operatorIndex > -1 ? field.operators[operatorIndex] : field.operators[0];
  }

  public getFieldOperators(field: string): Array<IOperator> {
    const operators = this.operatorFields.find((item: IOperatorList) => {
      return item.fieldName === field;
    });
    return operators?.operators;
  }

  public onAddTag(selection: ICustomTag, rule: Rule): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollingService.dataBoundObservable.next();
    });
    this.onGetTags(rule, this.initialState);
    const rules = this.getRules();
    const query = new Query(
      rules,
      QueryType.QUERY_BUILDER,
      this.queryModel.selections,
    );
    query.query.condition = this.queryModel.query.condition;
    query.addQueryBuilderSelection({
      section: this.dataSource.find(
        (item: Category) => item.name === rule.field,
      ).section,
      categoryName: rule.field as TagCategory,
      tagName: selection.name,
      tagId: selection.id,
      included: rule.operator === DTOQueryConditionOperator.in ? true : false,
      excluded:
        rule.operator === DTOQueryConditionOperator.notin ? true : false,
    });
    this.dataBound.emit(query);
  }

  public onChangeValue(selection: ICustomTag, rule: Rule): void {
    const selectionsExists = this.queryModel.selections?.find(
      (item: ITagMetadata) => item.tagId === selection.id,
    );
    if (selectionsExists) {
      this.onRemoveTag(selection, rule);
    } else {
      this.onAddTag(selection, rule);
    }
  }

  public onRemoveTag(selection: ICustomTag, rule: Rule): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollingService.dataBoundObservable.next();
    });
    this.onGetTags(rule, this.initialState);
    const rules = this.getRules();
    const query = new Query(
      rules,
      QueryType.QUERY_BUILDER,
      this.queryModel.selections,
    );
    query.query.condition = this.queryModel.query.condition;
    query.removeQueryBuilderSelection({
      section: this.dataSource.find(
        (item: Category) => item.name === rule.field,
      ).section,
      categoryName: rule.field as TagCategory,
      tagName: selection.name,
      tagId: selection.id,
      included: rule.operator === DTOQueryConditionOperator.in ? true : false,
      excluded:
        rule.operator === DTOQueryConditionOperator.notin ? true : false,
    });
    this.dataBound.emit(query);
  }

  public onClearFilters(): void {
    this.queryModel = new Query(
      [
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [],
        ),
      ],
      QueryType.QUERY_BUILDER,
    );
    this.clearFilters.emit(this.queryModel);
  }
}
