import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  IconType,
  PillType,
  QueryFilters,
  SharedService,
  Sizes,
} from '@intorqa-ui/core';
import { NetworkChart } from '@portal/boards/models/network-chart';
import { WidgetService } from '@portal/boards/services/widget.service';
import { IConnectionsData } from '@portal/profiles/interfaces/profile-connection.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ConnectionsService } from '@portal/profiles/services/connections.service';
import { ProfileService } from '@portal/profiles/services/profiles.service';
import { WidgetActions, ChartType } from '@portal/shared/enums/widget.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { ECharts } from 'echarts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-connections-network',
  templateUrl: './connections-network.component.html',
  styleUrls: ['./connections-network.component.scss'],
})
export class ConnectionsNetworkComponent implements OnInit {
  @Input() profile: Profile;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() form: FormGroup;
  @Input() action: WidgetActions;
  @Input() showConnectionSettings: boolean;

  public widget: NetworkChart;
  public data: IConnectionsData;
  private connectionsSubscription: Subscription;
  public initialState = new QueryFilters(30, 1, undefined, undefined, {
    direction: 'desc',
    active: 'updatedDate',
  });
  public chartInstance: ECharts;

  readonly WidgetActions = WidgetActions;
  readonly Sizes = Sizes;
  readonly PillType = PillType;

  constructor(
    private connectionsService: ConnectionsService,
    private widgetService: WidgetService,
    readonly profileService: ProfileService,
    readonly cdr: ChangeDetectorRef,
    readonly widgetSettingsService: WidgetSettingsService,
  ) {}

  ngOnInit(): void {
    this.connectionsSubscription =
      this.connectionsService.connections$.subscribe(
        (response: IConnectionsData) => {
          this.data = response;
          this.cdr.detectChanges();
          this.widgetService.reloadChart$.next(this.profile);
          this.widgetSettingsService.loader$.next(false);
        },
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.navigationItem?.previousValue !==
      changes?.navigationItem?.currentValue
    ) {
      this.widget = new NetworkChart(this.profile.name, ChartType.NETWORK);
      this.widgetSettingsService.loader$.next(true);
      this.connectionsService
        .getConnections(this.profile.profileId, this.initialState)
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.connectionsSubscription.unsubscribe();
  }

  public onToggleSettings(): void {
    this.connectionsService.showConnectionsSettings$.next(true);
  }

  public onExport(): void {
    this.widgetService.exportChart(
      `${this.profile.name}_connections`,
      this.chartInstance,
    );
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  public onDrilldown(params: any): void {
    this.profileService
      .getProfileById(params.data.profileId)
      .subscribe((response: Profile) => {
        this.navigationItem.selectedTabIndex = 1;
        const navigationItem = new ProfilesNavigationItem(
          `${WidgetActions.EXPLORE}_${response.profileId}`,
          response,
          WidgetActions.EXPLORE,
          undefined,
          new QueryFilters(30, 1, undefined, undefined, {
            direction: 'desc',
            active: 'updatedDate',
          }),
          new FormGroup({}),
          'file-invoice',
          IconType.FONT_AWESOME,
          {
            id: 'Timeline',
            type: ChartType.TIMELINE,
            svgIcon: 'board',
            tooltip: 'Timeline',
          },
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          0,
        );
        this.widgetService.drilldownObservable.next(navigationItem);
      });
  }
}
