import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  PillType,
  QueryFilters,
  Sections,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { Subscription } from 'rxjs';
import { ListSearch } from './list-search.model';

@Component({
  selector: 'itq-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss'],
  providers: [
    VirtualScrollService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ListSearchComponent,
    },
  ],
})
export class ListSearchComponent implements OnInit, OnDestroy {
  @Input() initialState: QueryFilters;
  @Input() query: Query;
  @Input() filter: string;
  @Input() dataSource: Array<ICustomTag>;
  @Input() disabled = false;
  @Input() section: Sections;
  @Input() showSelectionsLabel = true;
  @Input() category = TagCategory['My Tags'];

  @Output() search = new EventEmitter<QueryFilters>();
  @Output() changeValue = new EventEmitter<ITagMetadata>();

  @ViewChild('listSearch') listSearch: ElementRef;

  readonly Sizes = Sizes;
  readonly PillType = PillType;

  public list: ListSearch;
  private scrollTop = 0;
  private touched = false;
  private value: Array<ITagMetadata>;
  private subscriptions = new Subscription();

  constructor(
    private virtualScrollService: VirtualScrollService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.category?.previousValue !== changes?.category?.currentValue) {
      this.category = this.category;
      this.filter = undefined;
      this.onSearch();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  onChange = () => {};

  onTouched = () => {};

  writeValue(items: Array<ITagMetadata>): void {
    this.value = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public onSearch(): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    this.initialState.query = this.filter;
    this.search.emit(this.initialState);
  }

  public onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.listSearch.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          undefined,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.search.emit(this.initialState);
          }
        });
    }
  }

  public onChangeValue(value: ITagMetadata): void {
    this.changeValue.emit(value);
  }
}
