import { Pipe, PipeTransform } from "@angular/core";
import { Cheat_Reputation_Map } from '@portal/profiles/const/profiles.const';
import { MetadataFields } from '@portal/profiles/enums/profiles-metadata.enum';

@Pipe({
  name: "renderSingleMetadata",
})
export class RenderSingleMetadataPipe implements PipeTransform {
  transform(value: string, field: string): string {
    if (field === MetadataFields.CHEAT_REPUTATION_SCORE) {
      const map = Cheat_Reputation_Map.find(
        (item: { name: string; value: number }) =>
          item.value === parseInt(value)
      );
      return `${value} - ${map.name}`;
    } else {
      return value;
    }
  }
}
