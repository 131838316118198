import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ResearchNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
@Component({
  selector: "itq-notification-research-detail",
  templateUrl: "./notification-research-detail.component.html",
  styleUrls: ["./notification-research-detail.component.scss"],
  standalone: true,
})
export class NotificationResearchDetailComponent implements OnInit {
  @Input() notification: ResearchNotification;

  public safeHtml: SafeHtml;

  constructor(
    readonly notificationService: NotificationsService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
      this.notification.message
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.notification?.previousValue !==
      changes?.notification?.currentValue
    ) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
        this.notification.message
      );
    }
  }
}
