@if (board) {
  <itq-board-header
    class="p-7 flex flex-col"
    [board]="board"
    [form]="form"
  ></itq-board-header>

  <itq-board-toolbar
    [board]="board"
    [form]="form"
    [initialState]="initialState"
    (search)="onSearch($event)"
    (refresh)="onReloadDashboard()"
  ></itq-board-toolbar>
}

<div class="max-w-full p-7 overflow-auto w-full flex flex-1">
  <ktd-grid
    [cols]="cols"
    [rowHeight]="rowHeight"
    [compactType]="'horizontal'"
    [scrollableParent]="document"
    [layout]="layout"
    [preventCollision]="false"
    (layoutUpdated)="onLayoutUpdated($event)"
    [gap]="15"
  >
    @if (widgets?.length > 0) {
      @for (item of layout; track item.id; let i = $index) {
        <ktd-grid-item
          [id]="item.id"
          [draggable]="true"
          dragStartThreshold="0"
          [resizable]="true"
        >
          @if (widgets[i].type === AnalysisTypes.TIMELINE) {
            <itq-timeline-widget
              class="widget"
              [widget]="getTimelineWidget(i)"
              [board]="board"
              [action]="WidgetActions.RENDER"
              [query]="initialState?.query"
              (clone)="onCloneWidget($event)"
              (delete)="onDeleteWidget($event)"
            ></itq-timeline-widget>
          } @else {
            <itq-widget
              class="widget"
              [board]="board"
              [widget]="getWidget(i)"
              (delete)="onDeleteWidget($event)"
              (update)="onUpdateWidget($event)"
            ></itq-widget>
          }
          <div ktdGridDragHandle class="btn--drag" [matTooltip]="'Drag widget'">
            <fa-icon [icon]="['far', 'grip-vertical']"></fa-icon>
          </div>
        </ktd-grid-item>
      }
    } @else {
      @if (widgets?.length === 0) {
        <itq-panel-info
          [label]="'You don\'t have any widgets yet!!'"
          [description]="
            'Now that you have a dashboard, it\'s time to start adding widgets. 🎉'
          "
          [message]="
            'Click + Create Widget to start adding them to your dashboard.'
          "
          [icon]="'hands-helping'"
        ></itq-panel-info>
      }
    }
  </ktd-grid>
</div>
