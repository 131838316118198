import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayService,
  DateQueryType,
  DateRange,
  HeadLineSimpleComponent,
  IButtonThumbnail,
  IDropdownItem,
  IPreset,
  IPresetQuery,
  IconType,
  PillType,
  QueryFilters,
  SharedService,
  TableColumn,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { WidgetService } from '@portal/boards/services/widget.service';
import { Priority_DataSource } from '@portal/notifications/const/alerts.const';
import { AlertType } from '@portal/notifications/models/alert-type';
import {
  GroupNotification,
  Notification,
  ResearchNotification,
} from '@portal/notifications/models/notifications';
import { GroupByDatePipe } from '@portal/notifications/pipes/notifications.pipe';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ToolbarActions } from '@portal/shared/components/toolbar/toolbar.enum';
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import { ISearchResults } from '@portal/shared/interfaces/document.interface';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { Timeline } from '@portal/shared/models/timeline';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { NotificationNavigationItem } from '@portal/widget-settings/models/notification-navigation-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import moment from 'moment';
import {
  DTOQueryConditionOperator,
  DTOQueryFieldType,
} from 'projects/core/src/lib/enums/query.enum';
import { Subscription, forkJoin } from 'rxjs';
import { NotificationResearchDetailComponent } from '../notification-research-detail/notification-research-detail.component';
import { NotificationsTimelineComponent } from '../notifications-timeline/notifications-timeline.component';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { UserService } from '@portal/shared/services/user.service';

@Component({
  selector: 'itq-notifications-explore',
  templateUrl: './notifications-explore.component.html',
  styleUrls: ['./notifications-explore.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    NotificationsTimelineComponent,
    ReactiveFormsModule,
    SharedModule,
    MatTooltipModule,
    HeadLineSimpleComponent,
    NotificationResearchDetailComponent,
  ],
  providers: [NotificationsService, GroupByDatePipe, SharedService],
})
export class NotificationsExploreComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() articleDetail: ISegment;
  @Input()
  set navigationItem(value: NavigationHistoryItem) {
    this._navigationItem = value as NotificationNavigationItem;
  }

  get navigationItem(): NotificationNavigationItem {
    return this._navigationItem;
  }

  public _navigationItem: NotificationNavigationItem;
  public expandedFilters = true;
  public priorityDataSource: Array<IDropdownItem> = [];

  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
  );
  public loadOtherMatchesSubscription: Subscription;
  public buttonDataSource: Array<IButtonThumbnail>;
  public unreadCount: number;
  public notificationsDataSource: {
    items: Array<GroupNotification>;
    totalCount: number;
  };
  private loadNotificationsSubscription: Subscription;
  public raisedAlertId: string;
  private _researchNotification: ResearchNotification;

  public get researchNotification(): ResearchNotification {
    return this._researchNotification;
  }

  public set researchNotification(value: ResearchNotification) {
    this._researchNotification = value;
  }
  public tableColumns: TableColumn[] = [];
  public matchesDataSource: ISearchResults;
  public toolbarActions = [
    {
      action: ToolbarActions.DATE,
    },
  ];
  private createProfileSubscription: Subscription;
  public alertTypesDataSource: Array<AlertType> = [];
  private getUnreadCountSubscription: Subscription;
  public loadNotificationDetailSubscription: Subscription;

  @ViewChild('unreadTemplate')
  unreadTemplate: TemplateRef<unknown>;

  @ViewChild('template')
  template: TemplateRef<unknown>;

  readonly PillType = PillType;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly groupByDate: GroupByDatePipe,
    readonly widgetService: WidgetService,
    readonly alertsService: AlertsService,
    readonly virtualScrollService: VirtualScrollService,
    readonly widgetSettingsService: WidgetSettingsService,
    readonly userService: UserService,
  ) {
    this.priorityDataSource = Priority_DataSource;
  }

  ngOnInit() {
    this.loadNotificationDetailSubscription =
      this.notificationsService.loadNotificationDetail$.subscribe(
        (response: ResearchNotification) => {
          this.researchNotification = response;
          this.raisedAlertId = undefined;
        },
      );
    this.alertsService.getTypes().subscribe((response: Array<AlertType>) => {
      this.alertTypesDataSource = response;
    });
    this.raisedAlertId = this.navigationItem.raisedAlertId;
    this.getUnreadCountSubscription =
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount = response;
          this.buttonDataSource = this.buttonDataSource.map(
            (item: IDisplayType) => {
              if (item.id === 'unread') {
                if (this.unreadCount === 0) {
                  item = { ...item, ...{ disabled: true } };
                } else {
                  delete item.disabled;
                }
                return item;
              } else {
                return item;
              }
            },
          );
        },
      );
    this.createProfileSubscription =
      this.notificationsService.createProfile$.subscribe(
        (params: {
          profileDrilldown: ProfileDrilldown;
          notification: Notification;
        }) => {
          this.createProfile(params);
        },
      );
    this.loadNotificationsSubscription =
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          this.notificationsDataSource = {
            items: this.groupByDate.transform(response.items),
            totalCount: response.totalCount,
          };
          if (this.navigationItem.researchAlertId) {
            this.notificationsService
              .getNotificationById(this.navigationItem.researchAlertId)
              .subscribe((response: ResearchNotification) => {
                this.researchNotification = response;
              });
          }
          this.widgetSettingsService.loader$.next(false);
        },
      );
    this.loadOtherMatchesSubscription =
      this.notificationsService.loadOtherMatches$.subscribe(
        (response: { filters: QueryFilters; raisedAlertId: string }) => {
          this.raisedAlertId = response.raisedAlertId;
          this.researchNotification = undefined;
          this.loadMatches();
        },
      );
    this.createForm();

    this.onDataBound();

    this.loadMatches();
  }

  ngAfterViewInit(): void {
    this.tableColumns = [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
    this.buttonDataSource = [
      {
        id: 'read',
        tooltip: 'All',
      },
      {
        id: 'unread',
        tooltip: 'Unread',
        template: this.unreadTemplate,
      },
    ];
  }

  ngOnDestroy(): void {
    this.loadNotificationsSubscription.unsubscribe();
    this.loadOtherMatchesSubscription.unsubscribe();
    this.createProfileSubscription.unsubscribe();
    this.getUnreadCountSubscription.unsubscribe();
    this.loadNotificationDetailSubscription.unsubscribe();
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const notification = this.notificationsService.findNotificationById(
      this.raisedAlertId,
    );
    this.createProfile({ profileDrilldown, notification });
  }

  private createProfile(params: {
    profileDrilldown: ProfileDrilldown;
    notification: Notification;
  }): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      params.profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      this.userService.userPreferences.defaultEcosystemId,
      params.profileDrilldown.profileType.id,
      params.profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        params.profileDrilldown.value,
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? params.profileDrilldown.value
          : `Field field:${params.profileDrilldown.value}:${
              params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private loadMatches(): void {
    if (this.raisedAlertId) {
      this.notificationsService
        .getMatches(
          new QueryFilters(30, 1, undefined, undefined, undefined),
          this.raisedAlertId,
        )
        .subscribe((matches: ISearchResults) => {
          this.matchesDataSource = matches;
        });
    }
  }

  public createForm(): void {
    this.form.addControl('alertType', new FormControl(undefined));
    this.form.addControl('priority', new FormControl(undefined));
    this.form.addControl('showAll', new FormControl('read'));
    this.form.addControl('query', new FormControl(undefined));
  }

  public onDataBound(params?: QueryFilters): void {
    if (params) {
      this.initialState = params;
    }
    this.initialState.query = this.form.get('query').value;
    this.widgetSettingsService.loader$.next(true);
    const getNotificationsObservable =
      this.notificationsService.getNotifications(
        params || this.initialState,
        this.form.get('showAll').value === 'unread' ? false : undefined,
        this.form.get('priority').value,
        this.form.get('alertType').value,
      );
    const getUnreadCountObservable =
      this.notificationsService.getUnreadNotificationsCount(
        this.initialState,
        this.form.get('priority').value,
      );

    forkJoin([getNotificationsObservable, getUnreadCountObservable]).subscribe(
      (response) => {
        this.unreadCount = response[1];
        this.buttonDataSource = this.buttonDataSource.map(
          (item: IDisplayType) => {
            if (item.id === 'unread') {
              if (this.unreadCount === 0) {
                item = { ...item, ...{ disabled: true } };
              } else {
                delete item.disabled;
              }
              return item;
            } else {
              return item;
            }
          },
        );
      },
    );
  }

  public onSearch(): void {
    this.raisedAlertId = undefined;
    this.researchNotification = undefined;
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
      this.onDataBound(this.initialState);
    });
  }

  public onDrilldownMatches(segment: ISegment): void {
    const notification = this.notificationsService.findNotificationById(
      this.raisedAlertId,
    );
    this.onDrilldown(segment, notification);
  }

  public onDrilldown(segment?: ISegment, notification?: Notification): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment, notification);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment, notification);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.widgetService.updateSegmentObservable.next(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment, notification);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment, notification);
        break;
      case SegmentScope.REPLIES:
        this.drilldownReplies(segment, notification);
      default:
        break;
    }
  }

  private drilldownContext(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      segment,
      undefined,
      segment.context,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownReplies(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitType === 'Comment'
        ? `${segment.value.emitType} by ${segment.value.emitActor} `
        : `Replies of ${segment.value.emitHeadline}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.id}:ReplyTo`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'file',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownDiscord(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onDrilldownDocumentDetail(segment?: ISegment): void {
    const notification = this.notificationsService.findNotificationById(
      this.navigationItem.raisedAlertId,
    );
    this.onDrilldown(segment, notification);
  }

  private drilldownChannel(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment, notification: Notification): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onDateRangeChange(args: IPreset): void {
    let payload: IPresetQuery;
    if (args.start) {
      payload = this.getPayload(args);
    }
    this.initialState.where = payload;

    this.onSearch();
  }

  private getPayload(dates: IPreset): IPresetQuery {
    return {
      label: DateRange.getDateQueryText(dates.label),
      start: DateRange.convertToEpochSec(moment(dates.start).toDate()),
      end:
        dates.label === DateQueryType.Custom
          ? DateRange.convertToEpochSec(moment(dates.end).toDate())
          : undefined,
    };
  }
}
