import { Routes } from '@angular/router';
import { ErrorComponent } from '@intorqa-ui/core';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { ProfilesResolver } from './profiles/profiles.resolver';
import { BoardsComponent } from './boards/components/boards/boards.component';
import { PageDocumentComponent } from './document/page-document.component';
import { BoardComponent } from './boards/components/board/board.component';
import { TagsComponent } from './tags/tags.component';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'boards', pathMatch: 'full' },
  {
    path: 'boards',
    component: BoardsComponent,
    children: [
      {
        path: '',
        component: BoardComponent,
      },
      {
        path: ':id',
        component: BoardComponent,
      },
    ],
  },
  {
    path: 'document/:id',
    component: PageDocumentComponent,
  },
  {
    path: 'tags',
    component: TagsComponent,
  },
  {
    path: 'profiles',
    resolve: { resolveProfiles: ProfilesResolver },
    canActivate: [AuthGuardService],
    data: { roles: ['saas-profiles'] },
    loadChildren: () =>
      import('./profiles/profiles.module').then((m) => m.ProfilesModule),
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];
