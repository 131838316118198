<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
>
  <div class="flex flex-col overflow-hidden">
    <div class="flex justify-between p-3">
      <div class="flex items-center overflow-hidden">
        <span class="pl-5 text-17 font-semibold text-md overflow-ellipsis overflow-hidden whitespace-nowrap" [matTooltip]="widget?.name">
          {{ widget?.name }}
        </span>
        @if(widget?.description) {
        <fa-icon
          class="ml-1"
          [icon]="['far', 'circle-info']"
          [matTooltip]="widget.description"
        ></fa-icon>
        }
      </div>
      <div class="flex items-center">
        @if(data?.count > 0) {
        <itq-pill
          class="mr-1"
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md '"
          >{{ data?.count | number }}</itq-pill
        >
        }

        @if (timelineStatus === TimelineStatus.IN_PROGRESS) {
          <fa-icon
            class="mr-2 ml-2"
            [matTooltip]="
              'Tagging in progress, please refresh to see new content'
            "
            [icon]="['far', 'tags']"
            [size]="'lg'"
          ></fa-icon>
        }
        <itq-nav-button
          class="mr-1"
          [padding]="Sizes['X-SM']"
          [width]="170"
          [openDirection]="{ vertical: 'down', horizontal: 'right' }"
          [openPosition]="'inside'"
          [template]="displayTypesTemplate"
          [templateData]="widget"
          [disabled]="data?.count === 0"
          [materialStyle]="'basic'"
          [matTooltipPosition]="'above'"
          [matTooltip]="'Change display type'"
        >
          @if (selectedDisplayType?.icon) {
            <fa-icon [size]="'lg'" [icon]="selectedDisplayType?.icon"></fa-icon>
          } @else {
            <itq-svg
              [icon]="selectedDisplayType?.svgIcon"
              class="w-6"
            ></itq-svg>
          }
        </itq-nav-button>
        <itq-nav-button
          [padding]="Sizes['X-SM']"
          [width]="200"
          [openDirection]="{ vertical: 'down', horizontal: 'right' }"
          [openPosition]="'inside'"
          [template]="widgetMoreTemplate"
          [templateData]="ProfileDrildownScope.TAG"
          [materialStyle]="'basic'"
          [matTooltip]="'More options'"
          [matTooltipPosition]="'above'"
        >
          <fa-icon [icon]="['far', 'gear']" [size]="'lg'"></fa-icon>
        </itq-nav-button>
      </div>
    </div>
  </div>
  <div
    class="flex flex-1 overflow-hidden w-full border-t border-border"
    #chartContainer
  >
    @if (timelineStatus) {
      @if (selectedDisplayType.type === ChartType.TABLE) {
        <div class="chart__wrapper">
          @if (widgetData?.series[0]?.data) {
            <itq-table
              class="p-4 overflow-hidden h-full"
              [tableData]="widgetData?.series[0]?.data"
              [tableColumns]="tableColumns"
              [trackBy]="'tagId'"
            >
            </itq-table>
          }
        </div>
      } @else {
        <div class="chart__wrapper">
          @if (
            widget &&
            (timelineStatus === TimelineStatus.COMPLETED ||
              (timelineStatus === TimelineStatus.IN_PROGRESS &&
                data?.count > 0))
          ) {
            <itq-timeline-feed
            class="h-full"
              [widget]="widget"
              [data]="data"
              [initialState]="initialState"
              [segment]="segment"
              [query]="query"
              (scroll)="onDataBound($event)"
              (drilldown)="onDrilldown($event)"
              (createProfile)="onCreateProfile($event)"
            ></itq-timeline-feed>
          } @else {
            @if (timelineStatus === TimelineStatus.IN_PROGRESS) {
              <itq-panel-info
                [label]="noContentTitle"
                [message]="noContentMessage"
                [icon]="'exclamation-circle'"
              ></itq-panel-info>
            }
          }
        </div>
      }
    } @else {
      @if (timelineStatus === TimelineStatus.IN_PROGRESS) {
        <itq-panel-info
          [label]="noContentTitle"
          [message]="noContentMessage"
          [icon]="'exclamation-circle'"
        ></itq-panel-info>
      }
    }
  </div>
  <footer class="flex p-4 border-t border-border">
    <itq-mat-button
      [matTooltip]="'Scroll to top'"
      [disabled]="data?.count === 0 || widgetData?.totalHits === 0"
      [padding]="Sizes.NONE"
      [type]="'button'"
      [materialStyle]="'stroked'"
      (mousedown)="scrollToTop()"
    >
      <fa-icon [icon]="['far', 'chevron-up']" [size]="'lg'"></fa-icon>
    </itq-mat-button>
    <itq-mat-button
      class="flex-1 ml-2"
      [size]="Sizes.BLOCK"
      [disabled]="data?.count === 0 || widgetData?.totalHits === 0"
      [type]="'button'"
      [materialStyle]="'flat'"
      [style]="'primary'"
      (mouseDownEvent)="onExplore()"
    >
      EXPLORE
    </itq-mat-button>
  </footer>
</div>

<itq-loader *ngIf="showLoader"></itq-loader>

<ng-template #widgetMoreTemplate let-data>
  <div
    class="menu__container"
    (mouseleave)="showNestedNavbar = false; showListProfile = false"
  >
    <div class="menu__wrapper">
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [disabled]="this.widget | isWidgetDisabled"
        [matTooltipPosition]="'right'"
        [matTooltip]="this.widget | getWidgetTooltip"
        (mouseDownEvent)="onEdit()"
        (mouseover)="showNestedNavbar = false; showListProfile = false"
      >
        <fa-icon [icon]="['far', 'pencil']"></fa-icon>
        Edit
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [disabled]="this.widget | isWidgetDisabled"
        [matTooltipPosition]="'right'"
        [matTooltip]="this.widget | getWidgetTooltip"
        (mouseDownEvent)="onExplore()"
        (mouseover)="showNestedNavbar = false; showListProfile = false"
      >
        <fa-icon [icon]="['far', 'expand-wide']"></fa-icon>
        Explore
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [disabled]="this.widget | isWidgetDisabled"
        [matTooltip]="
          (this.widget | isWidgetDisabled)
            ? 'You don\'t have permissions to clone this tag'
            : undefined
        "
        [matTooltipPosition]="'right'"
        (mouseDownEvent)="onClone()"
        (mouseover)="showNestedNavbar = false; showListProfile = false"
      >
        <fa-icon [icon]="['far', 'clone']"></fa-icon>
        Clone
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (mouseDownEvent)="onDeleteWidget(widget)"
        (mouseover)="showNestedNavbar = false; showListProfile = false"
      >
        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        Remove
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [cdkCopyToClipboard]="tagShareLink"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onShareLink()"
        (mouseover)="showNestedNavbar = false; showListProfile = false"
      >
        <fa-icon [icon]="['far', 'link']"></fa-icon>
        Share link
      </itq-mat-button>
      <itq-mat-button
        *ngIf="keycloakService.isUserInRole('saas-profiles')"
        [size]="Sizes.BLOCK"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (mouseover)="showNestedNavbar = true; showListProfile = false"
      >
        <fa-icon
          [icon]="['far', showNestedNavbar ? 'chevron-left' : 'chevron-down']"
        ></fa-icon>
        Create new profile
      </itq-mat-button>
      @if (keycloakService.isUserInRole('saas-profiles')) {
        <itq-mat-button
          [size]="Sizes.BLOCK"
          [type]="'button'"
          [align]="Align.START"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          (mouseover)="onMouseOverLinkProfile()"
        >
          <fa-icon
            [icon]="['far', showListProfile ? 'chevron-right' : 'chevron-down']"
          ></fa-icon>
          Add existing profile
        </itq-mat-button>
      }
    </div>
    @if (showListProfile) {
      <div class="menu__sidebar">
        <itq-dropdown-results
          [dataSource]="profilesDataSource"
          [httpBinding]="true"
          [dataFields]="{ name: 'name', value: 'profileId' }"
          [initialState]="profileInitialState"
          (dataBound)="onProfileDataBound($event)"
          (changeValue)="onLinkProfile($event)"
        ></itq-dropdown-results>
      </div>
    }
    @if (showNestedNavbar) {
      <div class="menu__sidebar" [ngStyle]="{ width: '170px' }">
        @for (item of profileTypesDataSource; track item.id) {
          <itq-mat-button
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            [type]="'button'"
            [materialStyle]="'basic'"
            [padding]="Sizes['X-SM']"
            (clickEvent)="onCreateProfileTag(data, item)"
          >
            <fa-icon [icon]="['far', item.name | getProfileTypeIcon]"></fa-icon>
            <span>
              {{ item.name }}
            </span>
          </itq-mat-button>
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #displayTypesTemplate>
  <div class="displayTypes__container">
    @for (item of displayTypes; track item.id) {
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [align]="Align.START"
        [padding]="Sizes['X-SM']"
        [materialStyle]="'basic'"
        (clickEvent)="onChangeDisplay(item)"
      >
        <div
          [ngClass]="{
            'fill-primary': item.id === selectedDisplayType.id,
            'text-primary': item.id === selectedDisplayType.id,
            flex: true,
            'items-center': true
          }"
        >
          <div class="display_type__icon">
            @if (item.icon) {
              <fa-icon class="mr-1" [icon]="item.icon"></fa-icon>
            } @else {
              <itq-svg [icon]="item.svgIcon" class="w-4 mr-1"></itq-svg>
            }
          </div>
          {{ item.tooltip }}
        </div>
      </itq-mat-button>
    }
  </div>
</ng-template>

<ng-template #countTemplate let-row>
  <button
    type="button"
    class="btn btn-link-secondary"
    (click)="onLoadCount(row)"
  >
    {{ row.count }}
  </button>
</ng-template>
