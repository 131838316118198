@if (data) {
  <itq-virtual-table
    [initialState]="initialState"
    [tableColumns]="tableColumns"
    [headerVisible]="false"
    [theme]="'tile'"
    [dataSource]="data?.result"
    [totalCount]="data?.count"
    [active]="segment?.value?.id"
    [trackBy]="'id'"
    [emptyMessageDescription]="'Please update your filters and try again'"
    (dataBound)="onDataBound($event)"
  ></itq-virtual-table>
}

<ng-template #template let-data>
  <itq-document-item-tile
    [item]="data"
    [allowDrilldown]="allowDrilldown"
    [segment]="segment"
    [query]="query"
    [form]="form"
    (drilldown)="onDrilldown($event)"
    (createProfile)="onCreateProfile($event)"
    (addNote)="onAddNote($event)"
    (addConnection)="onAddConnection($event)"
  ></itq-document-item-tile>
</ng-template>
