<fa-icon
  class="history__icon"
  [icon]="['far', 'history']"
  [size]="'4x'"
></fa-icon>
<div class="history__container">
  <ng-container *ngFor="let item of dataSource; let i = index">
    <itq-mat-button
      [ngClass]="{
        btn__item: true,
        active: this.selected.id === item.id ? true : false
      }"
      [size]="Sizes.BLOCK"
      [padding]="Sizes.NONE"
      [materialStyle]="'basic'"
      (clickEvent)="onLoadHistory(item)"
    >
      <div class="navigation__item__container">
        <div class="navigation__item__wrapper">
          <ng-container
            *ngIf="
              item?.iconType === IconType.SVG;
              else fontAwesomeIconTemplate
            "
          >
            <itq-svg class="w-5" [icon]="item.icon | getSVGIcon"></itq-svg>
          </ng-container>
          <ng-template #fontAwesomeIconTemplate>
            <fa-icon
              class="container__icon"
              [icon]="item.icon | getFontAwesomeIcon"
            ></fa-icon>
          </ng-template>
          <span class="text-wrapper">
            {{ item.item[this.dataFields.name] || "Create tag" }}
          </span>
        </div>
        <itq-mat-button
          *ngIf="i > 0"
          class="btn__delete"
          [materialStyle]="'basic'"
          [padding]="Sizes.NONE"
          [type]="'button'"
          [matTooltip]="'Delete'"
          (mouseDownEvent)="onDelete(item)"
        >
          <fa-icon [icon]="['far', 'times']" [size]="'lg'"></fa-icon>
        </itq-mat-button>
      </div>
    </itq-mat-button>
  </ng-container>
</div>
