import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CoreModule, CustomOverlayRef, FAwesomeModule } from '@intorqa-ui/core';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import { WidgetSettingsService } from '../widget-settings/widget-settings.service';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { ChartWizardComponent } from '@portal/widget-settings/modules/widget-settings-chart/chart-wizard/components/chart-wizard/chart-wizard.component';
import { WidgetTypeThumbnailListComponent } from '../widget-type-thumbnail-list/widget-type-thumbnail-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserService } from '@portal/shared/services/user.service';

@Component({
  selector: 'itq-create-widget-type',
  templateUrl: './create-widget-type.component.html',
  styleUrls: ['./create-widget-type.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    WidgetTypeThumbnailListComponent,
    MatTooltipModule,
    FAwesomeModule,
  ],
})
export class CreateWidgetTypeComponent implements OnInit {
  readonly AnalysisTypes = AnalysisTypes;

  constructor(
    readonly widgetSettingsService: WidgetSettingsService,
    readonly customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
  ) {}

  ngOnInit() {}

  public onAdd(type: AnalysisTypes): void {
    this.widgetSettingsService.navigate$.next({
      component: ChartWizardComponent,
      inputs: {
        widget: WidgetFactory.createObjectByType(
          type,
          this.userService.userPreferences.defaultEcosystemId,
        ),
      },
      outputs: {
        close: () => {
          this.widgetSettingsService.navigate$.next({
            component: CreateWidgetTypeComponent,
          });
        },
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
