<ng-container [formGroup]="form">
  <div
    class="notes__toolbar"
    *ngIf="navigationItem.action !== WidgetActions.CREATE"
  >
    <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-md'">{{
      dataSource?.totalCount | number
    }}</itq-pill>
    <div class="toolbar__wrapper">
      <itq-button-toggle
        class="collape__btn"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        [activeTooltip]="'Collapse filters'"
        [inactiveTooltip]="'Expand filters'"
        (clickEvent)="isFilterable = !isFilterable"
      >
        <fa-icon class="mr-1" [icon]="['far', 'filter-list']"></fa-icon>
        <span class="text-wrapper">Filters</span>
      </itq-button-toggle>
      <itq-mat-button
        [padding]="Sizes.SM"
        [type]="'button'"
        [materialStyle]="'basic'"
        (clickEvent)="onAddNote()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="text-wrapper">Add note</span>
      </itq-mat-button>
    </div>
  </div>
  <div class="notes__body">
    <itq-virtual-table
      *ngIf="
        dataSource?.items &&
        (navigationItem.action !== WidgetActions.CREATE
          ? true
          : action
            ? false
            : true)
      "
      [ngStyle]="{
        flex: navigationItem.action !== WidgetActions.CREATE ? 1 : undefined
      }"
      [isFilterable]="isFilterable"
      [tableColumns]="tableColumns"
      [footerTemplate]="
        navigationItem.action === WidgetActions.CREATE
          ? footerTemplate
          : undefined
      "
      [initialState]="initialState"
      [dataSource]="dataSource?.items | transformNotes"
      [totalCount]="dataSource?.totalCount"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t added any notes yet!'"
      [emptyMessageDescription]="'To add a note click the button below.'"
      (dataBound)="onDataBound($event)"
      (rowClick)="onViewNote($event)"
      (sort)="onDataBound(this.initialState)"
    ></itq-virtual-table>
    <ng-template #footerTemplate>
      <itq-mat-button
        [padding]="Sizes.SM"
        [type]="'button'"
        [materialStyle]="'basic'"
        (clickEvent)="onAddNote()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="text-wrapper">Add note</span>
      </itq-mat-button>
    </ng-template>

    <ng-template #valueTemplate let-row>
      <div class="flex items-center">
        <span
          class="mr-2 mt-3 mb-3"
          [innerHtml]="row?.rawValue | urlify"
          (click)="$event.stopImmediatePropagation()"
        ></span>
      </div>
    </ng-template>

    <ng-template #notesTemplate let-row>
      <div class="flex items-center">
        <span
          class="mr-2 mt-3 mb-3"
          [innerHtml]="row?.rawTextNote | urlify"
          (click)="$event.stopImmediatePropagation()"
        ></span>
        <fa-icon *ngIf="row.documentId" [icon]="['far', 'file']"></fa-icon>
      </div>
    </ng-template>

    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [openDirection]="{ vertical: 'down', horizontal: 'left' }"
        [openPosition]="'inside'"
        [materialStyle]="'basic'"
        [padding]="Sizes.NONE"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [width]="72"
        [template]="actionsTemplate"
      >
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <itq-mat-button
          [materialStyle]="'basic'"
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          (clickEvent)="onViewNote(row)"
        >
          <fa-icon [icon]="['far', 'eye']" class="mr-2" [size]="'lg'"></fa-icon>
          View
        </itq-mat-button>
        <itq-mat-button
          [materialStyle]="'basic'"
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          (clickEvent)="onEditNote(row)"
        >
          <fa-icon
            [icon]="['far', 'pencil']"
            class="mr-2"
            [size]="'lg'"
          ></fa-icon>
          Edit
        </itq-mat-button>
        <itq-mat-button
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onDeleteNote(row)"
        >
          <fa-icon
            [icon]="['far', 'trash-alt']"
            class="mr-2"
            [size]="'lg'"
          ></fa-icon>
          Delete
        </itq-mat-button>
      </ng-template>
    </ng-template>

    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>

    <ng-container *ngIf="action">
      <itq-add-notes
        class="overflow-auto"
        [ngStyle]="{
          width:
            navigationItem.action !== WidgetActions.CREATE ? '380px' : undefined
        }"
        [ngClass]="{ 'ml-4': navigationItem.action === WidgetActions.EXPLORE }"
        [showTitle]="showTitle"
        [form]="form"
        [action]="action"
        [note]="note"
        [navigationItem]="navigationItem"
        [profile]="profile"
      ></itq-add-notes>
    </ng-container>
    <ng-container *ngIf="note && !action">
      <itq-post-notes
        [segment]="segment"
        [navigationItem]="navigationItem"
        [note]="note"
      ></itq-post-notes>
    </ng-container>
  </div>
</ng-container>
