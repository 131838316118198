<div class="flex flex-col my-4">
  <div class="flex mb-4 flex-col">
    <span class="font-medium pr-2">Headline:</span>
    {{ notification?.headline }}
  </div>
  <div class="flex mb-4 flex-col">
    <span class="font-medium pr-2">Message:</span>
    <div [innerHTML]="safeHtml"></div>
  </div>
</div>
