import { Pipe, PipeTransform } from "@angular/core";
import { DateRange } from "@intorqa-ui/core";
import { GroupNotification, Notification } from "../models/notifications";

@Pipe({
  name: "groupByDate",
  standalone: true,
})
export class GroupByDatePipe implements PipeTransform {
  constructor() {}

  transform(
    dataSource: Array<Notification>
  ): Array<GroupNotification> | undefined {
    if (!dataSource) return undefined;
    let results: Array<{
      date: string;
      notifications: Array<Notification>;
    }> = [];
    dataSource.forEach((item: Notification) => {
      const relativeDate = DateRange.getRelativeDate(item.createdDate);
      const itemExists = results?.find(
        (date: GroupNotification) => date.date === relativeDate
      );
      if (!itemExists) {
        results.push({ date: relativeDate, notifications: [item] });
      } else {
        itemExists.notifications = [...itemExists.notifications, item];
      }
    });
    return results;
  }
}
