<ng-container *ngIf="!showConnectionSettings; else settingsTemplate">
  <div class="widget_settings__toolbar">
    <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-sm'">{{
      data?.totalCount | number
    }}</itq-pill>
    <div class="content__switch">
      <itq-mat-button
        class="mr-2"
        [type]="'button'"
        [style]="'primary'"
        [materialStyle]="'flat'"
        [padding]="Sizes.SM"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        <span class="text-wrapper">View content</span>
      </itq-mat-button>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onToggleSettings()"
      >
        <fa-icon class="mr-2" [icon]="['far', 'cog']"></fa-icon>
        <span class="text-wrapper">Settings</span>
      </itq-mat-button>
    </div>
    <div class="toolbar__wrapper">
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onExport()"
      >
        <fa-icon [icon]="['far', 'download']"></fa-icon>
        <span class="text-wrapper">Export</span>
      </itq-mat-button>
    </div>
  </div>
  <itq-chart
    [widget]="widget"
    [data]="data | getNetworkGraphData: profile"
    [action]="WidgetActions.EXPLORE"
    [noResultsTitle]="'You haven\'t added any connections yet!'"
    [noResultsMessage]="'To add a connection click the button below.'"
    (updateRef)="onUpdateRef($event)"
    (drilldown)="onDrilldown($event)"
  ></itq-chart>
</ng-container>
<ng-template #settingsTemplate>
  <itq-profiles-connections
    [profile]="profile"
    [navigationItem]="navigationItem"
    [form]="form"
    [action]="action"
  ></itq-profiles-connections>
</ng-template>
