<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'boardHeader'">
    <div
      class="rounded-md border border-border bg-white flex w-full items-center justify-center p-7"
    >
      <div class="flex items-center flex-1">
        <fa-icon
          class="pr-2"
          [icon]="['far', 'chart-column']"
          [size]="'3x'"
        ></fa-icon>

        <itq-input
          class="board__name"
          [formControlName]="'name'"
          [matTooltip]="'Please click to edit'"
          [placeholder]="'Please type to add board name'"
          (blurEvent)="onUpdateName()"
          (mouseDownEvent)="$event.stopImmediatePropagation()"
        ></itq-input>
        @if (board?.defaultBoard) {
          <fa-icon
            class="ml-4"
            [icon]="['far', 'star']"
            class="text-primary"
            [size]="'lg'"
          ></fa-icon>
        } @else {
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            [type]="'button'"
            [matTooltip]="'Set as default'"
            (clickEvent)="onToggleDefault()"
          >
            <fa-icon [icon]="['far', 'star']" [size]="'lg'"></fa-icon>
          </itq-mat-button>
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            [type]="'button'"
            [matTooltip]="'Delete Board'"
            (clickEvent)="onDeleteBoard()"
          >
            <fa-icon [icon]="['far', 'trash-alt']" [size]="'lg'"></fa-icon>
          </itq-mat-button>
        }
      </div>
      <itq-input
        class="flex-1 board__description"
        [formControlName]="'description'"
        [matTooltip]="'Please click to edit'"
        [placeholder]="'Please type to add board description'"
        (blurEvent)="onUpdateDescription()"
        (mouseDownEvent)="$event.stopImmediatePropagation()"
      ></itq-input>
    </div>
  </ng-container>
</ng-container>
