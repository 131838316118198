import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Category,
  CoreModule,
  DateQueryType,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { TagComparison } from '@portal/boards/models/tag-comparison';
import { TimeSeries } from '@portal/boards/models/time-series';
import {
  DTOQueryFieldType,
  DTOQueryConditionOperator,
} from 'projects/core/src/lib/enums/query.enum';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { FilterTagsPipe } from '../chart-wizard/chart-wizard.pipe';
import moment from 'moment';

@Component({
  selector: 'itq-specific-tag-datasource',
  templateUrl: './specific-tag-datasource.component.html',
  styleUrls: ['./specific-tag-datasource.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CoreModule, FilterTagsPipe],
})
export class SpecificTagDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() widget: TimeSeries | TagComparison;

  @Output() changeDataValue = new EventEmitter<Array<string>>();
  @Output() changeDataSource = new EventEmitter<Array<string>>();
  @Output() changeDataType = new EventEmitter<TagCategory>();

  public dataPointsDataSource: Array<ICustomTag>;
  public tagsDataSource: Array<ICustomTag> = [];
  public categoriesDataSource: Array<Category>;
  public dataValues: Array<any> = [];
  public tag: Timeline;
  private dataSourcesInitialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
  );

  private dataPointsInitialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
  );

  readonly TagCategory = TagCategory;

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.onDataBoundDataSources(this.dataSourcesInitialState);
    if (this.widget.widgetId) {
      this.getCategories();
      this.getSelections();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
    this.form.removeControl('category');
    this.form.removeControl('dataPoints');
  }

  private getSelections(): void {
    let tagIds = this.widget.getTagIds();
    let fieldFilterIds = this.widget.getFieldFilterTagIds();
    this.tagService
      .getSelections(
        tagIds,
        fieldFilterIds,
        undefined,
        this.categoryService.categories,
      )
      .then((response: Array<ITagMetadata>) => {
        this.dataValues = response.map((item: ITagMetadata) => ({
          name: item.tagName,
          id: item.tagId,
        }));
        this.onDataBoundDataValues(this.dataPointsInitialState);
      });
  }

  public onDataBoundDataSources(queryFilters: QueryFilters): void {
    this.dataSourcesInitialState = queryFilters;
    this.categoryService
      .getTags(
        undefined,
        this.dataSourcesInitialState,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        this.dataSourcesInitialState.page > 1
          ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1].name
          : undefined,
      )
      .then((response: Array<ICustomTag>) => {
        this.tagsDataSource =
          this.dataSourcesInitialState.page === 1
            ? response
            : [...this.tagsDataSource, ...response];
        if (this.widget.dataSource?.length > 0) {
          this.tagService
            .getTagById(this.widget.dataSource[0])
            .then((tag: Timeline) => {
              this.form.patchValue({
                dataSource: {
                  name: tag.name,
                  id: tag.tagId,
                },
              });
            });
        }
      });
  }

  private getCategories(): void {
    this.categoryService
      .getCategories(this.userService.userPreferences.defaultEcosystemId)
      .then((response: Array<Category>) => {
        this.categoriesDataSource = response;
        this.form.controls.category.setValue(this.widget.dataType);
      });
  }

  private createForm(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(undefined, Validators.required),
    );
    this.form.addControl(
      'category',
      new FormControl(
        { value: undefined, disabled: !this.widget.dataType },
        Validators.required,
      ),
    );
    this.form.addControl(
      'dataPoints',
      new FormControl(
        {
          value: undefined,
          disabled: this.widget.dataValues?.length === 0,
        },
        Validators.required,
      ),
    );
  }

  public onChangeDataSources(): void {
    this.widget.dataSource = [this.form.controls.dataSource.value?.id];
    this.form.get('category').enable();
    this.getCategories();
    this.changeDataSource.emit(this.widget.dataSource);
  }

  public onChangeDataValues(): void {
    this.widget.dataValues = this.form.controls.dataPoints.value?.map(
      (item: ICustomTag) => item.id,
    );
    this.changeDataValue.emit(this.widget.dataValues);
  }

  public onChangeDataType(): void {
    const dataType = this.form.controls.category.value as TagCategory;
    this.form.get('dataPoints').enable();
    this.dataPointsInitialState.reset();
    this.changeDataType.emit(dataType);
    this.widget.dataType = dataType;
  }

  private getQueryModel(): Query {
    const queryModel = new Query();
    if (this.tagsDataSource?.length > 0) {
      this.widget?.dataSource.forEach((item: string) => {
        queryModel.addRule(
          new QueryRule(DTOQueryFieldType.tag, DTOQueryConditionOperator.in, [
            item,
          ]),
        );
      });
    }
    return queryModel;
  }

  public onDataBoundDataValues(params: QueryFilters): void {
    this.dataPointsInitialState = params;
    const queryModel = this.getQueryModel();
    this.categoryService
      .getTags(
        undefined,
        this.dataPointsInitialState,
        queryModel.modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        this.dataPointsInitialState.page > 1
          ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1].name
          : undefined,
      )
      .then((response: Array<ICustomTag>) => {
        this.dataPointsDataSource =
          this.dataPointsInitialState.page === 1
            ? response
            : [...this.dataPointsDataSource, ...response];
        this.form.patchValue({
          dataPoints: this.dataValues,
        });
      });
  }
}
