import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  CoreModule,
  HeadLineSimpleComponent,
  IButtonThumbnail,
  IconType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { NotificationsService } from './services/notifications.service';
import { NotificationsHubComponent } from './components/notifications-hub/notifications-hub.component';
import { Notification, ResearchNotification } from './models/notifications';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { INotification } from './interfaces/notification.interface';

@Component({
  selector: 'itq-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    CommonModule,
    NotificationsHubComponent,
    HeadLineSimpleComponent,
  ],
})
export class NotificationsComponent implements OnInit {
  @Output() close = new EventEmitter<void>();

  @Input() raisedAlertId: string;
  @Input() researchAlertId: string;

  public form: FormGroup;
  public buttonDataSource: Array<IButtonThumbnail>;
  unreadCount = signal(undefined);
  public showLoader = false;
  private showLoaderSubscription: Subscription;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
  );
  private getUnreadCountSubscription: Subscription;
  private loadNotificationsSubscription: Subscription;
  public notificationsCount: number;

  @ViewChild('unreadTemplate')
  unreadTemplate: TemplateRef<unknown>;

  readonly IconType = IconType;
  readonly Sizes = Sizes;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.loadNotificationsSubscription =
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          if (response.totalCount) {
            this.notificationsCount = response.totalCount;
          }
          this.notificationsService.loader$.next(false);
        },
      );
    this.getUnreadCountSubscription =
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount.set(response);
          this.buttonDataSource = this.buttonDataSource.map(
            (item: IDisplayType) => {
              if (item.id === 'unread') {
                if (this.unreadCount() === 0) {
                  item = { ...item, ...{ disabled: true } };
                } else {
                  delete item.disabled;
                }
                return item;
              } else {
                return item;
              }
            },
          );
        },
      );
    this.showLoaderSubscription = this.notificationsService.loader$.subscribe(
      (response: boolean) => {
        this.showLoader = response;
      },
    );
    this.form = new FormGroup({
      showAll: new FormControl('read'),
    });
  }

  ngAfterViewInit(): void {
    if (this.raisedAlertId) {
      this.notificationsService.loadOtherMatches$.next({
        filters: undefined,
        raisedAlertId: this.raisedAlertId,
      });
    }
    if (this.researchAlertId) {
      this.notificationsService
        .getNotificationById(this.researchAlertId)
        .subscribe((response: INotification) => {
          const researchNotification = response as ResearchNotification;
          this.notificationsService.loadNotificationDetail$.next(
            new ResearchNotification(
              researchNotification.raisedAlertId,
              researchNotification.createdDate,
              researchNotification.alertTypeName,
              researchNotification.priority,
              researchNotification.message,
              researchNotification.read,
              researchNotification.headline,
              researchNotification.rawMessage,
            ),
          );
        });
    }
    this.buttonDataSource = [
      {
        id: 'read',
        tooltip: 'All',
      },
      {
        id: 'unread',
        tooltip: 'Unread',
        template: this.unreadTemplate,
      },
    ];
  }

  ngOnDestroy(): void {
    this.showLoaderSubscription.unsubscribe();
    this.getUnreadCountSubscription.unsubscribe();
    this.loadNotificationsSubscription.unsubscribe();
  }

  public onExplore(): void {
    this.notificationsService.loadOtherMatches$.next({
      filters: new QueryFilters(30, 1, undefined, undefined, undefined),
      raisedAlertId: undefined,
    });
  }

  public onDataBound(): void {
    this.notificationsService.loader$.next(true);
    this.notificationsService
      .getNotifications(
        this.initialState,
        this.form.get('showAll').value === 'unread' ? false : undefined,
      )
      .subscribe();
  }

  public onMarkAllRead(): void {
    this.showLoader = true;
    this.notificationsService.markAllAsRead().subscribe(() => {
      this.showLoader = false;
    });
  }
}
