import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CastIconNamePipe,
  CastIconSizePipe,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { IAddNote } from '@portal/profiles/interfaces/profile-note.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { AlertTypesIcons } from '@portal/notifications/enums/alerts.enum';
import {
  PriorityLabel,
  PriorityColor,
} from '@portal/notifications/enums/notification.enum';
import { TagMatchNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { SharedModule } from '@portal/shared/shared.module';
import { Profile } from '@portal/profiles/models/profile';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { PostNotesWizardComponent } from '@portal/shared/components/post-notes-wizard/post-notes-wizard.component';

@Component({
  selector: 'itq-notifications-tag-match',
  templateUrl: './notifications-tag-match.component.html',
  styleUrls: ['./notifications-tag-match.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    CastIconNamePipe,
    CastIconSizePipe,
  ],
})
export class NotificationsTagMatchComponent implements OnInit {
  @Input() notification: TagMatchNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  @Output() drilldown = new EventEmitter<{
    segment?: ISegment;
    notification: TagMatchNotification;
  }>();

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;

  private markAsReadSubscription: Subscription;

  @HostBinding('style.borderRight') borderRight: string;
  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit() {
    this.markAsReadSubscription =
      this.notificationService.markAsRead$.subscribe(() => {
        this.class = '';
      });
    this.class = !this.notification.read ? 'unread' : '';
    this.borderRight =
      '10px solid ' + PriorityColor[this.notification.priority];
  }

  ngOnDestroy(): void {
    this.markAsReadSubscription?.unsubscribe();
  }

  public onViewOtherMatches(): void {
    this.notificationService.loadOtherMatches$.next({
      raisedAlertId: this.notification.raisedAlertId,
      filters: undefined,
    });
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: TagMatchNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? 'unread' : '';
      });
  }

  public onAddNote(params: IAddNote): void {
    let customOverlay: CustomOverlayRef;
    customOverlay = this.customOverlayService.open({
      data: params,
      size: 'lg',
      type: CustomOverlayType['slide-right'],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe(
      (response: { refresh: boolean; profile: Profile; note: ProfileNote }) => {
        if (response?.profile && response.note) {
          const navigationItem = new ProfilesNavigationItem(
            `${WidgetActions.CREATE}_addnote_profiles`,
            response.profile,
            WidgetActions.CREATE,
            undefined,
            undefined,
            this.form,
            'plus',
            IconType.FONT_AWESOME,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [response.note],
            0,
          );
          customOverlay = this.customOverlayService.open({
            data: {
              navigationItem,
            },
            closeBtnStyle: 'basic',
            type: CustomOverlayType['almost-full'],
            size: 'md',
            component: WidgetSettingsComponent,
            disposeOnNavigation: true,
          });
        }
      },
    );
  }

  public onDrilldown(segment?: ISegment): void {
    this.onToggleRead();
    this.drilldown.emit({ segment, notification: this.notification });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    this.notificationService.createProfile$.next({
      profileDrilldown,
      notification: this.notification,
    });
  }
}
