import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CoreModule,
  DateQueryType,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import { ICustomTag, ITag } from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import moment from 'moment';

@Component({
  selector: 'itq-timeline-metrics',
  templateUrl: './timeline-metrics.component.html',
  styleUrls: ['./timeline-metrics.component.scss'],
  standalone: true,
  imports: [CoreModule, ReactiveFormsModule],
})
export class TimelineMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.timelineWidget = widget as Timeline;
  }
  @Input() form: FormGroup;

  @Output() update = new EventEmitter<Widget>();

  public tagsDataSource: Array<ITag>;
  public initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
  );
  public timelineWidget: Timeline;

  constructor(
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly boardService: BoardService,
  ) {
    this.onGetTags();
  }

  ngOnInit(): void {
    this.addControls();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
  }

  public onGetTags(): void {
    this.tagService
      .getTagsForUser(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<ITag>) => {
        this.tagsDataSource = response;
        const tagIds = this.boardService.widgets
          .filter((item: Widget) => item.type === AnalysisTypes.TIMELINE)
          .map((item: Widget) => (item as Timeline).tagId);

        this.tagsDataSource = this.tagsDataSource.filter(
          (item: ITag) => !tagIds.includes(item.tagId),
        );
      });
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(this.timelineWidget?.tagId, [Validators.required]),
    );
  }
  public onChangeDataSources(value: string): void {
    const tag = this.tagsDataSource.find((item: ITag) => item.tagId === value);
    this.form.get('name').setValue(tag.name);
    this.form.get('description').setValue(tag.description);
    this.timelineWidget.tagId = value;
    this.update.emit(this.timelineWidget);
  }
}
