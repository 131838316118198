<div class="flex" *ngIf="notification.tagEdited">
  <fa-icon
    class="absolute right-4 bottom-4 z-50"
    [size]="'lg'"
    [matTooltip]="'This tag has changed since the alert was flat'"
    [icon]="['far', 'bell-exclamation']"
    [ngStyle]="{ color: '#ffb74d' }"
  ></fa-icon>
</div>
<div class="flex flex-col w-full">
  <div class="w-full flex flex-col">
    <div class="header__wrapper">
      <div class="header__left">
        <fa-icon
          [matTooltip]="'Tag match alert'"
          [size]="'lg'"
          [icon]="[
            'far',
            AlertTypesIcons[notification?.alertTypeName] | castIconName
          ]"
        ></fa-icon>
        <div>New match for {{ notification?.tagName }}</div>
      </div>
      <div class="header__right">
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          [matTooltip]="'View matches'"
          (clickEvent)="onViewOtherMatches()"
        >
          <fa-icon [icon]="['far', 'arrow-up-right-from-square']"></fa-icon>
        </itq-mat-button>
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
          (clickEvent)="onToggleRead()"
        >
          <fa-icon
            [icon]="['far', notification.read ? 'eye-slash' : 'eye']"
          ></fa-icon>
        </itq-mat-button>
        <span
          class="priority"
          [ngStyle]="{ color: PriorityColor[notification?.priority] }"
          >{{ PriorityLabel[notification.priority] }}</span
        >
      </div>
    </div>
    <div class="flex flex-col italic ml-4">
      <span *ngIf="notification.matches" class="text-wrapper pr-4">
        Raised with {{ notification?.matches }} matches on
        {{
          notification.createdDate
            | convertFromEpochMillis
            | date: 'yyyy/MM/dd - hh:mm'
        }}
      </span>
    </div>
  </div>
  <div class="notification__body">
    <span class="body_message" *ngIf="notification.message">
      {{ notification.message }}</span
    >
    <itq-document-item-tile
      class="pl-4"
      *ngIf="notification?.document"
      [item]="notification?.document"
      (addNote)="onAddNote($event)"
      (createProfile)="onCreateProfile($event)"
      (drilldown)="onDrilldown($event)"
    ></itq-document-item-tile>
  </div>
</div>
